import React, { useEffect } from 'react';
import { styles } from 'style';
import { Order, ReduxState, Params, Dispatch } from 'types';
import { formatDate, getOrder, selectIdFromRoute } from 'selectors';
import { connect } from 'react-redux';
import Nav from 'src/shared-components/screens/Nav';

import { actions } from 'src/redux/actions';
import { fetchRetailOrder } from 'src/redux/order/thunks';
import { LRStepperProvider } from './lr-stepper';

// Steps
import SelectProductsStep from './steps/select-products';
import AddProductTypeStep from './steps/add-product-type-step';
import PrescriptionStep from './steps/prescription-step';
import OpticianNotesStep from './steps/optician-notes-step';
import CustomerConsentStep from './steps/customer-consent';
import { PrescriptionStepperProvider } from './prescription-stepper';

interface LensReplacementPageProps {
  customerId: string;
  dispatch: Dispatch;
  jwt: string;
  order: Order;
  orderId: string;
  params: Params;
}

const LensReplacementPage : React.FC<LensReplacementPageProps> = ({
  customerId,
  dispatch,
  jwt,
  order,
  orderId,
  params,
}) => {
  useEffect(actions.fetchOrder(dispatch, jwt, params), []);
  useEffect(actions.fetchPimItems(dispatch, jwt, order), [order]);
  useEffect(actions.fetchPimConfigProducts(dispatch, jwt, order), [order]);
  useEffect(actions.fetchLensReplacementValidations(dispatch, order?.id, jwt, true), [order]);

  useEffect(() => {
    dispatch(fetchRetailOrder(customerId, orderId));
  }, []);

  const orderPlaced = order && formatDate(order.placed);
  const href = order && `/customer/${order.customer_id}/order/${order.id}`;
  const text = order && `Order ${order.id}`;
  const subtext = order && `Ordered on ${orderPlaced}`;
  return (
    <div className='page' style={styles.page}>
      <div className="content">
        {order && (
            <LRStepperProvider order={order} header={
              <Nav
                href={href}
                text={text}
                subtext={subtext}
              />
            }>
              <SelectProductsStep params={params} />

              <AddProductTypeStep />

              <OpticianNotesStep params={params} />

              <CustomerConsentStep />
              <PrescriptionStepperProvider params={params}>
                <PrescriptionStep params={params} />
              </PrescriptionStepperProvider>
            </LRStepperProvider>
        )}
      </div>
    </div>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const customerId =  selectIdFromRoute('customer', params);
  const orderId =  selectIdFromRoute('order', params);
  const order = getOrder(state, params) as Order;

  return ({ customerId, order, orderId, jwt: state.auth.jwt });
};

export default connect(select)(LensReplacementPage);
