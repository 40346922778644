import React, { useState, useEffect } from 'react';

import { styles } from 'style';
import { Dispatch, ServiceGesturePreview, ServiceGesture } from 'types';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import Form from 'src/shared-components/order-actions/Form';

const submitButtonStyle = (shouldBeDisabled: boolean): any => {
  if (shouldBeDisabled) {
    return disabledButton;
  }

  return styles.button;
};

const disabledButton = { ...styles.button, ...styles.disabledButton };

interface ServiceGestureConfirmationProps {
  discount: string;
  dispatch: Dispatch;
  formatGesturePreview(discount: string, preview: ServiceGesturePreview): string;
  gesturePreview: ServiceGesturePreview;
  handleServiceGesture(dispatch: Dispatch, jwt: string, orderId: string, presetId: number, reasonId: number, note: string, amountOverride?: number): void;
  isGestureRequested: boolean;
  jwt: string;
  note: string;
  orderId: string;
  reason: ServiceGesture;
}

const ServiceGestureOverride: React.StatelessComponent<ServiceGestureConfirmationProps> = ({
  discount, handleServiceGesture, dispatch, jwt, orderId, reason, isGestureRequested, note, formatGesturePreview, gesturePreview,
}) => {
  const [form, setValues] = useState({
    gestureAmount: null,
    hideForm: true,
  });

  useEffect(() => {
    if (discount && gestureAmount === null) {
      setValues({
        ...form,
        gestureAmount: discount,
      });
    }
  });

  const updateDiscountOverride = (event) => {
    setValues({
      ...form,
      gestureAmount: event.target.value,
    });
  };

  const toggleForm = (event) => {
    event.preventDefault();
    setValues({
      ...form,
      hideForm: !form.hideForm,
    });
  };

  const formatGestureData = (dispatch, jwt, orderId, reason, note, gestureAmount, discount) => {
    // if the service gesture discount amount has not been overridden
    if (gestureAmount === discount) {
      handleServiceGesture(dispatch, jwt, orderId, reason.reason_id, reason.detailed_reason_id, note);
      return;
    }

    // gestureAmount is a string from the input form in dollar amount
    const overrideAmount = Number(gestureAmount) * 100;
    handleServiceGesture(dispatch, jwt, orderId, reason.reason_id, reason.detailed_reason_id, note, overrideAmount);
  };

  const { gestureAmount, hideForm } = form;
  const serviceGestureButton = isGestureRequested ? 'Loading...' : formatGesturePreview(gestureAmount, gesturePreview);

  return (
    hideForm ? (
      <>
        <EditableSelectionPill
          subtext="Service gesture discount amount"
          text={`$${gestureAmount}`}
          onClick={toggleForm}
        />
        <p style={styles.paddedPara}>
          Apply $
          {gestureAmount}
          {' '}
          credit?
        </p>
        <button data-cy="apply-credit" style={submitButtonStyle(isGestureRequested)} disabled={isGestureRequested} onClick={() => formatGestureData(dispatch, jwt, orderId, reason, note, gestureAmount, discount)}>{serviceGestureButton}</button>
      </>
    ) : (
      <>
        <p style={styles.paddedPara}>Service gesture discount amount</p>
        <Form
          onSubmit={toggleForm}
          onChange={updateDiscountOverride}
          name="note"
          value={gestureAmount}
          submitButton={submitButtonStyle(!gestureAmount.length)}
          disabled={!gestureAmount.length}
          text="Done"
        />
      </>
    )
  );
};

export default ServiceGestureOverride;
