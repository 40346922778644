import React, { useState } from 'react';
import { formatToIsoString } from 'src/utils/format-dates';
import { CheckboxField } from '../checkbox';
import {
  RadioButton,
  RxDescription,
  RxLink,
  RxSubtitle,
  RxTitle,
  SwapButton,
  Wrapper,
  cssStyle,
} from './styles';
import { Badge } from '../badge/Badge';
import PrescriptionTable from './table';
import { UserPrescription } from '../../redux/lens-replacement/types';

interface RxCardProps {
  rxTitle: string;
  isCurrentlySelected: boolean;
  prescription: UserPrescription;
  isPreviouslyUsed: boolean;
  isSelectedForSwap: boolean;
  onSelectForSwap: () => void;
  onSwapClick: () => void;
  isDisabled?: boolean;
}

const RxCardComponent: React.FC<RxCardProps> = ({
  rxTitle,
  isCurrentlySelected,
  prescription,
  isPreviouslyUsed,
  onSwapClick,
  isSelectedForSwap,
  onSelectForSwap,
  isDisabled,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  const shouldBeChecked = isSelectedForSwap || isCurrentlySelected;

  const handleRxDescriptionClick = () => {
    if (!isDisabled) {
      onSelectForSwap();
    }
  };

  const buttonText = isPreviouslyUsed ? 'Keep this Rx' : 'Swap to this Rx';

  return (
    <Wrapper isDisabled={isDisabled}>
      <RadioButton isDisabled={isDisabled}>
        <CheckboxField type="radio" isChecked={shouldBeChecked} handleCheck={handleRxDescriptionClick} />
      </RadioButton>
      <RxDescription onClick={handleRxDescriptionClick}>
        <RxTitle centered>
          <span style={{ marginRight: '5px' }}>{rxTitle}</span>
          {isPreviouslyUsed && <Badge variant="secondary">Previously Used</Badge>}
          {isDisabled && <Badge variant='error'>Expired</Badge>}
        </RxTitle>
        <RxSubtitle>Expires: {formatToIsoString(prescription.expiration_date)}</RxSubtitle>
      </RxDescription>
      <RxLink onClick={() => setExpanded(!isExpanded)}>
        Details <span style={isExpanded ? cssStyle.arrowUp : cssStyle.arrow} />
      </RxLink>
      {isExpanded && <PrescriptionTable data={prescription} />}
      {(isSelectedForSwap && !isCurrentlySelected) && (
        <SwapButton onClick={onSwapClick}>{buttonText}</SwapButton>
      )}
    </Wrapper>
  );
};

export default RxCardComponent;
