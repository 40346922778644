import React from 'react';

import { browserHistory } from 'browserHistory';

class Link extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  handleClick = (event) => {
    const { target, href } = this.props;
    const nextURL = window.location.search;

    // need to check for the target to prevent page refresh when clicking on <a>
    if (!target) {
      event.preventDefault();

      // if the Link is disabled, only capture the nextURL
      if (href === '#') {
        if (nextURL && nextURL !== '') browserHistory.push(nextURL);
        return;
      }

      browserHistory.push(href + nextURL);
    }
  };

  render() {
    return (
      <a {...this.props} onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

export default Link;
