import { CategoryProduct } from 'src/types/lens-replacement';

export enum CountryCode {
  US = 'us',
  CA = 'ca',
}

export const mockDetailedProductCA = [
  {
    product_weight: 3,
    product_height: null,
    product_width: null,
    product_depth: null,
    pc_product_id: 75134,
    pc_product_ids: [75134],
    product_type_id: 'FINISHED_GOOD',
    primary_product_category_id: 'CONTACT_LENS_PACK',
    product_name: '1-Day Acuvue Define (30 pack)',
    internal_name: '1-Day Acuvue Define 30pk',
    brand_name: 'Acuvue (Johnson & Johnson)',
    description: '1-Day Acuvue Define 30pk',
    long_description: '',
    weight_uom_id: 'WT_oz',
    sku: null,
    upc: null,
    virtual_pc_product_id: null,
    virtual_product_name: null,
    categories: [
      {
        id: 'CA_PURCHASE_ALLOW',
        name: 'Purchasable in ecomm in the CA',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'RETAIL_CA_PURC_ALLOW',
        name: 'Purchasable in retail in the CA',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'CONTACT_LENS_GALLERY',
        name: 'The Contact Lens gallery',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'CA_VIEW_ALLOW',
        name: 'Viewable in ecomm in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'RETAIL_CA_VIEW_ALLOW',
        name: 'Viewable in retail in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
    ],
    content: [
      {
        content_name: 'product_FRONT',
        content_type_id: 'PRODUCT_IMAGE',
        data_resource_name: '',
        data_resource_type_id: 'IMAGE_BLOB',
        product_content_type_id: 'FRONT',
        text_data: null,
      },
    ],
    features: [
      {
        abbrev: '',
        data_resources: {},
        description: '30',
        group_ids: ['CONTACT'],
        id_code: '',
        type_id: 'CL_BOXSIZE',
        uom_id: 'OTH_ea',
      },
    ],
    good_ids: [
      {
        type_id: 'PC_SPECIFIC_PL_ID',
        id_value: '3817',
      },
    ],
  },
  {
    product_weight: 3,
    product_height: null,
    product_width: null,
    product_depth: null,
    pc_product_id: 75135,
    pc_product_ids: [75135],
    product_type_id: 'FINISHED_GOOD',
    primary_product_category_id: 'CONTACT_LENS_PACK',
    product_name: '1-Day Acuvue Define (30 pack)',
    internal_name: '1-Day Acuvue Define 30pk',
    brand_name: 'Acuvue (Johnson & Johnson)',
    description: '1-Day Acuvue Define 30pk',
    long_description: '',
    weight_uom_id: 'WT_oz',
    sku: null,
    upc: null,
    virtual_pc_product_id: null,
    virtual_product_name: null,
    categories: [
      {
        id: 'CA_PURCHASE_ALLOW',
        name: 'Purchasable in ecomm in the CA',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'RETAIL_CA_PURC_ALLOW',
        name: 'Purchasable in retail in the CA',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
    ],
    content: [
      {
        content_name: 'product_FRONT',
        content_type_id: 'PRODUCT_IMAGE',
        data_resource_name: '',
        data_resource_type_id: 'IMAGE_BLOB',
        product_content_type_id: 'FRONT',
        text_data: null,
      },
    ],
    features: [
      {
        abbrev: '',
        data_resources: {},
        description: '30',
        group_ids: ['CONTACT'],
        id_code: '',
        type_id: 'CL_BOXSIZE',
        uom_id: 'OTH_ea',
      },
    ],
    good_ids: [
      {
        type_id: 'PC_SPECIFIC_PL_ID',
        id_value: '3817',
      },
    ],
  },
  {
    product_weight: 3,
    product_height: null,
    product_width: null,
    product_depth: null,
    pc_product_id: 75136,
    pc_product_ids: [75136],
    product_type_id: 'FINISHED_GOOD',
    primary_product_category_id: 'CONTACT_LENS_PACK',
    product_name: '1-Day Acuvue Define (30 pack)',
    internal_name: '1-Day Acuvue Define 30pk',
    brand_name: 'Acuvue (Johnson & Johnson)',
    description: '1-Day Acuvue Define 30pk',
    long_description: '',
    weight_uom_id: 'WT_oz',
    sku: null,
    upc: null,
    virtual_pc_product_id: null,
    virtual_product_name: null,
    categories: [
      {
        id: 'CONTACT_LENS_GALLERY',
        name: 'The Contact Lens gallery',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
    ],
    content: [
      {
        content_name: 'product_FRONT',
        content_type_id: 'PRODUCT_IMAGE',
        data_resource_name: '',
        data_resource_type_id: 'IMAGE_BLOB',
        product_content_type_id: 'FRONT',
        text_data: null,
      },
    ],
    features: [
      {
        abbrev: '',
        data_resources: {},
        description: '30',
        group_ids: ['CONTACT'],
        id_code: '',
        type_id: 'CL_BOXSIZE',
        uom_id: 'OTH_ea',
      },
    ],
    good_ids: [
      {
        type_id: 'PC_SPECIFIC_PL_ID',
        id_value: '3817',
      },
    ],
  },
];

export const mockDetailedProductUS = [
  {
    product_weight: 3,
    product_height: null,
    product_width: null,
    product_depth: null,
    pc_product_id: 75134,
    pc_product_ids: [75134],
    product_type_id: 'FINISHED_GOOD',
    primary_product_category_id: 'CONTACT_LENS_PACK',
    product_name: '1-Day Acuvue Define (30 pack)',
    internal_name: '1-Day Acuvue Define 30pk',
    brand_name: 'Acuvue (Johnson & Johnson)',
    description: '1-Day Acuvue Define 30pk',
    long_description: '',
    weight_uom_id: 'WT_oz',
    sku: null,
    upc: null,
    virtual_pc_product_id: null,
    virtual_product_name: null,
    categories: [
      {
        id: 'US_PURCHASE_ALLOW',
        name: 'Purchasable in ecomm in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'RETAIL_US_PURC_ALLOW',
        name: 'Purchasable in retail in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'CONTACT_LENS_GALLERY',
        name: 'The Contact Lens gallery',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'US_VIEW_ALLOW',
        name: 'Viewable in ecomm in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'RETAIL_US_VIEW_ALLOW',
        name: 'Viewable in retail in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
    ],
    content: [
      {
        content_name: 'product_FRONT',
        content_type_id: 'PRODUCT_IMAGE',
        data_resource_name: '',
        data_resource_type_id: 'IMAGE_BLOB',
        product_content_type_id: 'FRONT',
        text_data: null,
      },
    ],
    features: [
      {
        abbrev: '',
        data_resources: {},
        description: '30',
        group_ids: ['CONTACT'],
        id_code: '',
        type_id: 'CL_BOXSIZE',
        uom_id: 'OTH_ea',
      },
    ],
    good_ids: [
      {
        type_id: 'PC_SPECIFIC_PL_ID',
        id_value: '3817',
      },
    ],
  },
  {
    product_weight: 3,
    product_height: null,
    product_width: null,
    product_depth: null,
    pc_product_id: 75135,
    pc_product_ids: [75135],
    product_type_id: 'FINISHED_GOOD',
    primary_product_category_id: 'CONTACT_LENS_PACK',
    product_name: '1-Day Acuvue Define (30 pack)',
    internal_name: '1-Day Acuvue Define 30pk',
    brand_name: 'Acuvue (Johnson & Johnson)',
    description: '1-Day Acuvue Define 30pk',
    long_description: '',
    weight_uom_id: 'WT_oz',
    sku: null,
    upc: null,
    virtual_pc_product_id: null,
    virtual_product_name: null,
    categories: [
      {
        id: 'US_PURCHASE_ALLOW',
        name: 'Purchasable in ecomm in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
      {
        id: 'RETAIL_US_PURC_ALLOW',
        name: 'Purchasable in retail in the US',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
    ],
    content: [
      {
        content_name: 'product_FRONT',
        content_type_id: 'PRODUCT_IMAGE',
        data_resource_name: '',
        data_resource_type_id: 'IMAGE_BLOB',
        product_content_type_id: 'FRONT',
        text_data: null,
      },
    ],
    features: [
      {
        abbrev: '',
        data_resources: {},
        description: '30',
        group_ids: ['CONTACT'],
        id_code: '',
        type_id: 'CL_BOXSIZE',
        uom_id: 'OTH_ea',
      },
    ],
    good_ids: [
      {
        type_id: 'PC_SPECIFIC_PL_ID',
        id_value: '3817',
      },
    ],
  },
  {
    product_weight: 3,
    product_height: null,
    product_width: null,
    product_depth: null,
    pc_product_id: 75136,
    pc_product_ids: [75136],
    product_type_id: 'FINISHED_GOOD',
    primary_product_category_id: 'CONTACT_LENS_PACK',
    product_name: '1-Day Acuvue Define (30 pack)',
    internal_name: '1-Day Acuvue Define 30pk',
    brand_name: 'Acuvue (Johnson & Johnson)',
    description: '1-Day Acuvue Define 30pk',
    long_description: '',
    weight_uom_id: 'WT_oz',
    sku: null,
    upc: null,
    virtual_pc_product_id: null,
    virtual_product_name: null,
    categories: [
      {
        id: 'CONTACT_LENS_GALLERY',
        name: 'The Contact Lens gallery',
        primary_parent_id: '',
        type_id: 'CATALOG_CATEGORY',
        detail_screen: '',
      },
    ],
    content: [
      {
        content_name: 'product_FRONT',
        content_type_id: 'PRODUCT_IMAGE',
        data_resource_name: '',
        data_resource_type_id: 'IMAGE_BLOB',
        product_content_type_id: 'FRONT',
        text_data: null,
      },
    ],
    features: [
      {
        abbrev: '',
        data_resources: {},
        description: '30',
        group_ids: ['CONTACT'],
        id_code: '',
        type_id: 'CL_BOXSIZE',
        uom_id: 'OTH_ea',
      },
    ],
    good_ids: [
      {
        type_id: 'PC_SPECIFIC_PL_ID',
        id_value: '3817',
      },
    ],
  },
];

export const orderItem = [
  {
    id: '1',
    total: 0,
    invoices: null,
    quantity: 1,
    tax_amount: 0,
    pc_product_id: '75134',
    discount_amount: 0,
  },
  {
    id: '2',
    total: 0,
    invoices: null,
    quantity: 1,
    tax_amount: 0,
    pc_product_id: '75135',
    discount_amount: 0,
  },
  {
    id: '3',
    total: 0,
    invoices: null,
    quantity: 1,
    tax_amount: 0,
    pc_product_id: '75136',
    discount_amount: 0,
  },
];

export const salesOrderPrescription = {
  isMultiFocal: false,
  title: 'Test prescription',
  created: 0,
  expiration_date: 2240611200,
  prescription_id: 233321212,
};

export const salesOrderPrescription2 = {
  isMultiFocal: false,
  title: 'Test prescription 2',
  created: 0,
  expiration_date: '2023-01-09T01:53:35.776Z',
  prescription_id: 233321212,
};

export const salesOrderPrescription3 = {
  isMultiFocal: false,
  title: 'Test prescription 3',
  created: 0,
  expiration_date: '2023-01-09T01:53:35.776Z',
  prescription_id: 233321212,
};

export const selectedItemsMock = [
  {
    id: 'item1',
    total: 100,
    tax_amount: 10,
    discount_amount: 5,
    quantity: 2,
    invoices: [],
    pc_product_id: 'product1',
  },
  {
    id: 'item2',
    total: 150,
    tax_amount: 15,
    discount_amount: 10,
    quantity: 1,
    invoices: [],
    pc_product_id: 'product2',
  },
];

export const  categoriesMock: CategoryProduct[] = [
  {
    id: 'US_PURCHASE_ALLOW',
    name: 'Purchasable in ecomm in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 1,
    pc_product_config_id: 75134,
  },
  {
    id: 'RETAIL_US_PURC_ALLOW',
    name: 'Purchasable in retail in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 2,
    pc_product_config_id: 75134,
  },
  {
    id: 'RETAIL_CA_PURC_ALLOW',
    name: 'Purchasable in retail in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 2,
    pc_product_config_id: 75134,
  },
  {
    id: 'RETAIL_US_PURC_ALLOW',
    name: 'Purchasable in retail in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 2,
    pc_product_config_id: 75135,
  },
  {
    id: 'CONTACT_LENS_GALLERY',
    name: 'The Contact Lens gallery',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 3,
    pc_product_config_id:7,
  },
  {
    id: 'US_VIEW_ALLOW',
    name: 'Viewable in ecomm in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 4,
    pc_product_config_id: 75135,
  },
  {
    id: 'RETAIL_US_VIEW_ALLOW',
    name: 'Viewable in retail in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 5,
    pc_product_config_id: 75134,
  },
  {
    id: 'RETAIL_CA_VIEW_ALLOW',
    name: 'Viewable in retail in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 5,
    pc_product_config_id: 75134,
  },
  {
    id: 'RETAIL_CA_PURC_ALLOW',
    name: 'Viewable in retail in the US',
    primary_parent_id: 0,
    type_id: 'CATALOG_CATEGORY',
    detail_screen: '',
    pc_product_id: 5,
    pc_product_config_id: 75135,
  },
];

