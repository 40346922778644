import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { apiFactory } from 'src/redux/actions-wip';
import {
  SalesOrder, CreditMemo, ReduxState, CSSStyle, Dispatch, Params, Interaction, InteractionType,
} from 'types';
import { Endpoint } from 'types-wip';
import {
  currentUserHasFeature, selectIdFromRoute, getYYYYMMDD, formatDateFromYYYYMMDD,
} from 'selectors';
import { styles } from 'style';

import Loading from 'src/shared-components/screens/Loading';
import backArrow from 'src/shared-components/icons/back-arrow.svg';
import InteractionDay from './InteractionDay';
import { formatParams } from '../app/routeUtils';

const formatNextURL = (url: string): string => `${decodeURIComponent(url.split('?next=')[1])}/`;

const returnToPoe = (url: string): JSX.Element => {
  const wrapPara = {
    flex: '1 0 100%',
    margin: '125px 0 0',
  };
  const para = { ...styles.paddedLargePara, ...wrapPara };
  return (
    <div style={style.container}>
      <p style={para}>This customer has no orders yet!</p>
      <a href={url} style={style.narrowButton}>Return to POE</a>
    </div>
  );
};

const groupInteractions = (orders: SalesOrder[], creditMemos: CreditMemo[]): GroupedInteractions => {
  const groupedInteractions = {};

  orders.forEach((order) => {
    const yyyymmdd = getYYYYMMDD(order.placed);
    const interaction = {
      interactionType: InteractionType.SalesOrder,
      date: order.placed,
      salesOrder: order,
    };

    if (!groupedInteractions[yyyymmdd]) {
      groupedInteractions[yyyymmdd] = [interaction];
    } else {
      groupedInteractions[yyyymmdd].push(interaction);
    }
  });

  creditMemos.forEach((cm) => {
    const yyyymmdd = getYYYYMMDD(cm.created);
    const interaction = {
      interactionType: InteractionType.CreditMemo,
      date: cm.created,
      creditMemo: cm,
    };

    if (!groupedInteractions[yyyymmdd]) {
      groupedInteractions[yyyymmdd] = [interaction];
    } else {
      groupedInteractions[yyyymmdd].push(interaction);
    }
  });

  Object.keys(groupedInteractions).forEach((k) => {
    groupedInteractions[k].sort((a: Interaction, b: Interaction): number => (a.date < b.date ? 1 : -1));
  });

  return groupedInteractions;
};

const renderInteractionDetails = (interactions: GroupedInteractions, params: Params): JSX.Element[] => (
  Object.keys(interactions).sort().reverse().map((key) => (
    <InteractionDay
      key={key}
      interactions={interactions[key]}
      customerId={params.customer}
      orderDate={formatDateFromYYYYMMDD(key)}
    />
  ))
);

const style: CSSStyle = {
  arrow: {
    background: `url(${backArrow}) no-repeat`,
    height: '19px',
    width: '26px',
  },
  background: {
    background: '#F7FAFC',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  header: {
    color: '#434D58',
    fontSize: '25px',
    fontWeight: 600,
    margin: '0',
    paddingRight: '25px',
    textAlign: 'center',
    width: '100%',
  },
  nav: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: '768px',
    padding: '0 20px',
    textDecoration: 'none',
    width: '100%',
  },
  navContainer: {
    background: 'white',
    borderBottom: '1px solid #E4E6E8',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '25px 0',
    position: 'fixed',
    width: '100%',
    zIndex: 2,
  },
  narrowButton: {
    alignItems: 'center',
    background: '#00A2E1',
    border: '0',
    borderRadius: '10px',
    bottom: '20px',
    boxSizing: 'border-box',
    color: 'white',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 600,
    justifyContent: 'center',
    margin: '0 auto',
    padding: '20px 55px',
    textDecoration: 'none',
  },
};

interface GroupedInteractions {
  [key: string]: Interaction[];
}

interface InteractionListProps {
  dispatch: Dispatch;
  hasRequestedData: boolean;
  jwt: string;
  nextURL: string;
  orders: SalesOrder[];
  creditMemos: CreditMemo[];
  params: Params;
}

const InteractionList: React.StatelessComponent<InteractionListProps> = ({
  dispatch,
  jwt,
  nextURL,
  orders,
  creditMemos,
  params,
}) => {
  useEffect(() => {
    if (jwt) {
      const customerId = params.customer;
      dispatch(apiFactory(Endpoint.SalesOrders, jwt, customerId));
    }
  }, []);

  useEffect(() => {
    if (jwt) {
      const customerId = params.customer;
      dispatch(apiFactory(Endpoint.CreditMemos, jwt, customerId));
    }
  }, []);

  const poeURL = formatNextURL(nextURL);
  const hasNoOrders = orders && orders.length === 0;
  const hasNoCreditMemos = creditMemos && creditMemos.length === 0;
  const groupedInteractions = orders && creditMemos && groupInteractions(orders, creditMemos);

  return (
    <div className="page" style={style.background}>
      <div style={style.navContainer}>
        <a style={style.nav} href={poeURL}>
          <span style={style.arrow} />
          <span style={style.header}>Order History</span>
        </a>
      </div>
      <div className="content">
        {(!orders || !creditMemos) && <Loading />}
        {hasNoOrders && hasNoCreditMemos && returnToPoe(poeURL)}
        {groupedInteractions && renderInteractionDetails(groupedInteractions, params)}
      </div>
    </div>
  );
};

const select = (state: ReduxState) => {
  const routeParams = formatParams(window.location.pathname);
  const customerId = selectIdFromRoute('customer', routeParams);
  const ordersStatus = state.apiWIP[Endpoint.SalesOrders];
  const orders = ordersStatus[customerId] && ordersStatus[customerId].body;
  const creditMemosStatus = state.apiWIP[Endpoint.CreditMemos];
  const creditMemos = creditMemosStatus[customerId] && creditMemosStatus[customerId].body;

  return ({
    jwt: state.auth.jwt,
    orders,
    creditMemos,
  });
};

export default connect(select)(InteractionList);
