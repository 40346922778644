import { Action } from 'redux';
import { StatePrescriptions, UserId } from './types';
import {
  CategoryProduct,
  ItemsLensReplacementEligibility,
  OriginalSOProductDetail,
  SalesOrderLensReplacementDetail,
} from '../../types/lens-replacement';

export const FETCH_LENS_REPLACEMENT_VALIDATION_REQUEST =
  'FETCH_LENS_REPLACEMENT_VALIDATION_REQUEST';
export const FETCH_LENS_REPLACEMENT_VALIDATION_SUCCESS =
  'FETCH_LENS_REPLACEMENT_VALIDATION_SUCCESS';
export const FETCH_LENS_REPLACEMENT_VALIDATION_FAILURE =
  'FETCH_LENS_REPLACEMENT_VALIDATION_FAILURE';

export const GET_PRESCRIPTIONS_REQUEST = 'GET_PRESCRIPTIONS_REQUEST';
export const GET_PRESCRIPTIONS_SUCCESS = 'GET_PRESCRIPTIONS_SUCCESS';
export const GET_PRESCRIPTIONS_FAILURE = 'GET_PRESCRIPTIONS_FAILURE';
export const UPDATE_CUSTOMER_CONSENT = 'UPDATE_CUSTOMER_CONSENT';
export const FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_REQUEST =
  'FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_REQUEST';
export const FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_SUCCESS =
  'FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_SUCCESS';
export const FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_FAILURE =
  'FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_FAILURE';

export const FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_REQUEST =
  'FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_REQUEST';
export const FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_SUCCESS =
  'FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_SUCCESS';
export const FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_FAILURE =
  'FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_FAILURE';

export const FETCH_PRODUCT_AVAILABILITIES_REQUEST_SUCCESS =
    'FETCH_PRODUCT_AVAILABILITIES_REQUEST_SUCCESS';

export interface GetLensReplacementValidationRequest {
  type: typeof FETCH_LENS_REPLACEMENT_VALIDATION_REQUEST;
}

export interface GetLensReplacementValidationSuccess {
  type: typeof FETCH_LENS_REPLACEMENT_VALIDATION_SUCCESS;
  payload: ItemsLensReplacementEligibility[];
}

export interface GetLensReplacementValidationFailure {
  type: typeof FETCH_LENS_REPLACEMENT_VALIDATION_FAILURE;
}

export interface GetPrescriptionsRequestAction extends Action {
  customerId: UserId;
}

export interface GetPrescriptionsSuccessAction extends Action {
  prescriptions: StatePrescriptions;
  customerId: UserId;
}

export interface GetPrescriptionsFailureAction extends Action {
  userId: UserId;
  error: string;
}

export interface GetSalesOrderLRDetailsBySalesOrderIdRequestAction {
  type: typeof FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_REQUEST
}

export interface GetSalesOrderLRDetailsBySalesOrderIdSuccessAction {
  type: typeof FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_SUCCESS
  payload: SalesOrderLensReplacementDetail[],
}

export interface GetSalesOrderLRDetailsBySalesOrderIdFailureAction {
  type: typeof FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_FAILURE
}

export interface GetProductDetailsFromOriginalSalesOrderRequestAction {
  type: typeof FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_REQUEST
}

export interface GetProductDetailsFromOriginalSalesOrderSuccessAction {
  type: typeof FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_SUCCESS
  payload: OriginalSOProductDetail[],
}
export interface GetProductAvailabilitiesSuccessAction {
  type: typeof FETCH_PRODUCT_AVAILABILITIES_REQUEST_SUCCESS
  payload: CategoryProduct[],
}


export interface GetProductDetailsFromOriginalSalesOrderFailureAction {
  type: typeof FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_FAILURE,
}

export type LRAction =
  | GetLensReplacementValidationRequest
  | GetLensReplacementValidationSuccess
  | GetLensReplacementValidationFailure
  | GetPrescriptionsRequestAction
  | GetPrescriptionsSuccessAction
  | GetPrescriptionsFailureAction
  | GetSalesOrderLRDetailsBySalesOrderIdRequestAction
  | GetSalesOrderLRDetailsBySalesOrderIdSuccessAction
  | GetSalesOrderLRDetailsBySalesOrderIdFailureAction
  | GetProductDetailsFromOriginalSalesOrderRequestAction
  | GetProductDetailsFromOriginalSalesOrderSuccessAction
  | GetProductDetailsFromOriginalSalesOrderFailureAction
  | GetProductAvailabilitiesSuccessAction;
