import * as React from 'react';

import { styles } from 'style';

import Link from 'src/components/app/Link';

interface SelectionPillProps {
  href: string;
  text: string;
  'data-cy'?: string;
}

const SelectionPill: React.StatelessComponent<SelectionPillProps> = ({ href, text, 'data-cy': dataCy }) => (
  <Link data-cy={dataCy} style={styles.pill} href={href}>{text}</Link>
);

export default SelectionPill;
