import React, { FC, useEffect, useRef, useState } from 'react';
import { styles } from 'style';
import { connect } from 'react-redux';
import { fetchUserPrescriptions } from 'src/redux/lens-replacement/thunks';
import { StatePrescriptions, UserPrescription } from 'src/redux/lens-replacement/types';
import { Dispatch, Params, ReduxState, Order, PimItem, ConfigProduct, MeData, OrderItem } from 'types';
import { getOrderData } from 'src/redux/order/selectors';
import { RetailOrder } from 'src/redux/order/types';
import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { MapItemFrameAttributes, OrderWithFrameAttributes } from 'src/types/lens-replacement';
import { apiResponse, apiStatus, getAntiFatigueAddPowers, getOrder, getPimConfigProducts, getPimItems, selectPimItem } from 'selectors';
import { defaultImage } from 'src/shared-components/order-actions/common';
import { getFrameAttributesFromMapItems } from 'src/redux/lens-replacement/util';
import { useLRStepper } from '../../lr-stepper';
import LargeButton from '../../large-button';
import { mapOrderItemFrameAttributes, canProceedToCartWithPrescription } from '../../utils';
import { LR_PRODUCT_TYPE, LensTypes } from '../../../../utils/constants';
import { areAllPrescriptionsValid } from './prescriptionUtils';
import { usePrescriptionStepper } from '../../prescription-stepper';
import { HighIndexConsentStepModal, HighIndexConsentStepRef } from '../high-index-consent';
import { CenteredImage, ButtonContainer } from './styles';
import PrescriptionsContent from '../../prescriptions-content';

type Props = {
  dispatch: Dispatch;
  params: Params;
  prescriptions: StatePrescriptions;
  retailOrder: RetailOrder;
  jwt: string;
  order: Order;
  orderWithFrameAttributes: OrderWithFrameAttributes;
  meData: MeData;
  pimItems: PimItem[];
};

const PrescriptionStep: FC<Props> = ({
  dispatch,
  prescriptions,
  retailOrder,
  params,
  jwt,
  order,
  orderWithFrameAttributes,
  meData,
  pimItems,
}) => {
  const { data: lrData, finishStepper } = useLRStepper('PrescriptionStep');
  const {
    stepIdx,
    nextStep,
    previousStep,
    updateData,
    data,
    currentItem,
    isLastItem,
    currentPrescription,
    setAllPrescriptions,
  } = usePrescriptionStepper('PrescriptionSubStepper', true);
  const { type: lensReplacementTypeFlow } = lrData;
  const { updateData: updateReadersStrength } = useLRStepper('ReadersUpdateStep');
  const customerId = params.customer;
  const orderItems = retailOrder.items;
  const product = selectPimItem(pimItems, currentItem);
  const originalReaderStrength = orderItems[0] ? orderItems[0].attributes.frame.readers_strength : null;
  const initialReaderStrength = new Array(lrData.selectedItems.length).fill(originalReaderStrength);

  const [originalPrescription, setOriginalPrescription] = useState({} as UserPrescription);
  const [selectedForSwap, setSelectedForSwap] = useState<number | null>(null);
  const [canProceedToCart, setCanProceedToCart] = useState<boolean>(true);
  const [rxForLensReplacement, setRxForLensReplacement] = useState<UserPrescription | null>(null);
  const [readersStrengthsForLensReplacement, setReadersStrengthsForLensReplacement] = useState<string[]>(initialReaderStrength);
  const highIndexConsentStepRef = useRef<HighIndexConsentStepRef>(null);

  useEffect(() => {
    if (!(retailOrder && retailOrder.prescription) || !prescriptions[customerId]) return;

    const initialPrescription = prescriptions[customerId].find(
      (item) => retailOrder.prescription.prescription_id === item.prescription_id,
    );

    setAllPrescriptions(initialPrescription);
    setOriginalPrescription(initialPrescription);
    setRxForLensReplacement(initialPrescription);
    setCanProceedToCart(
      canProceedToCartWithPrescription(currentPrescription, lensReplacementTypeFlow) && areAllPrescriptionsValid(data.selectedPrescriptions),
    );
  }, [prescriptions[customerId]]);

  useEffect(() => {
    if (!readersStrengthsForLensReplacement?.length) return;

    updateReadersStrength({ readersStrength: readersStrengthsForLensReplacement });
  }, [readersStrengthsForLensReplacement]);

  useEffect(() => {
    setCanProceedToCart(
      canProceedToCartWithPrescription(currentPrescription, lensReplacementTypeFlow)
      && areAllPrescriptionsValid(data.selectedPrescriptions)
      || readersStrengthsForLensReplacement.length >= 0,
    );

    if (currentPrescription !== null) {
      setRxForLensReplacement(currentPrescription);
      return;
    }
    if (originalPrescription) {
      setRxForLensReplacement(originalPrescription);
      updateData(rxForLensReplacement);
    }
  }, [stepIdx]);

  useEffect(() => {
    if (!rxForLensReplacement) return;

    updateData(rxForLensReplacement);
  }, [rxForLensReplacement]);

  useEffect(() => {
    if (customerId && prescriptions && !prescriptions[customerId]) {
      // eslint-disable-next-line no-void
      void dispatch(fetchUserPrescriptions(customerId));
    }
  }, [customerId]);

  const onSwapToPrescription = (item: UserPrescription) => {
    setRxForLensReplacement(item);
    setSelectedForSwap(null);
    onChangeReadersStrength(null);
  };

  const onSelectPrescriptionForSwap = (prescriptionId: number) => {
    setSelectedForSwap(prescriptionId);
  };

  const updateReaders = (readerStrength: string | null) => (previousStrength: string[]) => {
    const readers = [...previousStrength];
    readers[stepIdx] = readerStrength;
    return readers;
  };

  const onChangeReadersStrength = (option: string) => {
    setReadersStrengthsForLensReplacement(updateReaders(option));

    if (!option) return;

    // Only discard Rx if it actually picks up a Readers
    setRxForLensReplacement(null);
    setSelectedForSwap(null);
  };

  const onNextStep = () => {
    if (!rxForLensReplacement && !readersStrengthsForLensReplacement[stepIdx]) return;

    if (hasToShowConsentBanner(lrData.selectedItems[stepIdx], orderWithFrameAttributes, rxForLensReplacement)) {
      highIndexConsentStepRef.current.openHighIndexConsentStep();
      return;
    }

    nextStep();
  };

  const onPreviousStep = ()=> {
    previousStep();
  };

  const hasToShowConsentBanner = (
    orderItem: OrderItem,
    orderWithFrameAttributes: OrderWithFrameAttributes,
    prescription: UserPrescription,
  ) => {
    const orderId = order.id;
    const isHighIndex = getFrameAttributesFromMapItems(orderItem, orderWithFrameAttributes.get(orderId), 'lens_type') === LensTypes.HighIndex;
    const isMinor = prescription?.end_user && prescription?.end_user.under_18;
    const isFree = lrData.type === LR_PRODUCT_TYPE.FREE;
    return isHighIndex && isMinor && isFree;
  };

  const onContinueToStep = useCallbackRef(() => {
    if (hasToShowConsentBanner(lrData.selectedItems[stepIdx], orderWithFrameAttributes, rxForLensReplacement)) {
      highIndexConsentStepRef.current.openHighIndexConsentStep();
      return;
    }

    finishSteps();
  });

  const finishSteps = useCallbackRef(() => {
    finishStepper(jwt, order, orderWithFrameAttributes, meData.facility, data.selectedPrescriptions);
  });

  if (!prescriptions[customerId]) return null;

  return (
    <>
      <p style={styles.paddedLargePara}>{product?.product_name}</p>
      <p style={{ ...styles.para, textAlign: 'center' }}>{stepIdx + 1} of {lrData.selectedItems.length}</p>
      <CenteredImage src={product?.image ?? defaultImage} />
      <PrescriptionsContent
        lrData={lrData}
        prescriptions={prescriptions}
        currentSelection={rxForLensReplacement}
        customerId={customerId}
        originalPrescription={originalPrescription}
        retailOrder={retailOrder}
        selectedForSwap={selectedForSwap}
        onSelectForSwap={onSelectPrescriptionForSwap}
        onSwapClick={onSwapToPrescription}
        onReadersStrengthOptionClick={onChangeReadersStrength}
        readerStrengthOption={readersStrengthsForLensReplacement[stepIdx]}
      />
      {lrData.selectedItems.length > 1 && <ButtonContainer>
        <LargeButton onClick={onPreviousStep} disabled={stepIdx === 0}>
          Previous
        </LargeButton>
        <LargeButton onClick={onNextStep} disabled={(!rxForLensReplacement && !readersStrengthsForLensReplacement[stepIdx]) || isLastItem}>
          Next
        </LargeButton>
      </ButtonContainer>
      }
      {(isLastItem || lrData.selectedItems.length === 1) &&
        <LargeButton disabled={!canProceedToCart} onClick={onContinueToStep}>
          Proceed to Cart
        </LargeButton>}
      <HighIndexConsentStepModal
        ref={highIndexConsentStepRef}
        stepIdx={stepIdx}
        isLastItem={isLastItem}
        nextStep={nextStep}
        finishStep={finishSteps}
      />
    </>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const { prescriptions } = state.lensReplacement;
  const order = getOrder(state, params) as unknown as Order;
  const pimItems = getPimItems(state, order);
  const antiFatiguePowers = getAntiFatigueAddPowers(state);
  const pimConfigProducts = getPimConfigProducts(state, order?.items);
  const retailOrder = getOrderData(state, params.order);
  const itemsWithFrameAttributes = mapOrderItemFrameAttributes(
    retailOrder,
    pimItems as PimItem[],
    antiFatiguePowers,
    pimConfigProducts as ConfigProduct[],
  );
  const orderWithItemsWithFrameAttributes = new Map<number, MapItemFrameAttributes>();
  orderWithItemsWithFrameAttributes.set(order.id, itemsWithFrameAttributes);

  const mePath = '/api/v1/user/me';
  const meDataStatus = apiStatus<MeData>(state, mePath);
  const meData = apiResponse(meDataStatus);

  return {
    prescriptions,
    retailOrder,
    order,
    jwt: state.auth.jwt,
    orderWithFrameAttributes: orderWithItemsWithFrameAttributes,
    meData,
    params,
    pimItems,
  };
};

export default connect(select)(PrescriptionStep);
