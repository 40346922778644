import { Order, OrderItem } from '../../../types';
import { LRData } from '../../components/lens-replacement/lr-stepper/types';
import { ConfigGalleryProduct, OrderWithFrameAttributes } from '../../types/lens-replacement';
import { LR_PRODUCT_TYPE } from '../../utils/constants';
import { getFrameAttributesFromMapItems, getFrameConfigsByOrderItem } from './util';
import { Customer, InterestProductItem, LensReplacementEstimatePayload, VisitPrescription } from './types';

interface LensReplacementEstimateBuilder {
  order: Order;
  lensReplacementData: LRData;
  frameConfigs: ConfigGalleryProduct[];
  frameAttributes: OrderWithFrameAttributes;
  getCustomer: () => Customer;
  getProducts: () => InterestProductItem[];
  buildEstimatePayload: () => LensReplacementEstimatePayload;
  isForReaders: (index: number) => boolean;
}

export class LensReplacementEstimate implements LensReplacementEstimateBuilder {
  order: Order;

  lensReplacementData: LRData;

  frameConfigs: ConfigGalleryProduct[];

  frameAttributes: OrderWithFrameAttributes;

  isForReaders: (index: number) => boolean;

  constructor(
    order: Order,
    lensReplacementData: LRData,
    frameConfigs: ConfigGalleryProduct[],
    frameAttributes: OrderWithFrameAttributes,
  ) {
    this.order = order;
    this.lensReplacementData = lensReplacementData;
    this.frameConfigs = frameConfigs;
    this.frameAttributes = frameAttributes;
    this.isForReaders = (index: number) => Boolean(this.lensReplacementData.readersStrength[index]);
  }

  getCustomer = (): Customer => {
    const [firstName, lastName = null] = this.order.customer_name.split(' ');
    return {
      customer_id: 'null',
      id: this.order.customer_id,
      email: this.order.customer_email,
      first_name: firstName,
      last_name: lastName,
      telephone: 'null',
      wants_marketing_emails: false,
    };
  };

  getPrescription(index: number): VisitPrescription {
    const basePrescription: VisitPrescription = {
      category: null,
      eyewear_type: null,
      type: null,
      readers_strength: null,
      customer_prescription_id: null,
    };

    if (this.isForReaders(index)) {
      return {
        ...basePrescription,
        readers_strength: this.lensReplacementData.readersStrength[index],
      };
    }

    return {
      ...basePrescription,
      customer_prescription_id: this.lensReplacementData.selectedPrescriptions[index]?.prescription_id,
    };
  }

  getProductFrameConfiguration(item: OrderItem, index: number) {
    const orderId = this.order.id;
    const itemsWithFrameConfiguration = this.frameAttributes.get(orderId);

    const baseConfiguration = {
      is_favorite: true,
      in_cart: this.lensReplacementData.type === LR_PRODUCT_TYPE.FREE,
      is_nonrx: false,
      config_pc_product_id: Number(item.pc_product_id),
      product_id: String(getFrameConfigsByOrderItem(this.frameConfigs, item).pc_product_id),
      frame_color_variant_id: getFrameConfigsByOrderItem(this.frameConfigs, item).variant.color_variant_id,
      frame_width: getFrameConfigsByOrderItem(this.frameConfigs, item).variant.width_slug,
      bi_oc_height: null,
      bi_seg_height: null,
      od_seg_height: null,
      os_seg_height: null,
      measurement_optician: null,
      measurement_type: null,
      lens_type: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'lens_type'),
      add_power_id: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'anti_fatigue_power'),
      high_index_consent_at: item.high_index_consent_at,
    };

    if (!this.isForReaders(index)) {
      return {
        ...baseConfiguration,
        bi_oc_height: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'oc_height'),
        bi_seg_height: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'bi_seg_height'),
        od_seg_height: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'seg_height_od'),
        os_seg_height: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'seg_height_os'),
        measurement_optician: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'optician'),
        measurement_type: getFrameAttributesFromMapItems(item, itemsWithFrameConfiguration, 'measurement_type'),
      };
    }

    return baseConfiguration;
  }

  getProducts(): InterestProductItem[] {
    const orderId = this.order.id;

    return this.lensReplacementData.selectedItems.map((item, index) => ({
      ...this.getProductFrameConfiguration(item, index),
      attributes: {
        lensReplacement: {
          original_sales_order_id: orderId,
          return_reason_id: this.lensReplacementData.returnReasonId[item.pc_product_id],
          original_config_pc_product_id: Number(item.pc_product_id),
          type: this.lensReplacementData.type,
        },
      },
      prescription: this.getPrescription(index),
    }));
  }

  buildEstimatePayload = (): LensReplacementEstimatePayload => ({
    customer: this.getCustomer(),
    products: this.getProducts(),
    notes: this.lensReplacementData.notes,
    is_exception_offered: this.lensReplacementData.offeredException,
  });
}

export default { LensReplacementEstimate };
