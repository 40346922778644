import { UserPrescription } from 'src/redux/lens-replacement/types';
import { SalesOrderPrescription } from '../../types';

const actualDate = (isNumber: boolean): string | number => {
  const actualDate = new Date();

  const year = actualDate.getFullYear();
  const month = actualDate.getMonth() + 1;
  const day = actualDate.getDate();

  const monthFormated = month < 10 ? `0${  month}` : month.toString();
  const dayFormated = day < 10 ? `0${  day}` : day.toString();

  const actualDateFormated = `${year}-${monthFormated}-${dayFormated}`;

  if (isNumber) {
    return  Math.floor(new Date(actualDateFormated).getTime() / 1000);
  }
  return new Date(actualDateFormated).toISOString().split('T')[0];
};

const resetTimestampToZero = (prescriptionDate: number) => {
  const formatedDate = new Date(prescriptionDate * 1000);

  formatedDate.setHours(0);
  formatedDate.setMinutes(0);
  formatedDate.setSeconds(0);
  formatedDate.setMilliseconds(0);

  return Math.floor(formatedDate.getTime() / 1000);
};

export const hasPrescriptionExpired = (salesOrderPrescription: SalesOrderPrescription | null) => {
  if (!salesOrderPrescription || !salesOrderPrescription?.expiration_date) return false;

  const { expiration_date } = salesOrderPrescription;
  const today = actualDate(typeof expiration_date !== 'string');
  const expirationDate = typeof expiration_date === 'string' ? expiration_date.split('T')[0] : resetTimestampToZero(expiration_date);

  return expirationDate < today;
};

export const hasAllPrescriptionsExpired = (prescriptions: UserPrescription[] | null) => {
  if (!prescriptions ) return false;

  return prescriptions.every(prescription => hasPrescriptionExpired(prescription));
};
