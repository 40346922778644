import {
  FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_FAILURE,
  FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_REQUEST,
  FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_SUCCESS,
  FETCH_LENS_REPLACEMENT_VALIDATION_FAILURE,
  FETCH_LENS_REPLACEMENT_VALIDATION_REQUEST,
  FETCH_LENS_REPLACEMENT_VALIDATION_SUCCESS,
  FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_FAILURE,
  FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_REQUEST,
  FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_SUCCESS,
  GetLensReplacementValidationFailure,
  GetLensReplacementValidationRequest,
  GetLensReplacementValidationSuccess,
  GetSalesOrderLRDetailsBySalesOrderIdFailureAction,
  GetSalesOrderLRDetailsBySalesOrderIdRequestAction,
  GetSalesOrderLRDetailsBySalesOrderIdSuccessAction,
  GetProductDetailsFromOriginalSalesOrderFailureAction,
  GetProductDetailsFromOriginalSalesOrderRequestAction,
  GetProductDetailsFromOriginalSalesOrderSuccessAction,
  GetProductAvailabilitiesSuccessAction,
  FETCH_PRODUCT_AVAILABILITIES_REQUEST_SUCCESS,
} from './actions';
import {
  CategoryProduct,
  ItemsLensReplacementEligibility,
  OriginalSOProductDetail,
  SalesOrderLensReplacementDetail,
} from '../../types/lens-replacement';

export const fetchLensReplacementValidationsRequest = (): GetLensReplacementValidationRequest => ({
  type: FETCH_LENS_REPLACEMENT_VALIDATION_REQUEST,
});

export const fetchLensReplacementValidationsSuccess = (
  payload: ItemsLensReplacementEligibility[],
): GetLensReplacementValidationSuccess => ({
  type: FETCH_LENS_REPLACEMENT_VALIDATION_SUCCESS,
  payload,
});

export const fetchLensReplacementValidationsFailure = (): GetLensReplacementValidationFailure => ({
  type: FETCH_LENS_REPLACEMENT_VALIDATION_FAILURE,
});

export const fetchSalesOrderLRDetailsByOrderIdRequest = (): GetSalesOrderLRDetailsBySalesOrderIdRequestAction => ({
  type: FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_REQUEST,
});

export const fetchSalesOrderLRDetailsByOrderIdSuccess = (
  payload: SalesOrderLensReplacementDetail[],
): GetSalesOrderLRDetailsBySalesOrderIdSuccessAction => ({
  type: FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_SUCCESS,
  payload,
});

export const fetchSalesOrderLRDetailsByOrderIdFailure = (): GetSalesOrderLRDetailsBySalesOrderIdFailureAction => ({
  type: FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_FAILURE,
});

export const fetchProductDetailFromOriginalSORequest = (): GetProductDetailsFromOriginalSalesOrderRequestAction => ({
  type: FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_REQUEST,
});

export const fetchProductDetailFromOriginalSOSuccess = (
  payload: OriginalSOProductDetail[],
): GetProductDetailsFromOriginalSalesOrderSuccessAction => ({
  type: FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_SUCCESS,
  payload,
});

export const fetchProductDetailFromOriginalSOFailure = (): GetProductDetailsFromOriginalSalesOrderFailureAction => ({
  type: FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_FAILURE,
});

export const fetchProductConfigsRequestSuccess = (
  payload: CategoryProduct[],
): GetProductAvailabilitiesSuccessAction => ({
  type: FETCH_PRODUCT_AVAILABILITIES_REQUEST_SUCCESS,
  payload,
});
