/* eslint-disable import/prefer-default-export */

/**
 * Formats a given number into a prescription/rx option-like value.
 *
 * @example
 * const optionValue = 8.5
 *
 * const parsedValue = formatNumberToEyeWearOption(optionValue)
 *
 * console.log(parsedValue) // '+8.50'
 */
const formatNumberToEyeWearOption = (value: number) =>
  Intl.NumberFormat('en-US', {
    signDisplay: 'exceptZero',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

export { formatNumberToEyeWearOption };
