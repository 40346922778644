export const configProductsUri = pcProductId =>
  `v1/product/${pcProductId}/config-products`;
export const frameConfigDetailsUri = (...configPcProductIds) =>
  `v1/frame-config/${configPcProductIds.join(',')}`;
export const detailedProductsUri = pcProductId =>
  `v1/detailed-product/${pcProductId}?format=nest`;
export const frameVariantWidthAndColorUri = (locale, configPcProductId) =>
  `v1/frame-variant-width/country/${locale}/frame-config/${configPcProductId}/`;

export const FRAME_FAMILY = 'FRAME_FAMILY';
export const FRAME_ASSEMBLY = 'FRAME_ASSEMBLY';
export const LENS_REPLACEMENT_FEATURE = 'LENS_REPLACEMENT';
export enum LensReplacementFeatures {
  Free = 'free_lens_replacement',
  Paid = 'paid_lens_replacement',
}

export const configFrameAssemblyProductUri = (stockType: string, pcProductId: string | number) =>
  `v1/frame-assembly/stock-type/${stockType}/sibling-frame-assembly/${pcProductId}`;

export const configsProductUri = (pcProductId: string) =>
  `v1/product/${pcProductId}/categories`;
