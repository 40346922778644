/* eslint-disable import/prefer-default-export */
import React from 'react';
import { OptionContainer, StyledBadge } from './styles';

type ReadersOptionProps = {
  hasPreviouslyUsed?: boolean | undefined
  onChangeValue: (newValue: string) => void
  selectedOption: string | null
  value: string
};

const ReadersOption: React.FC<ReadersOptionProps> = ({
  hasPreviouslyUsed = false,
  onChangeValue,
  selectedOption,
  value,
}) => {
  const optionId = React.useMemo(() => `readers-option__${value}`, [value]);

  return (
    <OptionContainer htmlFor={optionId}>
      <input
        id={optionId}
        type='radio'
        checked={selectedOption === value}
        onChange={evt => onChangeValue(evt.target.value)}
        value={value}
      />

      {value}

      {hasPreviouslyUsed && (
        <StyledBadge variant='subtle'>Previously used</StyledBadge>
      )}
    </OptionContainer>
  );
};

export { ReadersOption };
