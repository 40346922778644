import { actions } from 'src/redux/actions';
import { ReturnSummary, Dispatch } from 'types';
import { apiFactory } from 'src/redux/actions-wip';
import { Endpoint } from 'types-wip';
import { redirect } from '../app/routeUtils';

export const getReturnReceiptPath = (cmId) => `/api/v1/credit-memo/${cmId}/sales-order-return/receive`;
const salesOrderReturnAddPath = '/api/v1/sales-order-return/add';

export type ReturnRunnerSteps = {
  authorizeReturn?: boolean;
  receiveReturn?: boolean;
  issueRefund?: boolean;
  redirectToShop?: boolean;
  redirectToCreditMemo?: boolean;
  refreshCreditMemo?: boolean;
};

export const runReturn = async (
  returnBody: string,
  creditMemoId: string,
  customerId: string,
  jwt: string,
  dispatch: Dispatch,
  steps: ReturnRunnerSteps,
): Promise<void> => {
  let estimateId = null;

  if (steps.authorizeReturn) {
    if (creditMemoId) {
      throw new Error('Only one of returnBody and creditMemoId may be specified');
    }

    const postParams = { method: 'POST', body: returnBody };
    const returnAuthorization: ReturnSummary = await dispatch(actions.fetchAPI(salesOrderReturnAddPath, jwt, postParams)) ;
    creditMemoId = returnAuthorization.credit_memo_id;
    estimateId = returnAuthorization.estimate_id;
  }

  if (steps.receiveReturn) {
    if (!creditMemoId) {
      throw new Error('Could not receive return because creditMemoId was not found or provided');
    }

    await dispatch(actions.fetchAPI(getReturnReceiptPath(creditMemoId), jwt, { method: 'POST' }));
  }

  if (steps.redirectToShop) {
    window.location.href = `https://${process.env.POE_DOMAIN}/visit/estimate/${estimateId}`;
  }

  if (steps.redirectToCreditMemo) {
    redirect(`/customer/${customerId}/credit-memo/${creditMemoId}`);
  }

  if (steps.refreshCreditMemo) {
    dispatch(apiFactory(Endpoint.CreditMemo, jwt, creditMemoId));
  }
};
