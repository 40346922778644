import React from 'react';

import { Order, CSSStyle } from 'types';
import { formatDate, formatPrice } from 'selectors';
import { styles } from 'style';

const orderShipping = (order: Order): string | number => (
  order.shipping_total === 0 ? 'Free' : formatPrice(order.shipping_total)
);

const formatAdjustments = (order: Order): JSX.Element[] => {
  const { adjustments } = order;
  if (!adjustments) {
    return;
  }

  return adjustments.map((adjustment) => {
    const {
      amount, created, description, display_created,
    } = adjustment;
    const adjustmentDate = display_created ? `(${formatDate(created)})` : '';

    return (
      <span style={style.wrapper} key={created}>
        <p style={para}>
          {description}
          {' '}
          {adjustmentDate}
        </p>
        <p style={para}>
          -
          {formatPrice(amount)}
        </p>
      </span>
    );
  });
};

const style: CSSStyle = {
  bold: {
    fontWeight: 700,
  },
  chin: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px',
  },
  container: {
    margin: '0 auto',
    padding: '30px',
  },
  hr: {
    border: '1px solid #E4E6E8',
  },
  largePara: {
    fontSize: '22px',
  },
  padding: {
    padding: '0 0 10px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};

const para = { ...styles.para, ...style.padding };
const boldPara = { ...para, ...style.bold };
const largePara = { ...boldPara, ...style.largePara };

interface OrderLedgerProps {
  canServiceGesture: boolean;
  href?: string;
  last4?: string;
  order: Order;
  refund?: number;
}

const OrderLedger: React.StatelessComponent<OrderLedgerProps> = ({ order, canServiceGesture }) => {
  const adjustments = order && formatAdjustments(order);

  return (
    <>
      <div style={style.container}>
        <span style={style.wrapper}>
          <p style={para}>Subtotal</p>
          <p style={para}>{formatPrice(order.subtotal)}</p>
        </span>
        <span style={style.wrapper}>
          <p style={para}>Discounts</p>
          <p style={para}>{formatPrice(order.adjustment_total * -1)}</p>
        </span>
        <span style={style.wrapper}>
          <p style={para}>Shipping</p>
          <p style={para}>{orderShipping(order)}</p>
        </span>
        <span style={style.wrapper}>
          <p style={para}>Tax</p>
          <p style={para}>{formatPrice(order.tax_total)}</p>
        </span>
        {canServiceGesture && adjustments}
      </div>
      <hr style={style.hr} />
      <div style={style.container}>
        <span style={style.wrapper}>
          <p style={boldPara}>Total</p>
          <p style={boldPara}>{formatPrice(order.adjusted_total)}</p>
        </span>
      </div>
    </>
  );
};

export default OrderLedger;
