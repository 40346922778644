import React from 'react';

import { Interaction, InteractionType, CSSStyle } from 'types';

import OrderPill from '../order/OrderPill';
import CreditMemoPill from '../credit-memo/CreditMemoPill';

const configureInteractionPills = (customerId: string, interactions: Interaction[]): JSX.Element[] => (
  interactions.map((interaction) => {
    if (interaction.interactionType === InteractionType.SalesOrder) {
      return (<OrderPill key={`order-${interaction.salesOrder.id}`} customerId={customerId} salesOrder={interaction.salesOrder} />);
    }

    if (interaction.interactionType === InteractionType.CreditMemo) {
      return (<CreditMemoPill key={`credit-memo-${interaction.creditMemo.id}`} customerId={customerId} creditMemo={interaction.creditMemo} />);
    }
  })
);

const style: CSSStyle = {
  boldPara: {
    color: '#525E69',
    margin: '50px 0 10px',
    fontSize: '19px',
    fontWeight: 600,
  },
};

interface InterActionDayProps {
  customerId: string;
  orderDate: string;
  interactions: Interaction[];
}

const InteractionDay: React.StatelessComponent<InterActionDayProps> = ({
  customerId,
  orderDate,
  interactions,
}) => (
  <>
    <p style={style.boldPara}>{orderDate}</p>
    {configureInteractionPills(customerId, interactions)}
  </>
);

export default InteractionDay;
