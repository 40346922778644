import React from 'react';

import CreditMemoRouter from 'src/components/credit-memo/CreditMemoRouter';
import CreditMemoDetail from 'src/components/credit-memo/CreditMemoDetail';
import InteractionList from 'src/components/customer/InteractionList';
import OrderContainer from 'src/components/order/OrderContainer';
import ErrorScreen from 'src/shared-components/screens/ErrorScreen';
import CancelWarning from 'src/components/cancel/CancelWarning';
import CancelType from 'src/components/cancel/CancelType';
import CancelConfirmation from 'src/components/cancel/CancelConfirmation';
import ServiceGesturePreset from 'src/components/sg/ServiceGesturePreset';
import ServiceGesturePresetReasonContainer from 'src/components/sg/ServiceGesturePresetReasonContainer';
import ServiceGestureConfirmationContainer from 'src/components/sg/ServiceGestureConfirmationContainer';
import ReturnItemList from 'src/components/return/ReturnItemList';
import ReturnReasonType from 'src/components/return/ReturnReasonType';
import ReturnReason from 'src/components/return/ReturnReason';
import ReturnConfirmation from 'src/components/return/ReturnConfirmation';
import LensReplacementPage from '../lens-replacement/LensReplacementPage';
import { formatParams } from './routeUtils';

const appRoutes = [
  {
    path: '/customer/:id',
    component: InteractionList,
  },
  {
    path: '/customer/:id/order/:id',
    component: OrderContainer,
  },
  {
    path: '/order/:id/cancel-warning',
    component: CancelWarning,
  },
  {
    path: '/order/:id/cancel',
    component: CancelType,
  },
  {
    path: '/order/:id/cancel/:id/confirmation',
    component: CancelConfirmation,
  },
  {
    path: '/credit-memo/:id',
    component: CreditMemoRouter,
  },
  {
    path: '/customer/:id/credit-memo/:id',
    component: CreditMemoDetail,
  },
  {
    path: '/order/:id/service-gesture',
    component: ServiceGesturePreset,
  },
  {
    path: '/order/:id/service-gesture/:id',
    component: ServiceGesturePresetReasonContainer,
  },
  {
    path: '/order/:id/service-gesture/:id/reason/:id/confirmation',
    component: ServiceGestureConfirmationContainer,
  },
  {
    path: '/order/:id/return-items/:return-order-type',
    component: ReturnItemList,
  },
  {
    path: '/order/:id/return-items/:return-order-type/items/:id',
    component: ReturnReasonType,
  },
  {
    path: '/order/:id/return-items/:return-order-type/items/:id/reason-type/:id',
    component: ReturnReason,
  },
  {
    path: '/customer/:id/order/:id/return-items/:return-order-type/items/:id/reason-type/:id/reason/:id/confirmation',
    component: ReturnConfirmation,
  },
  {
    path: '/customer/:id/order/:id/lens-replacement',
    component: LensReplacementPage,
  },
];

export const compare = (uri: string): JSX.Element => {
  const uriParts = uri.split('/'); // uri parts
  let component;

  for (const routeData of appRoutes) { // iterate through each defined route option
    const pathParts = routeData.path.split('/'); // split the path into parts
    if (pathParts.length === uriParts.length) { // only execute logic if the route lengths same
      for (const [idx, uriPart] of uriParts.entries()) { // iterate over uri parts
        const placement = idx + 1; // format index to be able to test against array length
        const isLastElement = placement === uriParts.length;
        const matchedPart = pathParts[idx]; // path part to compare against uri part
        const isId = matchedPart && matchedPart.includes(':'); // matchedPart is some type of identifier that begins w/ colon
        const previous = idx - 1;

        if (!isId && matchedPart !== uriPart) { // if the two parts aren't equal and aren't ids
          break;
        }

        // if path part is id, previous elements in uri/path parts match, and uri part is last ele
        // in uri parts array
        if (isId && pathParts[previous] === uriParts[previous] && isLastElement) {
          component = routeData.component;
          return component;
        }

        // if uri part is last ele and uri/path parts match
        if (isLastElement && matchedPart === uriPart) {
          component = routeData.component;
          return component;
        }
      }
    }
  }

  return component || ErrorScreen;
};

export interface Location {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: null;
}

interface RouterProps {
  location: Location;
}

const Router = ({ location }: RouterProps): JSX.Element => {
  const { pathname, search } = location;
  const Component = compare(pathname) as any;
  const params = formatParams(location.pathname);

  return (
    <Component location={location} nextURL={search} params={params} />
  );
};

export default Router;
