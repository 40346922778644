/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';

import { StyledInput, StyledLabel } from './styles';

type InputProps = React.DetailedHTMLProps<
React.InputHTMLAttributes<HTMLInputElement>,
HTMLInputElement
>;

interface CheckBoxProps extends InputProps {
  isChecked?: boolean;
  handleCheck?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  type?: string;
}

export const CheckboxField: React.FC<CheckBoxProps> = ({
  isChecked = false,
  handleCheck = () => {},
  label,
  type = 'checkbox',
}) => {
  const id = useMemo(() => `checkbox-${Math.ceil(Math.random() * 10e12).toString(16)}`, []);

  return (
    <StyledLabel htmlFor={id}>
      <StyledInput id={id} type={type} checked={isChecked} onChange={handleCheck} />
      {label && <span>{label}</span>}
    </StyledLabel>
  );
};

export default CheckboxField;
