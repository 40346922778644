import { DetailedProduct, Order, OrderItem, SalesOrder } from '../../../types';
import { LENS_REPLACEMENT_FEATURE, LensReplacementFeatures } from '../../redux/lens-replacement/constants';
import {
  OriginalSOProductDetail,
  SalesOrderLensReplacementDetail,
} from '../../types/lens-replacement';

export const createItemInvoiceReturnablePredicate = (property: string) => (item: OrderItem): boolean =>
  item.invoices && item.invoices.some((invoice) => {
    if (!invoice.returnable) return false;
    if (invoice[property] === null) return true;
    return invoice.quantity > invoice[property];
  });

export const formatCancelHREF = (items: OrderItem[], order: Order): string => {
  if (!order.cancelable) {
    return '#';
  }

  if (items && items.length > 1) {
    return `/order/${order.id}/cancel-warning`;
  }

  return `/order/${order.id}/cancel`;
};

export const getLensReplacementSuffix = (
  lensReplacementDetails: SalesOrderLensReplacementDetail[],
  originalSalesOrderProductDetails: OriginalSOProductDetail[],
  itemId: number,
) => {
  if (!lensReplacementDetails.length || !originalSalesOrderProductDetails.length) {
    return null;
  }

  const lrProductDetails = lensReplacementDetails.find(
    item => item.sales_order_item_id === itemId,
  );

  if (!lrProductDetails) return null;

  const originalProductDetails = originalSalesOrderProductDetails.find(
    (item) => item.pc_product_id === lrProductDetails.frame_pc_product_id,
  );

  if (!originalProductDetails) return null;

  const { displayName } = originalProductDetails;

  if (!displayName) return null;

  return `for ${displayName}`;
};

export const formatLensReplacementProductName = (
  productName: string,
  suffix: string,
  lensReplacementSalesOrderItems: DetailedProduct[],
  pcProductId: number,
) => {
  const lensReplacementItem = lensReplacementSalesOrderItems.find((item) => item.pc_product_id === pcProductId);

  if (!lensReplacementItem || !lensReplacementItem?.features?.length) return null;

  const { features } = lensReplacementItem;

  const lensReplacementFeature = features.filter((feature) => feature.type_id === LENS_REPLACEMENT_FEATURE);

  if (!lensReplacementFeature || !lensReplacementFeature.length) return null;

  const paidLensReplacementFeature = lensReplacementFeature.find(
    (lrFeature) => lrFeature.description === LensReplacementFeatures.Paid,
  );

  return paidLensReplacementFeature
    ? productName.replace('Lens Replacement', `Paid Lens Replacement ${suffix}`)
    : `${productName} ${suffix}`;
};

export const getStatusFromSalesOrder = ({ order_status }: SalesOrder): string => {
  const status = order_status[0].name;
  const hasStatus = !!status;
  if (!hasStatus) {
    return 'No status available';
  }

  if (status === 'Pending order processing') {
    return 'Pending';
  }

  return status;
};
