import styled from 'react-emotion';
import colors from 'src/utils/colors';

export const Pill = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border: 1px solid ${colors.grayBorder};
  border-radius: 8px;
  margin: 0 auto 15px;
  text-align: left;
  text-decoration: none;
  grid-column: 1 / span 3;
`;

export const Table = styled('table')`
  align-self: flex-start;
  border-radius: 8px;
  border-collapse: collapse;
  overflow: hidden;
`;

export const Label = styled('div')`
  font-size: 12px;
  font-weight: 100;
  color: #676f78;
  min-width: 90px;
`;

const sharedCellStyles = `
background: ${colors.grayLight};
border: 1px solid ${colors.grayBorder};
`;

export const CellTitle = Label.withComponent(styled('th')`
  ${sharedCellStyles}
  padding: 8px;
`);

export const CellColumn = Label.withComponent(styled('td')`
  ${sharedCellStyles}
  padding: 15px;
`);

export const CellValue = styled('div')`
  font-size: 14px;
  padding: 0px 8px;
  border: 1px solid ${colors.grayBorder};
`.withComponent('td');
