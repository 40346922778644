import React, {
  useRef,
  SelectHTMLAttributes,
} from 'react';

import { Container } from './styles';

interface ISelectFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  defaultValue?: string | number
  options: { label: string; value: string | number }[]
  value?: string | number
  placeholder: string
}

export const SelectField: React.FC<ISelectFieldProps> = ({
  name,
  options,
  placeholder,
  defaultValue = '',
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  return (
    <Container>
      <select
        defaultValue={defaultValue ?? ''}
        ref={selectRef}
        {...rest}
      >
        <option disabled value="">
          {placeholder}
        </option>

        {options.map(option => (
          <option key={`${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default SelectField;
