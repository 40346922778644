import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actions } from 'src/redux/actions';
import {
  apiLoading, apiStatus, apiResponse, getOrder,
} from 'selectors';
import {
  ReduxState, CancelReason, Dispatch, Order, Params,
} from 'types';
import { styles } from 'style';

import Nav from 'src/shared-components/screens/Nav';
import Loading from 'src/shared-components/screens/Loading';
import SelectionPill from 'src/shared-components/order-actions/SelectionPill';

const cancelTypesPath = (): string => '/v1/sales-order/cancel-reasons';

const formatCancelPills = (cancelTypes: CancelReason[], orderId: number): JSX.Element[] => (
  cancelTypes.map((cancel) => {
    const href = `/order/${orderId}/cancel/${cancel.id}/confirmation`;
    return <SelectionPill data-cy={`order-${orderId}-cancel-${cancel.id}`} key={cancel.id} href={href} text={cancel.type} />;
  })
);

interface CancelTypeProps {
  cancelTypes: CancelReason[];
  dispatch: Dispatch;
  hasRequestedData: boolean;
  jwt: string;
  loading: boolean;
  order: Order;
  params: Params;
}

const CancelType: React.StatelessComponent<CancelTypeProps> = ({
  cancelTypes, loading, jwt, dispatch, hasRequestedData, params, order,
}) => {
  useEffect(actions.fetchOrder(dispatch, jwt, params), []);

  useEffect(() => {
    if (jwt && !hasRequestedData) {
      const path = cancelTypesPath();
      dispatch(actions.fetchOhmAPI(path, jwt));
    }
  }, []);

  if (!order) {
    return <Loading />;
  }

  const cancelLinks = cancelTypes && formatCancelPills(cancelTypes, order.id);

  return (
    <div className="page">
      <div className="content">
        <Nav
          href={`/customer/${order.customer_id}/order/${order.id}`}
          text="Cancel Items"
          subtext=""
        />
        <p style={styles.largeBoldPara}>Cancel Type</p>
        {loading ? <Loading /> : cancelLinks}
      </div>
    </div>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);

  const path = cancelTypesPath();
  const cancelTypesStatus = apiStatus(state, path);
  const cancelTypes = apiResponse(cancelTypesStatus);

  return ({
    hasRequestedData: !!cancelTypes,
    cancelTypes,
    jwt: state.auth.jwt,
    loading: apiLoading(cancelTypesStatus),
    order,
  });
};

export default connect(select)(CancelType);
