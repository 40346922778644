import React from 'react';

type FunctionType =
  | (() => any)
  | ((...args: any[]) => any);

const useCallbackRef = <T extends FunctionType>(callback: T) => {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  return React.useMemo(() => ((...args) => callbackRef.current?.(...args)) as T, []);
};

export { useCallbackRef };
