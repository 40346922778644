import { CSSStyle } from '../../../types';

export const style: CSSStyle = {
  boldPara: {
    color: '#525E69',
    fontSize: '16px',
    fontWeight: 600,
    margin: '0',
  },
  fixed: {
    display: 'flex',
    alignItems: 'center',
    bottom: '0',
    background: 'white',
    flexWrap: 'wrap',
    left: '0',
    margin: '0 auto',
    maxWidth: '768px',
    padding: '0 30px',
    position: 'fixed',
    right: '0',
    zIndex: 2,
  },
  margin: {
    margin: '0 0 15px',
  },
  orange: {
    color: '#D55C12',
  },
  pill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    display: 'flex',
    margin: '0 auto 15px',
    padding: '18px 30px',
    textAlign: 'left',
    textDecoration: 'none',
  },
};
