import * as React from 'react';

import { styles } from 'style';

import Link from 'src/components/app/Link';

interface SelectionPillProps {
  href?: string;
  onClick?(event: any): void;
  subtext: string;
  text: string;
}

const EditableSelectionPill: React.StatelessComponent<SelectionPillProps> = ({
  subtext, text, href, onClick,
}) => (
  <div style={styles.editablePill}>
    <span>
      <p style={styles.microPara}>{subtext}</p>
      <p style={styles.boldPara}>{text}</p>
    </span>
    {onClick && <button style={styles.editButton} onClick={onClick}>Edit</button>}
    {href && <Link href={href} style={styles.editButton}>Edit</Link>}
  </div>
);

export default EditableSelectionPill;
