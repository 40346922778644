import * as React from 'react';

import { CSSStyle, Order } from 'types';
import { styles } from 'style';

const style: CSSStyle = {
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  pill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto 15px',
    padding: '24px 30px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  flexPara: {
    display: 'flex',
    alignSelf: 'flex-end',
    fontSize: '18px',
    color: '#525E69',
    margin: '0 0 0 auto',
  },
};

interface CustomerDetailProps {
  order: Order;
}

const CustomerDetail: React.StatelessComponent<CustomerDetailProps> = ({ order: { customer_name, customer_email } }) => (
  <div style={style.pill}>
    <div style={style.wrapper}>
      <span>
        <p style={styles.microPara}>Who is this order for?</p>
        <p style={styles.boldPara}>{customer_name}</p>
      </span>
      <p style={style.flexPara}>{customer_email}</p>
    </div>
  </div>
);

export default CustomerDetail;
