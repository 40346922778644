import React from 'react';
import { styles } from 'style';

import { InsuranceBreakdown } from 'types';
import { formatPrice } from 'selectors';
import { Badge } from '../../badge/Badge';
import { style } from './styles';

interface InsuranceDetailProps {
  insurance: InsuranceBreakdown
}

const InsuranceDetail: React.StatelessComponent<InsuranceDetailProps> = ({ insurance }) => (
    <div style={style.pill} >
      <span>
        <p style={styles.microPara}>Insurance Details</p>
        <p style={style.boldPara}>Benefits Applied - {formatPrice(insurance.benefit_amount)}</p>
        <p style={style.boldPara}>Copay Sub Total - {formatPrice(insurance.copay_amount)}</p>
      </span>
      <div style={style.flexPara}>
        <Badge variant="notification">INSURANCE</Badge>
      </div>
    </div>
);

export default InsuranceDetail;
