import React from 'react';
import { connect } from 'react-redux';
import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { clamp } from 'src/utils/numbers';
import { useConst } from 'src/hooks/useConst';
import { UserPrescription } from 'src/redux/lens-replacement/types';
import { PrescriptionStepData, StepperProviderComponentProps, UpdatePrescriptionFn } from './types';
import { PrescriptionStepperContextProvider, usePrescriptionStepperContext } from './context';
import { useLRStepper } from '../lr-stepper';
import PrescriptionStep from '../steps/prescription-step';

const usePrescriptionStepper = (stepName: string, expectError = false) => {
  const context = usePrescriptionStepperContext(stepName, !expectError);
  return context;
};

const StepperProviderComponent: React.FC<StepperProviderComponentProps> = ({
  params,
}) => {

  const { data:lrData } = useLRStepper('PrescriptionStepperProvider');
  const { selectedItems } = lrData;
  const stepsCount = useConst(selectedItems.length);
  const data = React.useRef<PrescriptionStepData>({
    selectedPrescriptions : new Array(stepsCount).fill(null),
  });

  const [stepIdx, setStepIdx] = React.useState(0);

  const currentItem = React.useMemo(
    ()=> selectedItems[stepIdx], [selectedItems, stepIdx],
  );

  const currentPrescription = React.useMemo(
    ()=> data.current.selectedPrescriptions[stepIdx] ?? null,
    [data.current.selectedPrescriptions[stepIdx], stepIdx],
  );

  const clampSteps = useCallbackRef((value: number) => (
    clamp(value, 0, stepsCount)
  ));

  const updatePrescription = useCallbackRef<UpdatePrescriptionFn>((newData) => {
    data.current.selectedPrescriptions[stepIdx] = newData;
  });


  const nextStep = useCallbackRef(() => {
    setStepIdx(oldStep => clampSteps(oldStep + 1));
  });

  const previousStep = useCallbackRef(() => {
    setStepIdx(oldStep => clampSteps(oldStep - 1));
  });

  const setAllPrescriptions = useCallbackRef((prescription: UserPrescription) => {
    data.current.selectedPrescriptions = data.current.selectedPrescriptions.map(() => prescription);
  });

  const isLastItem = stepsCount - 1 === stepIdx;

  return (
    <PrescriptionStepperContextProvider
      data={data.current}
      updateData={updatePrescription}
      nextStep={nextStep}
      previousStep={previousStep}
      stepIdx={stepIdx}
      currentItem={currentItem}
      isLastItem={isLastItem}
      currentPrescription={currentPrescription}
      setAllPrescriptions={setAllPrescriptions}
    >
      <PrescriptionStep params={params}/>
    </PrescriptionStepperContextProvider>
  );
};


const PrescriptionStepperProvider = connect()(StepperProviderComponent);
export { PrescriptionStepperProvider, usePrescriptionStepper };
