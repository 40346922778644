import OhmClient, { Service as OhmService } from '@warbyparker/ohmsdk';
import { LR_PRODUCT_TYPE } from 'src/utils/constants';
import { LRData } from 'src/components/lens-replacement/lr-stepper/types';
import { Dispatch, MeFacility, Order } from '../../../types';
import {
  fetchLensReplacementValidationsFailure,
  fetchLensReplacementValidationsRequest,
  fetchLensReplacementValidationsSuccess,
  fetchSalesOrderLRDetailsByOrderIdFailure,
  fetchSalesOrderLRDetailsByOrderIdRequest,
  fetchSalesOrderLRDetailsByOrderIdSuccess,
  fetchProductDetailFromOriginalSOFailure,
  fetchProductDetailFromOriginalSORequest,
  fetchProductDetailFromOriginalSOSuccess,
  fetchProductConfigsRequestSuccess,
} from './action-creators';
import {
  CategoryProduct,
  ConfigGalleryProduct,
  ConfiguredProduct,
  ConfiguredProductDetailResponse,
  ConfiguredProductsResponse,
  FrameConfigDetails,
  FrameVariantWidth,
  ItemsLensReplacementEligibility,
  LREligibilityValidationResponse,
  OrderWithFrameAttributes,
  OriginalSOProductDetail,
  OriginalSOProductDetailResponse,
  SalesOrderLRDetailsBySalesOrderResponse,
} from '../../types/lens-replacement';
import {
  GET_PRESCRIPTIONS_FAILURE,
  GET_PRESCRIPTIONS_REQUEST,
  GET_PRESCRIPTIONS_SUCCESS,
} from './actions';
import {
  LREstimateRequestResponse,
  UserPrescription,
} from './types';
import { configFrameAssemblyProductUri, configProductsUri, configsProductUri, detailedProductsUri, FRAME_FAMILY, frameConfigDetailsUri, frameVariantWidthAndColorUri } from './constants';
import { isFrameAssembly } from '../../components/lens-replacement/utils';
import { LensReplacementEstimate } from './lens-replacement-estimate-builder';
import { isSunLensCategory } from './util';

export const fetchLensReplacementValidation =
  (orderId: number, jwt: string) => async (dispatch: Dispatch) => {
    dispatch(fetchLensReplacementValidationsRequest());

    try {
      const lrValidationUrl = `/api/v1/sales_order/${orderId}/verify-lens-replacement-eligibility`;
      const response: Response = await makeApiRequest(lrValidationUrl, jwt);


      if (response.status !== 200) {
        dispatch(fetchLensReplacementValidationsFailure());
        return;
      }

      const { items } = (await response.json()) as LREligibilityValidationResponse;
      const mapOfItems: ItemsLensReplacementEligibility[] = items.map((item) => {
        const [key, value] = Object.entries(item)[0];
        return new Map([[key, value]]);
      });

      dispatch(fetchLensReplacementValidationsSuccess(mapOfItems));
    } catch (error) {
      dispatch(fetchLensReplacementValidationsFailure());
    }
  };

export const fetchUserPrescriptions = (customerId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: GET_PRESCRIPTIONS_REQUEST, customerId });

  try {
    const response: Response = await fetch(
      `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/customers/${customerId}/prescriptions`,
      { credentials: 'include' },
    );

    if (response.status === 200) {
      const data = (await response.json()) as UserPrescription[];

      dispatch({ type: GET_PRESCRIPTIONS_SUCCESS, customerId, prescriptions: data });
    } else {
      const message = ((await response.json())?.message as string) || 'Something went wrong';
      dispatch({ type: GET_PRESCRIPTIONS_FAILURE, customerId, error: message });
    }
  } catch (error) {
    dispatch({ type: GET_PRESCRIPTIONS_FAILURE, customerId, error: (error as Error)?.message });
  }
};

export const fetchLensReplacementDetailsByLensReplacementSalesOrderId =
  (orderId: number, jwt: string) => async (dispatch: Dispatch) => {
    dispatch(fetchSalesOrderLRDetailsByOrderIdRequest());

    try {
      const lrDetailsUrl = `/api/v1/lens-replacement/details/by-sales-order/${orderId}`;
      const response: Response = await makeApiRequest(lrDetailsUrl, jwt);


      if (response.status !== 200) {
        dispatch(fetchSalesOrderLRDetailsByOrderIdFailure());
        return;
      }

      const { data } = (await response.json()) as SalesOrderLRDetailsBySalesOrderResponse;
      dispatch(fetchSalesOrderLRDetailsByOrderIdSuccess(data));
    } catch (error) {
      dispatch(fetchSalesOrderLRDetailsByOrderIdFailure());
    }
  };

export const handleLREstimate =
  (jwt: string, order: Order, lrData: LRData, attributes: OrderWithFrameAttributes, facility: MeFacility) =>
    async () => {
      try {
        const client = new OhmClient(OhmService.PIM, jwt);

        const frameConfigs = await Promise.all<ConfigGalleryProduct>(
          lrData.selectedItems.map(async (item) => {
            const [
              frameAssemblyProduct,
              configVariantWidthAndColor,
              frameConfigDetails,
            ] = await Promise.all([
              getFrameAssemblyProduct(Number(item.pc_product_id), client),
              getFrameConfigVariantWidthAndColor(facility.locale, Number(item.pc_product_id), client),
              getFrameConfigDetails(client, item.pc_product_id),
            ] as const);

            const [frameConfigDetailsItem] = frameConfigDetails;
            const isSunGlasses = isSunLensCategory(frameConfigDetailsItem);

            if (isSunGlasses) {
              const sunProduct = await getFrameAssemblyConfigProduct(Number(frameAssemblyProduct.pc_product_id), client);
              return { [item.pc_product_id]: { ...(sunProduct || frameAssemblyProduct), variant: configVariantWidthAndColor } };
            }

            return { [item.pc_product_id]: { ...frameAssemblyProduct, variant: configVariantWidthAndColor } };
          }),
        );

        const lrVisitWithEstimate = await createLRVisitWithEstimate(
          jwt,
          order,
          lrData,
          frameConfigs,
          attributes,
        );

        const hasPaidType = lrData.type === LR_PRODUCT_TYPE.PAID;
        window.location.replace(buildLrPoeUrl(lrVisitWithEstimate, hasPaidType));
      } catch (error) {
        logger.error(error, 'Could not handle LR order');
      }
    };

const getFrameAssemblyProduct = async (pcProductId: string | number, client: OhmClient) => {
  try {
    const response = await client.get<ConfiguredProductsResponse>(configProductsUri(pcProductId));

    const configuredProducts = response.data;

    return configuredProducts.find((config) => isFrameAssembly(config));
  } catch (error) {
    throw new Error('Error getting name from configuration');
  }
};

const getFrameConfigDetails = async (client: OhmClient, ...configPcProductIds: Array<string | number>) => {
  if (!configPcProductIds.length) return [];

  try {
    const response = await client.get<FrameConfigDetails[]>(frameConfigDetailsUri(...configPcProductIds));

    return response.data;
  } catch (error) {
    throw new Error('Failed to get details from frame configuration');
  }
};

const getFrameConfigVariantWidthAndColor = async (locale: string, configPcProductId: string | number, client: OhmClient) => {
  try {
    const response = await client.get<FrameVariantWidth[]>(frameVariantWidthAndColorUri(locale, configPcProductId));

    return response.data[0];
  } catch (error) {
    throw new Error('Error getting config variant data from configuration');
  }
};

const getFrameAssemblyConfigProduct = async (pcProductId: string | number, client: OhmClient) => {
  try {
    const response = await client.get(configFrameAssemblyProductUri('SRW', pcProductId));

    return response.data ? response.data[0] as ConfiguredProduct : null;
  } catch (error) {
    throw new Error('Error getting ids from configuration');
  }
};

const getDisplayNameForOriginalFrame = (
  productDetails: OriginalSOProductDetail[],
  client: OhmClient,
) => Promise.all(productDetails.map(async (item: OriginalSOProductDetail) => {
  if (isFrameAssembly(item)) {
    return {
      ...item,
      displayName: item.virtual_product_name, // instead of going through the categories
    };
  }

  const frameAssemblyProduct = await getFrameAssemblyProduct(item.pc_product_id, client);

  const frameAssemblyProductId = frameAssemblyProduct.pc_product_id;
  const detailsResponse = await client.get(detailedProductsUri(frameAssemblyProductId));

  if (detailsResponse.status !== 200) throw new Error('Error getting details of configuration');

  const data = detailsResponse.data as ConfiguredProductDetailResponse;

  if (!data.length) throw new Error(`No details returned from frame assembly product ${frameAssemblyProductId}`);

  const { categories } = data[0];
  const frameFamilyCategory = (categories || []).find((category) => category.type_id === FRAME_FAMILY);

  return {
    ...item,
    displayName: frameFamilyCategory.name,
  };
}));

export const createLRVisitWithEstimate = async (
  jwt: string,
  order: Order,
  lrData: LRData,
  frameConfigs: ConfigGalleryProduct[],
  frameAttributes: OrderWithFrameAttributes,
): Promise<LREstimateRequestResponse> => {
  const lrDetailsUrl = 'api/v1/lens-replacement/create-estimate';
  const lensReplacementEstimatePayload = new LensReplacementEstimate(order, lrData, frameConfigs, frameAttributes);

  const response: Response = await fetch(
    `https://${process.env.HELIOS_RETAIL_DOMAIN}/${lrDetailsUrl}`,
    {
      body: JSON.stringify(lensReplacementEstimatePayload.buildEstimatePayload()),
      headers: { Authorization: `Bearer ${jwt}` },
      method: 'POST',
    },
  );

  if (!response.ok) throw new Error(`Response Status is not ok (${response.status})`);

  return response.json();
};

export const buildLrPoeUrl = (
  lrVisitWithEstimate: LREstimateRequestResponse,
  hasPaidType: boolean,
) => {
  let poeUri = `new-visit/${lrVisitWithEstimate.id}/estimate/${lrVisitWithEstimate.estimate_id}`;

  if (hasPaidType && lrVisitWithEstimate.items.length) {
    const { product_id, id } = lrVisitWithEstimate.items[0];
    poeUri = poeUri.concat(`/lens-replacement-product-config/${id}?pid=${product_id}`);
  }

  return `https://${process.env.POE_DOMAIN}/${poeUri}`;
};

export const fetchProductDetailsFromOriginalSalesOrder =
  (pcProductIds: number[], jwt: string) => async (dispatch: Dispatch) => {
    const idsParam = pcProductIds.join(',');

    if (!idsParam) return;

    try {
      dispatch(fetchProductDetailFromOriginalSORequest());
      const uri = `v1/detailed-product/${idsParam}?format=nest`;
      const client = new OhmClient(OhmService.PIM, jwt);
      const response = await client.get(uri);

      if (response.status !== 200) {
        return;
      }

      const originalProductsDetails = response.data as OriginalSOProductDetailResponse;
      const productsWithDisplayName = await getDisplayNameForOriginalFrame(originalProductsDetails, client);

      dispatch(fetchProductDetailFromOriginalSOSuccess(productsWithDisplayName));
    } catch (error) {
      dispatch(fetchProductDetailFromOriginalSOFailure());
    }
  };

export const fetchProductAvailabilities =
  (pcProductIds: string[], jwt: string) => async (dispatch: Dispatch) => {
    if (!pcProductIds) return;
    try {
      const formatedResponse = {};
      const client = new OhmClient(OhmService.PIM, jwt);
      const frameConfigs = await Promise.all(
        pcProductIds.map(async (id) => {
          const frameAssemblyProduct = await Promise.all([
            getFrameAssemblyProduct(Number(id), client),
          ]);
          const product_id = frameAssemblyProduct.length ? frameAssemblyProduct[0].pc_product_id : null;
          formatedResponse[product_id] = id;
          return product_id;
        }),
      );
      if (!frameConfigs) return;

      const idsParam = frameConfigs.filter((id) => id).join(',');

      const response = await client.get(configsProductUri(idsParam));

      if (response.status !== 200) {
        return;
      }

      const productCategories = response.data as CategoryProduct[];
      const dataFormated = productCategories.map((product) => {
        product.pc_product_config_id = formatedResponse[product.pc_product_id];
        return product;
      });

      dispatch(fetchProductConfigsRequestSuccess(dataFormated));

    } catch (error) {
      return [];
    }
  };

const makeApiRequest = async (url: string, jwt: string): Promise<Response> => fetch(
  `https://${process.env.HELIOS_RETAIL_DOMAIN}${url}`,
  { headers: { Authorization: `Bearer ${jwt}` } },
);

