import styled from 'react-emotion';
import colors from 'src/utils/colors';

export const Container = styled('div')`
  padding: 16px;
  width: calc(100% - 2rem - 2px);
  margin-bottom: 15px;

  background: white;
  border-radius: 10px;
  border: 1px solid ${colors.silver};

  display: flex;
  align-items: center;

  color: ${colors.tundora};

  & + div {
    margin-top: 8px;
  }
  
  select {
    flex: 1;
    background: transparent;
    border: none;
    font: inherit;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus, &:focus-visible {
      border-color: transparent;
    }

    &::placeholder {
      color: ${colors.gray};
    }

    option {
      color: ${colors.tundora};
    }
  }
`;
