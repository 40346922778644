import React from 'react';
import { combinedFilter, filterNonContactsPrescriptions } from '../utils';
import { StatePrescriptions, UserPrescription } from '../../../redux/lens-replacement/types';
import RxCardComponent from '../../../shared-components/rx-card-component';
import { RetailOrder } from '../../../redux/order/types';
import { LRData } from '../lr-stepper/types';
import { TabSubtitle, TabTitle } from './styles';
import { hasPrescriptionExpired } from '../../../utils/verifyPrescriptions';
import { getRxStepSubtitle } from './util';

type Props = {
  customerId: string;
  selectedForSwap: number | null;
  lrData: LRData;
  retailOrder: RetailOrder;
  prescriptions: StatePrescriptions;
  originalPrescription: UserPrescription;
  currentSelection: UserPrescription;
  onSwapClick: (item: UserPrescription) => void;
  onSelectForSwap: (prescriptionId: number) => void;
};

const PrescriptionsTab = ({
  prescriptions,
  selectedForSwap,
  customerId,
  originalPrescription,
  lrData,
  currentSelection,
  retailOrder,
  onSwapClick,
  onSelectForSwap,
}: Props) => {
  const { type: lensReplacementTypeFlow } = lrData;

  const isCurrentlySelected = (prescription: UserPrescription) => currentSelection?.prescription_id === prescription.prescription_id;

  const isSelectedForSwap = (prescriptionId: number) => selectedForSwap === prescriptionId;

  return (
    <>
      <TabTitle>Select a prescription</TabTitle>
      <TabSubtitle>
        {getRxStepSubtitle(currentSelection, lensReplacementTypeFlow)}
      </TabSubtitle>
      {prescriptions &&
        prescriptions[customerId]
          .filter(filterNonContactsPrescriptions)
          .filter((item) => combinedFilter(item, originalPrescription, lensReplacementTypeFlow))
          .map((item: UserPrescription) => (
            <RxCardComponent
              key={item.prescription_id}
              rxTitle={item.customer_supplied_name}
              isCurrentlySelected={isCurrentlySelected(item)}
              prescription={item}
              isPreviouslyUsed={item.prescription_id === retailOrder?.prescription_id}
              onSwapClick={() => onSwapClick(item)}
              isDisabled={hasPrescriptionExpired(item)}
              isSelectedForSwap={isSelectedForSwap(item.prescription_id)}
              onSelectForSwap={() => onSelectForSwap(item.prescription_id)}
            />
          ))
      }
    </>
  );
};

export default PrescriptionsTab;
