import React from 'react';

import { styles } from 'style';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import Nav from 'src/shared-components/screens/Nav';

interface ServiceGesturePresetReasonProps {
  href: string;
  preset: string;
  reasons: JSX.Element[];
  total: string;
}

const ServiceGesturePresetReason: React.StatelessComponent<ServiceGesturePresetReasonProps> = ({
  href,
  total,
  preset,
  reasons,
}) => (
  <div className="page">
    <div className="content">
      <Nav
        href={href}
        text={total}
        subtext=""
      />
      <EditableSelectionPill
        subtext="Select preset for customer courtesy"
        text={preset}
        href={href}
      />
      <p style={styles.paddedPara}>Select preset reason</p>
      {reasons}
    </div>
  </div>
);

export default ServiceGesturePresetReason;
