import React from 'react';
import ErrorScreen from 'src/shared-components/screens/ErrorScreen';

type Info = {
  componentStack: string
};

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
      nextURL: '',
    };
  }

  componentDidCatch(error: any, info: Info) {
    const { search } = window.location;
    const nextURL = `${decodeURIComponent(search.split('?next=')[1])}/`;
    this.setState({ error, info, nextURL });
  }

  render() {
    const { error, info, nextURL } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <ErrorScreen error={error} info={info} nextURL={nextURL} />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
