import * as React from 'react';

import { CSSStyle, Dispatch } from 'types';
import { styles } from 'style';

import { runReturn, ReturnRunnerSteps } from 'src/components/return/ReturnRunner';
import closeIcon from 'src/shared-components/icons/close.svg';
import questionMark from 'src/shared-components/icons/question-mark.svg';

const style: CSSStyle = {
  background: {
    alignItems: 'center',
    background: 'rgba(0, 36, 84, .8)',
    bottom: '0',
    display: 'flex',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: 10,
  },
  content: {
    background: 'white',
    borderRadius: '8px 8px 0 0',
    left: '0',
    margin: '15% auto',
    maxWidth: '430px',
    padding: '60px 65px 20px',
    position: 'fixed',
    right: '0',
    textAlign: 'center',
    top: '0',
  },
  close: {
    backgroundImage: `url(${closeIcon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    cursor: 'pointer',
    display: 'block',
    height: '15px',
    left: '20px',
    position: 'absolute',
    top: '30px',
    width: '15px',
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    overflowWrap: 'break-word',
  },
  buttonWrapper: {
    background: 'white',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    left: '0',
    position: 'absolute',
  },
  leftButton: {
    ...styles.button,
    ...{
      backgroundColor: '#0089BF',
      borderRadius: '0 0 0 8px',
      borderWidth: '0',
    },
  },
  rightButton: {
    ...styles.button,
    ...{
      borderRadius: '0 0 8px 0',
      borderWidth: '0',
    },
  },
};

const disabledButtons = (
  <div style={style.buttonWrapper}>
    <div style={{ ...style.leftButton, ...styles.disabledButton }}>Yes</div>
    <div style={{ ...style.rightButton, ...styles.disabledButton }}>No</div>
  </div>
);

const getExchangeButtons = (returnBody, customerId, jwt, dispatch, disabled): JSX.Element => {
  if (disabled) return disabledButtons;

  const receiveNowSteps: ReturnRunnerSteps = {
    authorizeReturn: true,
    receiveReturn: true,
    redirectToShop: true,
  };

  const receiveLaterSteps: ReturnRunnerSteps = {
    authorizeReturn: true,
    redirectToShop: true,
  };

  return (
    <div style={style.buttonWrapper}>
      <div onClick={() => runReturn(returnBody, null, customerId, jwt, dispatch, receiveNowSteps)} style={style.leftButton}>Yes</div>
      <div onClick={() => runReturn(returnBody, null, customerId, jwt, dispatch, receiveLaterSteps)} style={style.rightButton}>No</div>
    </div>
  );
};

const getReturnButtons = (returnBody, customerId, jwt, dispatch, disabled, closeClick) => {
  if (disabled) return disabledButtons;

  const receiveNowSteps: ReturnRunnerSteps = {
    authorizeReturn: true,
    receiveReturn: true,
    redirectToCreditMemo: true,
  };

  return (
    <div style={style.buttonWrapper}>
      <div onClick={() => runReturn(returnBody, null, customerId, jwt, dispatch, receiveNowSteps)} style={style.leftButton}>Yes</div>
      <div onClick={() => closeClick()} style={style.rightButton}>No</div>
    </div>
  );
};

const returnText = 'You must receive the item(s) before issuing a refund.';

interface ReturnModalProps {
  closeClick: Function;
  disabled: boolean;
  returnBody: string;
  jwt: string;
  dispatch: Dispatch;
  customerId: string;
  returnOrderType: string;
}

const ReturnModal: React.StatelessComponent<ReturnModalProps> = ({
  returnBody,
  closeClick,
  disabled,
  customerId,
  returnOrderType,
  jwt,
  dispatch,
}) => (
  <div style={style.background}>
    <div style={style.content}>
      <div style={style.close} onClick={() => closeClick()} />
      <img src={questionMark} />
      {returnOrderType === 'return' && <p style={style.header}>{returnText}</p>}
      <p style={style.header}>Are you receiving the item(s) now?</p>
      <div style={style.buttonWrapper}>
        {returnOrderType === 'exchange' && getExchangeButtons(returnBody, customerId, jwt, dispatch, disabled)}
        {returnOrderType === 'return' && getReturnButtons(returnBody, customerId, jwt, dispatch, disabled, closeClick)}
      </div>
    </div>
  </div>
);

export default ReturnModal;
