import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ReduxState, Order, ReturnReasonType, Dispatch, Params, RETURN_ORDER_TYPE_IDS, PimItem, OrderItem,
} from 'types';
import { actions } from 'src/redux/actions';
import {
  routeIdArray, apiStatus, apiResponse, getOrder, getDescriptiveName, selectPimItem, getPimItems,
} from 'selectors';
import { styles } from 'style';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import SelectionPill from 'src/shared-components/order-actions/SelectionPill';
import Nav from 'src/shared-components/screens/Nav';
import Loading from 'src/shared-components/screens/Loading';
import convertReasonTypes from 'src/utils/convertReasonTypes';
import { getReturnReasonTypesDataPath } from './constants';

const configureReturnReasonTypePill = (types: ReturnReasonType[], orderId: string, params: Params): JSX.Element | JSX.Element[] => {
  if (!types) {
    return <Loading />;
  }

  const itemIds = routeIdArray('items', params);
  return types.map((type) => {
    const href = `/order/${orderId}/return-items/${params['return-items']}/items/${itemIds}/reason-type/${type.id}`;
    return <SelectionPill key={type.id} href={href} text={type.name} />;
  });
};

interface ReturnReasonTypeProps {
  dispatch: Dispatch;
  jwt: string;
  order: Order;
  pimItems: PimItem[];
  params: Params;
  returnReasonTypesDataPath: string;
  returnReasonTypesData: ReturnReasonType[];
}

const ReturnReasonTypeSelected: React.StatelessComponent<ReturnReasonTypeProps> = ({
  jwt,
  dispatch,
  order,
  pimItems,
  params,
  returnReasonTypesDataPath,
  returnReasonTypesData,
}) => {
  const orderId = params.order;
  const itemIds = params.items;
  const returnOrderType = params['return-items'];

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);
  useEffect(actions.fetchPimItems(dispatch, jwt, order), [order]);

  useEffect(() => {
    if (jwt && !returnReasonTypesData) {
      dispatch(actions.fetchAPI(returnReasonTypesDataPath, jwt));
    }
  }, []);

  const items = order && order.items && order.items.filter((item) => itemIds.includes(item.id));
  const href = order && `/order/${order.id}/return-items/${params['return-items']}`;
  const returns = configureReturnReasonTypePill(returnReasonTypesData, orderId, params);
  const changeItemsHref = `/order/${orderId}/return-items/${returnOrderType}`;

  return (
    <div className="page" style={styles.page}>
      <div className="content">
        <Nav
          href={href}
          text="Return items"
          subtext=""
        />
        {!order ? <Loading /> : (
          <>
            {
              items.map((i) => (
                <EditableSelectionPill
                  key={i.id}
                  subtext="Item for return"
                  text={getDescriptiveName(selectPimItem(pimItems, i))}
                  href={changeItemsHref}
                />
              ))
            }
            <p style={styles.paddedPara}>Return Type</p>
            {returns}
          </>
        )}
      </div>
    </div>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);
  const pimItems = getPimItems(state, order);
  const returnReasonTypesDataPath = order ? getReturnReasonTypesDataPath(order.id) : '';
  const returnReasonTypesDataStatus = apiStatus(state, returnReasonTypesDataPath);
  const returnReasonData = apiResponse(returnReasonTypesDataStatus);
  const returnReasonTypesData = convertReasonTypes(returnReasonData);

  return ({
    jwt: state.auth.jwt,
    order,
    pimItems,
    returnReasonTypesDataPath,
    returnReasonTypesData,
  });
};

export default connect(select)(ReturnReasonTypeSelected);
