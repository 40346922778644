import React from 'react';

const useConst = <T>(value: T) => {
  const valueRef = React.useRef<T | null>(null);

  if (valueRef.current === null) {
    valueRef.current = value;
  }

  return valueRef.current;
};

export { useConst };
