import * as React from 'react';

import { CancelPayment, APIParsedSummary } from 'types';
import { formatPrice } from 'selectors';

import Modal from 'src/shared-components/screens/Modal';

export const formatPaymentType = (payment: CancelPayment): string => (
  Object.keys(payment).find((key) => payment[key] === true)
);

enum PaymentType {
  CASH = 'is_cash',
  CHECK = 'is_check',
  CREDIT_CARD = 'is_credit_card',
  GIFT_CARD = 'is_gift_card',
  CREDIT_MEMO = 'is_credit_memo',
}

export const formatHeader = (payments: CancelPayment[]): string | string[] => {
  // in the event that an order is canceled before the funds have been allocated
  // i.e., paid with card and the card hasn't been charged yet but cancel issued
  if (!payments.length) {
    return 'The customer\'\s order has been canceled and their payment will not be processed.';
  }

  return payments.map((payment) => {
    const { amount_refunded, last4, code } = payment;
    const paymentType = formatPaymentType(payment);
    const refundAmount = formatPrice(amount_refunded);

    switch (paymentType) {
      case PaymentType.CASH:
        return `Refund the customer ${refundAmount} in cash`;
      case PaymentType.CHECK:
        return `The customer will be refunded ${refundAmount} by check`;
      case PaymentType.CREDIT_CARD:
        return `${refundAmount} has been added back to card ${last4}`;
      case PaymentType.GIFT_CARD:
        return `${refundAmount} has been added back to gift card ${code}`;
      case PaymentType.CREDIT_MEMO:
        return `The customer will be refunded a ${refundAmount} credit memo`;
      default:
        return `The customer will be refunded ${refundAmount}`;
    }
  });
};

export const formatSubtext = (payments: CancelPayment[]): string | null => {
  const creditText = 'This could take a few days to show up on the customer\'\s statement';
  return payments.find((payment) => payment.is_credit_card) ? creditText : null;
};

interface CancelModalProps {
  customerId: number;
  orderId: string;
  summary: APIParsedSummary;
}

const CancelModal: React.StatelessComponent<CancelModalProps> = ({ customerId, orderId, summary }) => {
  const { success, body } = summary;
  if (!success) {
    return (
      <Modal
        showCloseButton={false}
        showCheckmark={false}
        confirmationHeader=""
        header={body}
        closeURL=""
        subtext=""
        buttonText="Got it"
        nextURL={`/customer/${customerId}/order/${orderId}`}
      />
    );
  }

  const headerPaymentText = formatHeader(body.payments);
  const subtext = formatSubtext(body.payments);

  return (
    <Modal
      showCloseButton={false}
      showCheckmark
      confirmationHeader=""
      header={headerPaymentText}
      closeURL=""
      subtext={subtext}
      buttonText="Got it"
      nextURL={`/customer/${customerId}/order/${orderId}`}
    />
  );
};

export default CancelModal;
