import { PDType } from 'types';

const hasUnit = (v: string) => v.toLowerCase().endsWith('mm');
const addUnit = (v: number | string | null): string | null => {
  if (v === null) {
    return v as null;
  }
  if (typeof v === 'string' && hasUnit(v)) {
    return v;
  }
  return `${v}mm`;
};

export const pdToString = (pd: PDType): string => {
  const { od, os, bi } = pd;
  if (!os && !od && !bi) return '';
  if (!os && !od) return addUnit(bi);
  return `${addUnit(od)}/${addUnit(os)}`;
};
