import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ISO8601DateTime,
  Order,
  OrderItem,
  PimItem,
  Dispatch,
  ReduxState,
  PaymentPreview,
  RxDetails,
  ItemDetails,
  AntiFatigueAddPower,
  Params,
  SalesOrderPrescription,
  RxDetailsData,
  InsuranceBreakdown,
} from 'types';
import { styles } from 'style';
import { selectIdFromRoute, selectPimItem, getAntiFatigueAddPowers, getAvailability, currentUserHasFeature } from 'selectors';
import { actions } from 'src/redux/actions';
import CustomerDetail from 'src/shared-components/screens/CustomerDetail';
import ServiceGestureLedger from 'src/components/sg/ServiceGestureLedger';
import OrderLedger from 'src/components/order/OrderLedger';
import Link from 'src/components/app/Link';
import Nav from 'src/shared-components/screens/Nav';
import PimProductCard from 'src/shared-components/order-actions/PimProductCard';
import RxSummary, { getRxName } from 'src/shared-components/screens/RxSummary';
import PaymentDetail from 'src/shared-components/screens/PaymentDetail';
import InsuranceDetail from 'src/shared-components/screens/insurance-detail/InsuranceDetail';
import { CategoryProduct } from 'src/types/lens-replacement';
import { formatParams } from '../app/routeUtils';
import {
  OrderId,
  RetailOrder,
  RetailOrderItem,
  RetailOrderItemAttributes,
  RetailOrderItemFrame,
} from '../../redux/order/types';
import { getOrderData, getInsuranceBreakdown } from '../../redux/order/selectors';
import { fetchRetailOrder } from '../../redux/order/thunks';
import { style } from './styles';

const getPaymentPreview = (orderData: RetailOrder | null): PaymentPreview[] => {
  if (!orderData) {
    return [];
  }

  return orderData?.payments as PaymentPreview[];
};

export const getRxDetails = (orderData: RetailOrder | null): RxDetails | null => {
  if (!orderData) {
    return null;
  }

  const {
    customer_name: customerName,
    prescription = {} as SalesOrderPrescription,
    prescription_id: prescriptionId,
    prescription_request_id: requestId,
    items,
  } = orderData;
  const itemAttributes = items[0]?.attributes;

  // eslint-disable-next-line no-underscore-dangle
  const customerSuppliedName = prescription?._customer_supplied_name;
  // eslint-disable-next-line no-underscore-dangle
  const hasPrism = prescription?._has_prism;
  const rxName = getRxName(itemAttributes, requestId, customerName, customerSuppliedName);
  let data: RxDetailsData = null;
  if (prescription) {
    data = {
      created: new Date(prescription.created * 1000).toISOString(),
      expiration: new Date(prescription.expiration_date * 1000).toISOString(),
      od: prescription.od,
      os: prescription.os,
      isMultiFocal: !!(prescription.od.add || prescription.os.add),
      forContacts: !!(
        prescription.od_contact_lens_product_identifier ||
        prescription.os_contact_lens_product_identifier
      ),
      odContactDiameter: prescription.od_contact_lens_diameter,
      odContactBaseCurve: prescription.od_contact_lens_base_curve,
      odContactColor: prescription.od_contact_lens_color,
      osContactDiameter: prescription.os_contact_lens_diameter,
      osContactBaseCurve: prescription.os_contact_lens_base_curve,
      osContactColor: prescription.os_contact_lens_color,
      pd: prescription.pd,
      hasPrism,
    };
  }
  return {
    data,
    name: rxName,
    prescriptionId,
    requestId,
  } as RxDetails;
};

const getItemDetails = (orderData: RetailOrder | null): ItemDetails[] => {
  if (!orderData?.items) {
    return [];
  }

  const { items } = orderData;

  return items.map((item: RetailOrderItem) => {
    const { id, attributes = {} } = item;
    const { frame = {} } = attributes as RetailOrderItemAttributes;
    const {
      oc_height: ocHeight,
      seg_height_od: segHeightOD,
      seg_height_os: segHeightOS,
      anti_fatigue_power: antiFatiguePower,
    } = frame as RetailOrderItemFrame;

    return {
      itemId: id,
      ocHeight,
      segHeightOD,
      segHeightOS,
      antiFatiguePower,
    } as ItemDetails;
  });
};

const canceledPill = (canceledDate: ISO8601DateTime): JSX.Element => (
  <div style={styles.orangePill}>
    <p style={orangePara}>
      Canceled on
      {' '}
      {canceledDate}
    </p>
  </div>
);

const orangePara = { ...styles.boldPara, ...style.orange };
const blueButton = { ...styles.button, ...style.margin };
const disabledButton = { ...styles.button, ...styles.disabledButton, ...style.margin };

interface OrderDetailProps {
  address: string;
  afAddPowers: AntiFatigueAddPower[];
  canCancel: boolean;
  cancelLink: string;
  canceledDate: string;
  canServiceGesture: boolean;
  customerId: string;
  dispatch: Dispatch;
  isCanceled: string;
  items: OrderItem[];
  jwt?: string;
  pimItems: PimItem[];
  order: Order;
  orderId: OrderId;
  orderPlaced: string;
  paymentPreview: PaymentPreview[];
  insuranceBreakdown: InsuranceBreakdown;
  retailOrder: RetailOrder;
  returnButton: JSX.Element;
  rxDetails: RxDetails;
  itemDetails: ItemDetails[];
  shipping: string;
  showShippingDetails: boolean;
  productCategories: CategoryProduct[];
}

const OrderDetail: React.FC<OrderDetailProps> = ({
  address,
  afAddPowers,
  canCancel,
  cancelLink,
  canceledDate,
  canServiceGesture,
  customerId,
  dispatch,
  isCanceled,
  items,
  jwt,
  pimItems,
  order,
  orderId,
  orderPlaced,
  paymentPreview,
  insuranceBreakdown,
  retailOrder,
  returnButton,
  rxDetails,
  itemDetails = [],
  shipping,
  showShippingDetails,
  productCategories,
}) => {
  useEffect(() => {
    if (!retailOrder) {
      dispatch(fetchRetailOrder(customerId, orderId));
    }

    if (afAddPowers.length === 0) {
      dispatch(actions.fetchAntiFatigueAddPowers(jwt));
    }
  }, []);

  return (
    <div className="page" style={styles.page}>
      <div className="content">
        <Nav
          href={`/customer/${order.customer_id}`}
          text={`Order ${order.id}`}
          subtext={`Ordered on ${orderPlaced}`}
        />
        {isCanceled && canceledPill(canceledDate)}
        <CustomerDetail order={order} />
        {items &&
          items.map((item) => (
            <PimProductCard
              total={item.total}
              pimItem={selectPimItem(pimItems, item)}
              availability={getAvailability(productCategories, item, order?.country)}
              itemDetails={itemDetails.find((details) => item.id === String(details.itemId))}
              afAddPowers={afAddPowers}
              key={item.id}
              rxName={rxDetails?.name}
            />
          ))}
        <RxSummary rxDetails={rxDetails} />
        {showShippingDetails && (
          <div style={style.pill}>
            <span>
              <p style={styles.microPara}>Shipping Details</p>
              <p style={style.boldPara}>
                {address}
                {' '}
                /
                {' '}
                {shipping}
              </p>
            </span>
          </div>
        )}
        {retailOrder?.has_insurance_payment && (
          <InsuranceDetail insurance={insuranceBreakdown}/>
        )}
        <PaymentDetail payments={paymentPreview} />
        {canServiceGesture && <ServiceGestureLedger order={order} />}
        <OrderLedger order={order} canServiceGesture={canServiceGesture} />
        <div style={style.fixed}>
          {canCancel && (
            <Link
              data-cy={`order-${order.id}-cancel`}
              href={cancelLink}
              style={!order.cancelable ? disabledButton : blueButton}
            >
              Cancel
            </Link>
          )}
          {returnButton}
        </div>
      </div>
    </div>
  );
};

const select = (state: ReduxState) => {
  const routeParams = formatParams(window.location.pathname) as Params;
  const orderId = selectIdFromRoute('order', routeParams);
  const retailOrder = getOrderData(state, orderId);
  const paymentPreview = getPaymentPreview(retailOrder);
  const insuranceBreakdown = getInsuranceBreakdown(retailOrder);
  const rxDetails = getRxDetails(retailOrder);
  const itemDetails = getItemDetails(retailOrder);
  const afAddPowers = getAntiFatigueAddPowers(state);

  const { jwt } = state.auth;
  return ({
    afAddPowers,
    jwt,
    paymentPreview,
    insuranceBreakdown,
    rxDetails,
    itemDetails,
    retailOrder,
    orderId,
    productCategories: state.lensReplacement.productCategories,
  });
};

export default connect(select)(OrderDetail);
