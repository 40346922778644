/* eslint-disable @typescript-eslint/naming-convention */
import { RxStepSubtitleCopy } from '../constants';
import { LR_PRODUCT_TYPE } from '../../../utils/constants';
import { UserPrescription } from '../../../redux/lens-replacement/types';

export const getRxStepSubtitle = (
  currentPrescription: UserPrescription,
  lensReplacementTypeFlow: string,
): string | null => {
  if (!currentPrescription) return RxStepSubtitleCopy.Archived;

  if (lensReplacementTypeFlow === LR_PRODUCT_TYPE.PAID) return RxStepSubtitleCopy.Default;

  const { for_readers, for_progressives } = currentPrescription;

  if (for_progressives) return RxStepSubtitleCopy.Progressives;

  return for_readers ? RxStepSubtitleCopy.Readers : RxStepSubtitleCopy.SingleVision;
};
