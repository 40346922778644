import React from 'react';

import { SalesOrder, SalesOrderDetail, CSSStyle } from 'types';

import Link from 'src/components/app/Link';
import { formatPrice } from 'selectors';
import dropdownArrow from 'src/shared-components/icons/dropdown-arrow.svg';
import { getStatusFromSalesOrder } from './util';

const style: CSSStyle = {
  pill: {
    background: 'white',
    borderRadius: '8px',
    border: '1px solid #E4E6E8',
    color: '#676F78',
    display: 'grid',
    fontSize: '18px',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(2, 1fr) 100px 25px 50px',
    marginBottom: '10px',
    padding: '35px 15px',
  },
  leftPara: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    margin: '0',
    marginLeft: '20px',
    textAlign: 'left',
    width: '100%',
  },
  middlePara: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  leftText: {
    textAlign: 'left',
  },
  lastPara: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    margin: '0',
    paddingRight: '70px',
    width: '100%',
  },
  leftPad: {
    paddingLeft: '20px',
    margin: '0',
  },
  block: {
    display: 'block',
    height: '100%',
    margin: '0',
    textAlign: 'left',
    width: '100%',
  },
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
    gridColumnStart: '5',
    paddingLeft: '20px',
  },
  arrow: {
    alignItems: 'center',
    background: `url(${dropdownArrow}) no-repeat`,
    display: 'flex',
    height: '14px',
    transform: 'rotate(-90deg)',
    width: '15px',
  },
};

const itemNames = (items: SalesOrderDetail[]): JSX.Element[] => {
  const flexPara = items.length > 1 ? { ...style.leftPad, ...style.block } : style.leftPad;
  return (
    items.map((item) => <p style={flexPara} key={item.id}>{item.display_name}</p>)
  );
};

const sumItemsTotal = (salesOrder: SalesOrder): string => {
  const itemsTotal = salesOrder.items.reduce((sum, item) => sum + item.item_total, 0);
  return formatPrice(itemsTotal);
};

interface OrderPillProps {
  customerId: string;
  salesOrder: SalesOrder;
}

const OrderPill: React.StatelessComponent<OrderPillProps> = ({ customerId, salesOrder }) => {
  const status = getStatusFromSalesOrder(salesOrder);
  const href = `/customer/${customerId}/order/${salesOrder.id}`;

  return (
    <Link data-cy={`order-${salesOrder.id}-details`} href={href} style={style.pill}>
      <p data-cy={`order-${salesOrder.id}`} style={style.leftPara}>
        {salesOrder.id}
        {' '}
        (
        {status}
        )
      </p>
      <div style={style.middlePara}>{itemNames(salesOrder.items)}</div>
      <p style={style.lastPara}>{salesOrder.order_total !== 0 ? formatPrice(salesOrder.order_total) : sumItemsTotal(salesOrder)}</p>
      <div style={style.flexContainer}>
        <span style={style.arrow} />
      </div>
    </Link>
  );
};

export default OrderPill;
