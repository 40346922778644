import { CSSStyle } from 'types';

export const style: CSSStyle = {
  boldPara: {
    color: '#525E69',
    fontSize: '16px',
    fontWeight: 600,
    margin: '0',
  },
  pill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    display: 'flex',
    margin: '0 auto 15px',
    padding: '18px 30px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  flexPara: {
    display: 'flex',
    alignSelf: 'center',
    margin: '0 0 0 auto',
  },
};
