import { css } from 'react-emotion';

import colors from '../../utils/colors';

// eslint-disable-next-line import/prefer-default-export
export const textfield = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    margin-bottom: 16px;

    & input {
      appearance: none;
      font-size: 16px;
      border: 0;
    }

    & span {
      font-size: 16px;
      font-weight: 400;
      color: ${colors.charcoal};
    }

    & div {
      display: flex;
      flex-direction: column;
      appearance: none;
      background-color: white;
      margin: 0;
      width: 100%;
      min-height:100px;
      border: 1px solid ${colors.silver};
      border-radius: 8px;
      padding: 8px 8px;
      box-sizing: border-box;
      margin-top: 2px;
      font-size: 12px;
      font-weight: 800;
      color: ${colors.grayChateau};
    }

    & input:focus {
      outline: 0;
      border-color: ${colors.darkBlue};
    }

    & :disabled {
      cursor: not-allowed;
      color: ${colors.grayChateau};
      background-color: ${colors.grayChateau};
      border-color: currentColor;
    }
`;

export const textfieldContainer = css`
  /*   border: 2px solid ${colors.silver};
    border-radius: 5px;
    width: 100%;
 */
`;
