
import colors from 'src/utils/colors';
import { CSSStyle } from 'types';

export const lrStyles: CSSStyle = {
  container: {
    border: `1px solid ${colors.silver}`,
    borderRadius: '5px',
    lineHeight: 2,
    padding: '30px 20px',
    WebkitOverflowScrolling: 'touch',
  },
  para: {
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  boldPara: {
    color: `${colors.charcoal}`,
    fontSize: '17px',
    fontWeight: 600,
    margin: '0',
  },
};
