import React from 'react';

import { formatPrice } from 'selectors';
import { ServiceGesturePayment, APIParsedSummary } from 'types';

import Modal from 'src/shared-components/screens/Modal';

const formatHeader = (payments: ServiceGesturePayment[]): string[] => payments.map((payment) => {
  const { amount_refunded } = payment;
  return `Amount credited: ${formatPrice(amount_refunded)}`;
});

const formatSubtext = (payments: ServiceGesturePayment[]): string | null => {
  const creditText = 'It may take up to 48 hours to reflect this update on the customer\'\s bank statement';
  return payments.find((payment) => payment.is_credit_card) ? creditText : null;
};

interface ServiceGestureModalProps {
  customerId: number;
  orderId: string;
  summary: APIParsedSummary;
}

const ServiceGestureModal: React.StatelessComponent<ServiceGestureModalProps> = ({ customerId, orderId, summary }) => {
  const { success, body } = summary;
  const nextURL = `/customer/${customerId}/order/${orderId}`;

  if (!success) {
    return (
      <Modal
        showCloseButton={false}
        showCheckmark={false}
        confirmationHeader=""
        header={body}
        closeURL=""
        subtext=""
        buttonText="Got it"
        nextURL={nextURL}
      />
    );
  }

  const headerConfirmation = 'Credit complete!';
  const headerPaymentText = formatHeader(body.payments);
  const subtext = formatSubtext(body.payments);

  return (
    <Modal
      showCloseButton={false}
      showCheckmark
      confirmationHeader={headerConfirmation}
      header={headerPaymentText}
      closeURL=""
      subtext={subtext}
      buttonText="Done"
      nextURL={nextURL}
    />
  );
};

export default ServiceGestureModal;
