/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { StyledButton } from './styles';
import { lrStyles } from '../styles';

type LargeButtonProps = React.ComponentProps<typeof StyledButton>;

const LargeButton: React.FC<LargeButtonProps> = ({
  style = {},
  ...rest
}) => (
  <div style={{ ...lrStyles.container, border: '0px solid', padding: 0, ...style }}>
    <StyledButton {...rest as any} />
  </div>
);

export default LargeButton;
