import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from 'src/redux/actions';
import {
  MeData, ReduxState, CSSStyle, Dispatch, CreditMemo, RefundPayment, Payment, Station, Till, AllocatedRefundPayment,
} from 'types';
import { styles } from 'style';
import { apiStatus, apiResponse, apiLoading } from 'selectors';
import { apiFactory } from 'src/redux/actions-wip';
import { Endpoint } from 'types-wip';

const mePath = '/api/v1/user/me';
const stationsPath = '/api/v1/stations';
const getTillsPath = (stationId: number): string => `/api/v1/stations/${stationId}/tills`;
const getRefundPaymentsPath = (cmId: string): string => `/api/v1/credit-memo/${cmId}/refund-payments`;
const getRefundPath = (cmId: string): string => `/api/v1/credit-memo/${cmId}/refund`;

const needsTills = (refundPayments: RefundPayment[]): boolean => refundPayments.some((p) => p.sales_order_payment.is_cash);

const selectStationId = (stations: Station[], facilityId: number): number => {
  const atFacility = stations.filter((s) => s.facility_id === facilityId);
  if (atFacility.length !== 1) {
    throw new Error('Unable to load station/till information');
  }
  return atFacility[0].station_id;
};

const fmtSOP = (sop: Payment): string => {
  if (sop.is_cash) {
    return 'Cash';
  }
  if (sop.is_check) {
    return 'Check';
  }
  if (sop.is_credit_memo) {
    // APS: Why does this ever happen? The helios case is meant to walk
    // down the CM DAG and find the original payments, not credit memos in
    // between.
    return 'Credit Memo';
  }
  if (sop.is_gift_card) {
    return `Gift Card: ${sop.code}`;
  }
  if (sop.is_credit_card) {
    return `Credit card ending in ${sop.last4}`;
  }
  return `Unknown payment of type ${sop.payment_type}`;
};

const style: CSSStyle = {
  pill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    textAlign: 'left',
    padding: '24px 30px',
    margin: '0 auto 15px',
    background: 'white',
  },
  subHeader: {
    fontSize: '22px',
    fontWeight: 600,
    textAlign: 'center',
    margin: '0 0 16px',
  },
  formRow: {
    width: '100%',
    border: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  invalid: {
    color: '#D55C12',
  },
  formLabel: {
    fontWeight: 600,
    fontSize: '18px',
    flex: '1 1 auto',
    display: 'block',
  },
  formInput: {
    fontSize: '18px',
    display: 'block',
    textAlign: 'right',
    width: '100px',
    padding: '4px',
    margin: '4px',
  },
  wideButton: {
    ...styles.button,
    margin: '0px 0px 15px',
  },
};

const humanMoney = (amount: number): string => (amount / 100).toFixed(2);
const toCents = (amount: string): number => {
  const dotPos = amount.indexOf('.');
  if (dotPos === -1) {
    if (amount === '') return 0;
    return parseInt(amount) * 100;
  }
  if (dotPos === amount.length - 3) {
    return parseInt(amount.replace('.', ''));
  }
  return null;
};

type RefundMetaData = {
  facility_id: number;
  till_id?: number;
  station_id?: number;
};

const refund = async (
  payments: AllocatedRefundPayment[],
  refundMetaData: RefundMetaData,
  creditMemo: CreditMemo,
  jwt: string,
  dispatch: Dispatch,
) => {
  const postParams = {
    body: JSON.stringify({ payments, ...refundMetaData }),
    method: 'POST',
  };

  await dispatch(actions.fetchAPI(getRefundPath(creditMemo.id), jwt, postParams));
  dispatch(apiFactory(Endpoint.CreditMemo, jwt, creditMemo.id));
};

interface RefundFormProps {
  refundPayments: RefundPayment[];
  dispatch: Dispatch;
  meData: MeData;
  jwt: string;
  stations: Station[];
  till: Till;
  tillsPath: string;
  creditMemo: CreditMemo;
  refundRequested: boolean;
  refundError: boolean;
}

const RefundForm: React.FC<RefundFormProps> = ({
  refundPayments,
  dispatch,
  meData,
  jwt,
  stations,
  till,
  tillsPath,
  creditMemo,
  refundRequested,
  refundError,
}) => {
  useEffect(() => {
    if (jwt && !meData) {
      dispatch(actions.fetchAPI(mePath, jwt));
    }

    if (jwt && !refundPayments) {
      dispatch(actions.fetchAPI(getRefundPaymentsPath(creditMemo.id), jwt));
    }
  }, []);

  useEffect(() => {
    if (refundPayments && needsTills(refundPayments) && !stations) {
      dispatch(actions.fetchAPI(stationsPath, jwt));
    }
  }, [refundPayments]);

  useEffect(() => {
    if (stations && needsTills(refundPayments) && !till) {
      dispatch(actions.fetchAPI(tillsPath, jwt));
    }
  }, [stations]);

  const [paymentForm, setPaymentForm] = useState([]);

  if (refundError) {
    return (
      <div>
        <h4 style={style.pill}>There was an error processing the refund.</h4>
        <div style={styles.button} onClick={() => dispatch(actions.removeAPIState(getRefundPath(creditMemo.id)))}>Try again</div>
      </div>
    );
  }

  if (!refundPayments) {
    return <h4 style={style.pill}>Loading payments</h4>;
  }

  if (needsTills(refundPayments) && !till) {
    return <h4 style={style.pill}>Loading tills</h4>;
  }

  const refundMetaData: RefundMetaData = needsTills(refundPayments) ? {
    till_id: till.till_id,
    station_id: till.station_id,
    facility_id: meData.facility.id,
  } : {
    facility_id: meData.facility.id,
  };

  if (refundPayments.length && !paymentForm.length) {
    setPaymentForm(refundPayments.map((p) => ({
      allocatedPayment: {
        id: parseInt(p.payment.id),
        amount: p.amount,
      },
      maxAmount: p.payment.amount,
      readableAmount: humanMoney(p.amount),
      salesOrderPayment: p.sales_order_payment,
    })));
  }

  const handleChange = (value, p) => {
    p.allocatedPayment.amount = toCents(value);
    p.readableAmount = value;
    setPaymentForm([...paymentForm]);
  };

  const amountInvalid = (p) => (p.allocatedPayment.amount > p.maxAmount) || (p.allocatedPayment.amount) < 0;
  const allocatedPayments = paymentForm.map((p) => p.allocatedPayment);
  const allocatedAmount = allocatedPayments.map((p) => p.amount).reduce((a, b) => a + b, 0);
  const refundAmountCorrect = allocatedAmount === creditMemo.amount;
  const isValid = !refundRequested && refundAmountCorrect && !paymentForm.some((p) => amountInvalid(p));

  const buttonStyle = isValid ? style.wideButton : { ...style.wideButton, ...styles.disabledButton };
  const formInvalidRow = { ...style.formRow, ...style.invalid };

  const submit = () => isValid && refund(allocatedPayments, refundMetaData, creditMemo, jwt, dispatch);

  return (
    <div>
      <div style={style.subHeader}>
        Remaining total: $
        {humanMoney(creditMemo.amount - allocatedAmount)}
      </div>
      <div style={style.pill}>
        {paymentForm.map((p) => (
          <div style={amountInvalid(p) ? formInvalidRow : style.formRow} key={p.allocatedPayment.id}>
            <span style={style.formLabel}>
              {fmtSOP(p.salesOrderPayment)}
              {' '}
              (Up to: $
              {humanMoney(p.maxAmount)}
              )
            </span>
            <input style={style.formInput} type="tel" defaultValue={p.readableAmount} onChange={(e) => handleChange(e.target.value, p)} />
          </div>
        ))}
      </div>
      <div style={buttonStyle} onClick={submit}>Refund</div>
    </div>
  );
};

const select = (state: ReduxState, { creditMemo }: { creditMemo: CreditMemo }) => {
  const { id } = creditMemo;

  const meDataStatus = apiStatus<MeData>(state, mePath);
  const meData = apiResponse(meDataStatus);

  const refundPaymentsStatus = apiStatus<RefundPayment[]>(state, getRefundPaymentsPath(id));
  const refundPayments = apiResponse(refundPaymentsStatus);

  const refundStatus = apiStatus(state, getRefundPath(creditMemo.id));
  const refundRequested = apiLoading(refundStatus) || apiResponse(refundStatus);
  const refundError = refundStatus?.error;

  const stationsStatus = refundPayments
    && needsTills(refundPayments)
    && apiStatus<Station[]>(state, stationsPath);
  const stations = stationsStatus && apiResponse(stationsStatus);

  const tillsPath = stations && getTillsPath(selectStationId(stations, meData.facility.id));
  const tillsStatus = refundPayments
    && needsTills(refundPayments)
    && stations && apiStatus<Till[]>(state, tillsPath);
  const till = tillsStatus && apiResponse(tillsStatus) && apiResponse(tillsStatus)[0];

  return {
    meData,
    creditMemo,
    refundPayments,
    stations,
    till,
    tillsPath,
    refundRequested,
    refundError,
    jwt: state.auth.jwt,
  };
};

export default connect(select)(RefundForm);
