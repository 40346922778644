import React from 'react';
import { connect } from 'react-redux';

import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { useConst } from 'src/hooks/useConst';
import { handleLREstimate } from 'src/redux/lens-replacement/thunks';

import { clamp } from 'src/utils/numbers';
import { MeFacility, Order } from 'types';
import { UserPrescription } from 'src/redux/lens-replacement/types';
import { LRData, LRStepperProviderComponentProps, UpdateDataFn } from './types';
import { OrderWithFrameAttributes } from '../../../types/lens-replacement';

import { LRStepperContextProvider, useStepperContext } from './context';


export const useLRStepper = (stepName: string, expectError = false) => {
  const context = useStepperContext('LRStepper', !expectError);
  return context;
};

const StepperProviderComponent: React.FC<LRStepperProviderComponentProps> = ({
  children,
  dispatch,
  header,
}) => {
  const stepsCount = useConst(React.Children.count(children));
  const data = React.useRef<LRData>({
    isConsentGiven: false,
    notes: '',
    returnReasonId: {},
    selectedItems: [],
    selectedPrescriptions: null,
    type: null,
    offeredException: false,
    readersStrength: [],
  });

  const [stepIdx, setStepIdx] = React.useState(0);

  const currentChildren = React.useMemo(
    () => React.Children.toArray(children)[stepIdx],
    [children, stepIdx],
  );

  const clampSteps = useCallbackRef((value: number) => (
    clamp(value, 0, stepsCount)
  ));

  const updateData = useCallbackRef<UpdateDataFn<LRData>>(newData => {
    data.current = {
      ...data.current,
      ...newData,
    };
  });

  const nextStep = useCallbackRef(() => {
    setStepIdx(oldStep => clampSteps(oldStep + 1));
  });

  const previousStep = useCallbackRef(() => {
    setStepIdx(oldStep => clampSteps(oldStep - 1));
  });

  const finishStepper = useCallbackRef((
    jwt: string,
    order: Order,
    orderWithFrameAttributes: OrderWithFrameAttributes,
    facility: MeFacility,
    selectedPrescriptions : UserPrescription[],
  ) => {
    updateData({ selectedPrescriptions });
    dispatch(
      handleLREstimate(jwt, order, data.current, orderWithFrameAttributes, facility),
    );
  });

  if (!stepsCount) throw new Error('StepperProvider expects at least one children, received 0');
  
  return (
    <LRStepperContextProvider
      data={data.current}
      updateData={updateData}
      finishStepper={finishStepper}
      nextStep={nextStep}
      previousStep={previousStep}
      stepIdx={stepIdx}
    >
      {header}
      {currentChildren}
    </LRStepperContextProvider>
  );
};
StepperProviderComponent.displayName = 'StepperProvider';

const LRStepperProvider = connect()(StepperProviderComponent);

export { LRStepperProvider };
