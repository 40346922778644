import React from 'react';
import {
  Container,
  Section,
  HeaderContainer,
  Title,
  Description,
  ButtonWrapper,
  Button,
  DisableButton,
  TagButton,
} from './styles';
import { useLRStepper } from '../lr-stepper';


type Props = {
  title: string;
  description: string;
  tag: React.ReactNode;
  status: string;
  productType: string;
  onClick: () => void;
  hasOverridePermission: boolean;
};

const GuaranteeCard: React.FC<Props> = ({ title, description, tag, status, productType, onClick, hasOverridePermission }) => {
  const isEligible = status === 'Eligible';
  const { updateData } = useLRStepper('OfferException');

  const handleButtonClick = () => {
    if (isEligible) {
      updateData({ offeredException: false });
    }

    if (!isEligible && hasOverridePermission) {
      updateData({ offeredException: true });
    }
    onClick();
  };

  return (
    <Container>
      <Section>
        <HeaderContainer>
          <Title>{title}</Title>
          {tag}
          {!isEligible && hasOverridePermission && <TagButton onClick={handleButtonClick}> Offer Exception </TagButton>}
        </HeaderContainer>
        <Description>{description}</Description>
        <ButtonWrapper>
          {isEligible ? (
            <Button onClick={handleButtonClick}>Continue with {productType === 'free' ? 'scratch guarantee' : `${productType} replacement`}</Button>
          ) : (
            <DisableButton disabled>
              Item is not eligible for {productType} replacement
            </DisableButton>
          )}
        </ButtonWrapper>
      </Section>
    </Container>
  );
};

export default GuaranteeCard;
