import React from 'react';

import { CSSStyle, PaymentPreview } from 'types';
import { styles } from 'style';
import { formatPrice } from 'selectors';

enum PaymentType {
  CASH = 'cash',
  CHECK = 'check',
  CREDIT_CARD = 'credit_card',
  GIFT_CARD = 'gift_card',
  CREDIT_MEMO = 'credit_memo',
}

export const configurePaymentPreviewHeader = (payment: PaymentPreview): string => {
  const {
    type, amount_cents, cc_type, cc_last_four, code,
  } = payment;
  const paymentAmount = formatPrice(amount_cents);

  switch (type) {
    case PaymentType.CASH:
      return `Cash - ${paymentAmount}`;
    case PaymentType.CHECK:
      return `Check — ${paymentAmount}`;
    case PaymentType.CREDIT_CARD:
      return `${cc_type} ending in ${cc_last_four} — ${paymentAmount}`;
    case PaymentType.GIFT_CARD:
      return `Gift card ${code} — ${paymentAmount}`;
    case PaymentType.CREDIT_MEMO:
      return `Credit memo  — ${paymentAmount}`;
    default:
      return 'Loading...';
  }
};

const style: CSSStyle = {
  boldPara: {
    color: '#525E69',
    fontSize: '16px',
    fontWeight: 600,
    margin: '0',
  },
  pill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    display: 'flex',
    margin: '0 auto 15px',
    padding: '18px 30px',
    textAlign: 'left',
    textDecoration: 'none',
  },
};

interface PaymentDetailProps {
  payments: PaymentPreview[];
}

const PaymentDetail: React.StatelessComponent<PaymentDetailProps> = ({ payments }) => {
  if (!payments) {
    return (
      <div style={style.pill}>
        <span>
          <p style={styles.microPara}>Payment Details</p>
          <p style={style.boldPara}>Loading...</p>
        </span>
      </div>
    );
  }

  return (
    <>
      {payments.map((payment) => (
        <div style={style.pill} key={payment.amount_cents}>
          <span>
            <p style={styles.microPara}>Payment Details</p>
            <p style={style.boldPara}>{configurePaymentPreviewHeader(payment)}</p>
          </span>
        </div>
      ),
      )}
    </>
  );
};

export default PaymentDetail;
