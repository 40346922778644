import styled from 'react-emotion';
import colors from 'src/utils/colors';

export const TabContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 1.5rem;
    margin: 3rem 0;
    color: ${colors.stone};
  }

  & > div {
    width: 676px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.75rem;
  }
`;
