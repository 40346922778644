import styled from 'react-emotion';

export const StatusIconContainer = styled('span')`
  span {
    border: 0;
    display: inline-block;
    height: 8px;
    margin: 0;
    width: 8px;
    margin-left: 10px;
  }

  .green {
    background: #00BF2D;
    border-color: #00BF2D;
  }

  .yellow {
    background: #F2B600;
    border-color: #F2B600;
  }

  .red {
    background: #D6003C;
    border-color: #D6003C;
  }

  .circle {
    border-radius: 50%;
  }

  .triangle {
    background: none;
    border-left-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-top-color: transparent;
    border-width: 0 5px 10px 5px;
    height: 0;
    width: 0;
  }
`;

export const StatusFrameContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const StatusDescription = styled('div')`
  margin-left: 10px;
`;
