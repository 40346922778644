import { CountryCode } from 'src/utils/mock-data';
import { titleCase } from 'src/utils/titleCase';
import {
  AntiFatigueAddPower,
  AntiFatigueAddPowerOptions,
  APIResult,
  ConfigProduct,
  CountryAvailability,
  ISO8601DateTime,
  LensFeatureMap,
  Order,
  OrderItem,
  Params,
  PimItem,
  ReduxState,
} from 'types';
import { Endpoint, UrlChecker } from 'types-wip';
import { CategoryProduct } from 'src/types/lens-replacement';
import { RetailOrder } from 'src/redux/order/types';
import { ANTI_FATIGUE_ADD_POWERS_ENDPOINT } from './src/redux/actions';
import { LensCategoryOptions, LensTypes } from './src/utils/constants';
import { getStatusFromSalesOrder } from './src/components/order/util';


// See ADR #3 for context regarding formatPrice
export const formatPrice = (value: number): string => {
  const price = Math.abs(value / 100);
  return `${value < 0 ? '-' : ''}$${price.toFixed(2)}`;
};

/*
For the getYYYYMMDD, formatDate, and formatDateFromYYYYMMDD methods, consider this
information about parsing date strings:

  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#Date_Time_String_Format
  When the time zone offset is absent, date-only forms are interpreted
  as a UTC time and date-time forms are interpreted as local time.

Our dates are stored in the database as UTC, but without any timezone denotation.
*/

/*
This method returns a datestamp in YYYY-MM-DD format based on the local time of the browser.
So, if a record in the database has a timestamp of 8/20/2019 02:00 (UTC, no timezone marker),
then this method would return 2019-08-19 on a browser in the US ET timezone.
*/
export const getYYYYMMDD = (dateTime: ISO8601DateTime): string => {
  if (!dateTime) return null;
  const date = new Date(`${dateTime}Z`);
  const yyyy = date.toLocaleDateString('en-us', { year: 'numeric' });
  const mm = date.toLocaleDateString('en-us', { month: '2-digit' });
  const dd = date.toLocaleDateString('en-us', { day: '2-digit' });
  return `${yyyy}-${mm}-${dd}`;
};

/*
This method formats a readable date string based on the local time of the browser.
So, if a record is in the database with a timestamp of 8/20/2019 02:00 (UTC, no timezone marker),
then this method would return 'August 19, 2019' on a browser in the US ET timezone.
*/
export const formatDate = (dateTime: ISO8601DateTime): string => {
  if (!dateTime) return null;
  const date = new Date(`${dateTime}Z`);
  const month = date.toLocaleString('en-us', { month: 'short' });
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

/*
This method is maps a datestamp in YYYY-MM-DD format to a readable string.
For example, an input of 2019-08-19 should return 'August 19, 2019'
*/
export const formatDateFromYYYYMMDD = (dateTime: ISO8601DateTime): string => {
  if (!dateTime) return null;

  /*
  Because we use toLocaleString (which is timezone-aware) in the next step,
  it's important that our date object be interpreted as local time. From above:
  > When the time zone offset is absent, ... date-time forms are interpreted as local time.
  Therefore, we need to add a time portion to our YYYY-MM-DD.
  */
  if (dateTime.length === 10) {
    dateTime += 'T00:00:00';
  } else {
    return null;
  }

  const date = new Date(dateTime);
  const month = date.toLocaleString('en-us', { month: 'short' });
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

export const currentUserHasFeature = (flagName: string, state: ReduxState) => {
  const me = '/api/v1/user/me';
  return (
    state.api[me] && state.api[me].body && state.api[me].body.features.includes(flagName)
  );
};

export const currentUserHasPermission = (permission: string, state: ReduxState) => {
  const me = '/api/v1/user/me';
  return (
    state.api[me] && state.api[me].body && state.api[me].body.permissions.includes(permission)
  );
};

export const capitalizeString = (str: string): string => {
  const parts = str ? str.split(' ') : [];
  const capitalizedStr = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  return capitalizedStr;
};

// takes in key argument to find in the route params object and return correlating value id
export const selectIdFromRoute = (key: string, routeParams: Params): string => routeParams[key];

// returns an array of string ids from route
export const routeIdArray = (key: string, routeParams: Params): string[] => routeParams[key].split(',');

export const getOrderPath = (id: string): string => `/v1/sales-order/${id}`;

/*
Given an an Order, craft the path for an api call to fetch the PimItems for the order.
*/
export const getPimItemsPath = (order: Order): string | null => {
  if (!order || !order.items) return null;
  const pcIds = order.items && order.items.map((item) => item.pc_product_id);
  return `v1/checkout-product/${pcIds}`;
};

export const getPimItemsDetailedProductPath = (orderItems: OrderItem[] | null | undefined) => {
  if (!orderItems || orderItems.length === 0) return null;
  const pcIds = orderItems.map((item) => item.pc_product_id).join(',');
  return `v1/detailed-product/${pcIds}?format=nest`;
};

export const getPimItemsConfigProductsPath = (orderItems: OrderItem[] | null | undefined) => {
  if (!orderItems || orderItems.length === 0) return null;
  const pcIds = orderItems.map((item) => item.pc_product_id).join(',');
  return `v1/product/${pcIds}/config-products`;
};

/*
Given an OrderItem and a set of PimItems, return the PimItem that corresponds
to the OrderItem. Returns null if the PimItem is not in the set of PimItems.
*/
export const selectPimItem = (pimItems: PimItem[], orderItem: OrderItem): PimItem | null => {
  if (!pimItems || !orderItem) return null;
  return pimItems.find((p) => String(p.pc_product_id) === orderItem.pc_product_id) || null;
};

export const isShipping = (item: PimItem): boolean => item.primary_product_category_id === 'SHIPPING';

export const checkIfIsReaderOrder = (retailOrder: RetailOrder | null) => !!(
  retailOrder &&
  retailOrder.items.length &&
  retailOrder.items[0].attributes &&
  retailOrder.items[0].attributes.frame &&
  retailOrder.items[0].attributes.frame.readers_strength);

const getParametersFromLensFeatures = (features: LensFeatureMap) => {
  const parameters = {
    // Progressives
    isProgressives: 'PROGRESSIVES' in features,
    progressivesType: '',
    progressivesName: '',

    // Refractive Index
    isRefractiveIndex: 'REFRACTIVE_INDEX' in features,
    refractiveIndexType: '',
    refractiveIndexName: '',

    // Main Parameters
    color: features.BRANDED_LENS_COLOR || features.LENS_COLOR,

    // Other Parameters
    hasArCoating: 'AR_COATING' in features,
    hasBlueLight: 'BLUE_LIGHT' in features,
    hasSunBackAR: 'AR_COATING' in features && features.AR_COATING === 'SUN_BACK_AR',
    isAntiGatigue: 'ANTI_FATIGUE' in features,
    isPhotochromic: 'PHOTOCHROMICS' in features,
  };

  switch (features.PROGRESSIVES) {
    case undefined:
      break;
    default:
      parameters.progressivesType = features.PROGRESSIVES;
      parameters.progressivesName = titleCase(features.PROGRESSIVES);
      break;
  }

  switch (features.REFRACTIVE_INDEX) {
    case undefined:
      break;
    case 'regular':
      parameters.refractiveIndexType = features.REFRACTIVE_INDEX;
      parameters.refractiveIndexName = titleCase('polycarbonate');
      break;
    default:
      parameters.refractiveIndexType = features.REFRACTIVE_INDEX;
      parameters.refractiveIndexName = titleCase(features.REFRACTIVE_INDEX.concat('_index'));
      break;
  }

  return parameters;
};

export const getDescriptiveName = (item: PimItem): string => {
  if (!item) return null;
  if (item.color) return `${item.product_name} in ${capitalizeString(item.color)}`;
  return item.product_name;
};

export const getRxCategory = (pimItem: PimItem) => {
  const { lens_feature_map: lensFeaturesMap } = pimItem;

  if (!lensFeaturesMap) return null;

  const { isProgressives, isRefractiveIndex } = getParametersFromLensFeatures(lensFeaturesMap);

  if (isProgressives) return 'Multifocal';
  if (isRefractiveIndex) return 'Single Vision';

  return 'Non-Rx';
};

export const getLensTypeId = (pimItem: PimItem, lensProductCategory: ConfigProduct) => {
  const { lens_feature_map: lensFeatureMap } = pimItem;

  if (!lensFeatureMap) return null;

  const { CR_39: cr39, REFRACTIVE_INDEX: refractiveIndex } = lensFeatureMap;

  if (cr39) return LensTypes.Cr39;

  if (!refractiveIndex) return null;

  if (refractiveIndex === 'regular') return LensTypes.Polycarbonate;
  if (refractiveIndex === 'ultra_high') return LensTypes.UltraHighIndex;

  const isEyeglasses = lensProductCategory.category_ids.includes(LensCategoryOptions.OPTICAL);
  if (refractiveIndex === 'high') return isEyeglasses ? LensTypes.HighIndex : LensTypes.SunHighIndex;

  return null;
};

export const getAntiFatiguePowerId = (
  antiFatiguePowerValue: string,
  afOptions: AntiFatigueAddPower[],
): number => afOptions.find((option) => antiFatiguePowerValue === option.name)?.id;

export const getLensType = (pimItem: PimItem) => {
  const { lens_feature_map: lensFeatureMap } = pimItem;

  if (!lensFeatureMap) return '';

  const { isRefractiveIndex, refractiveIndexName } = getParametersFromLensFeatures(lensFeatureMap);

  return isRefractiveIndex ? refractiveIndexName : 'Polycarbonate';
};

export const getLensModification = (pimItem: PimItem, antiFatiguePower?: string) => {
  const { lens_feature_map: lensFeatureMap } = pimItem;

  if (!lensFeatureMap) return '';

  const {
    color,
    hasBlueLight,
    isAntiGatigue,
    isPhotochromic,
    progressivesName,
    progressivesType,
  } = getParametersFromLensFeatures(lensFeatureMap);

  const isPrecision = progressivesType.includes('precision');
  const hasBlueLightOrIsPhotochromic = hasBlueLight || isPhotochromic;

  let modificationsLabel = 'Standard';

  if (hasBlueLight) modificationsLabel = 'Blue Light Filtering';

  if (isPhotochromic) modificationsLabel = 'Photochromic';

  if (isPrecision || hasBlueLightOrIsPhotochromic) {
    // isPrecision color !== Clear         Result
    //       false           false   "No display"
    //       false            true "Color + Lens"
    //        true           false    "Lens Only"
    //        true            true "Color + Lens"
    let progressivesLabel = '';

    progressivesLabel = progressivesLabel
      .concat(color !== 'Clear' ? color : '');

    progressivesLabel = progressivesLabel
      .concat(color !== 'Clear' || isPrecision ? ` ${progressivesName}` : '');

    modificationsLabel = hasBlueLight || isPhotochromic
      ? modificationsLabel.concat(` ${progressivesLabel}`).trim()
      : progressivesLabel;
  }

  if (isAntiGatigue) {
    modificationsLabel = modificationsLabel
      .concat(`, Anti-Fatigue ${antiFatiguePower ?? ''}`.trim());
  }

  return modificationsLabel;
};

export const getBacksideARInfo = (pimItem: PimItem) => {
  const { lens_feature_map: lensFeatureMap } = pimItem;

  if (!lensFeatureMap) return '';

  const { hasSunBackAR } = getParametersFromLensFeatures(lensFeatureMap);

  return hasSunBackAR ? 'Yes' : 'No';
};

export const getAvailability = (
  productCategories: CategoryProduct[],
  orderItem: OrderItem,
  country: string,
): CountryAvailability => {
  if (!productCategories.length || !orderItem) return null;

  const viewAllow = productCategories.find(
    (cat: CategoryProduct) =>
      cat.id === `RETAIL_${country}_VIEW_ALLOW` &&
      String(cat.pc_product_config_id) === orderItem.pc_product_id,
  );
  const purchaseAllow = productCategories.find(
    (cat) =>
      cat.id === `RETAIL_${country}_PURC_ALLOW` &&
      String(cat.pc_product_config_id) === orderItem.pc_product_id,
  );

  return getCountryAvailability(country, viewAllow, purchaseAllow);
};

const getAvailabilityStatus = (view: CategoryProduct, purchase: CategoryProduct) => {
  if (view && purchase) {
    return 'Active';
  }
  if (purchase) {
    return 'Admin';
  }
  return 'Unavailable';
};

const getCountryAvailability = (country: string, viewAllow: CategoryProduct, purchaseAllow: CategoryProduct): CountryAvailability => {
  const availabilityStatus = getAvailabilityStatus(viewAllow, purchaseAllow);
  const result: CountryAvailability = {};

  if (country?.toLowerCase() === CountryCode.US) {
    return { [CountryCode.US]: availabilityStatus };
  }
  if (country?.toLowerCase() === CountryCode.CA) {
    return { [CountryCode.CA]: availabilityStatus };
  }
  return result;
};

export const isInUSCountry = (state: ReduxState) => {
  const me = '/api/v1/user/me';
  return (
    state.api[me] && state.api[me].body && state.api[me].body.facility.locale.toLowerCase() === CountryCode.US
  );
};
// See ADR #8 for context regarding the below api parsing selectors
//
// checks to see if endpoint is in apiState but has not yet loaded error or body
export const apiLoading = (apiState: any): boolean => apiState && !(apiState.error || apiState.body);

// checks to see if API call has been triggered and if endpoint exists in redux store
export const apiStatus = <ResponseType = unknown>(
  state: ReduxState,
  path: string,
) => state.api && (state.api[path] as APIResult<ResponseType>);

export const apiWipStatus = (state: ReduxState, path: string) => state.apiWIP && state.apiWIP[path];

// takes the response from API state and extracts API data in body
export function apiResponse<ResponseType = unknown>(
  status?: APIResult<ResponseType>,
): ResponseType | undefined {
  return status && status.body;
}

// takes the response from API state and extracts fail/success data
// for an action regarding an order (cancel, gesture). API response
// for actions has different edge cases that need to be accounted for,
// i.e., a body with a ok HTTP status but unsuccessful action
//
export const apiActionResponse = (status) => {
  if (!status || !status.body) {
    return;
  }

  // api call does not have 200 status
  if (status.error) {
    return {
      success: false,
      body: status.body.error.message[0],
    };
  }

  // api call has 200 status but action unsuccessful
  if (!status.error && !status.body.status.success) {
    return {
      success: false,
      body: status.body.status.error,
    };
  }

  // api call has 200 status and action successful
  if (!status.error && status.body.status.success) {
    return {
      success: true,
      body: status.body,
    };
  }
};

/*
This method selects an order from state, if it has already been fetched.
*/
export const getOrder = (state: ReduxState, params: Params) => {
  if (!params) return null;
  const orderId = selectIdFromRoute('order', params);
  const orderPath = getOrderPath(orderId);
  const orderStatus = apiStatus(state, orderPath);
  const orderResponse = apiResponse(orderStatus);
  return orderResponse && orderResponse[0];
};

export const getOrderStatus = (state: ReduxState, order: Order) => {
  if (!order) return null;
  const ordersStatus = state.apiWIP[Endpoint.SalesOrders];
  const customerOrders = order && ordersStatus[order.customer_id] && ordersStatus[order.customer_id].body;
  const currentSalesOrder = order && customerOrders && customerOrders.find(
    (salesOrder) => salesOrder.id === Number(order.id),
  );
  const status = currentSalesOrder ? getStatusFromSalesOrder(currentSalesOrder) : null;
  return status;
};

/*
Given an Order, this method selects the collection of PimItems from state,
if they have already been fetched.
*/
export const getPimItems = (state: ReduxState, order: Order) => {
  if (!order) return null;
  const pimPath = getPimItemsPath(order);
  const pimStatus = apiStatus(state, pimPath);
  return apiResponse(pimStatus);
};

export const getPimConfigProducts = (state: ReduxState, orderItems: OrderItem[]) => {
  if (!orderItems) return null;
  const pimItemsDetailedProductPath = getPimItemsConfigProductsPath(orderItems);
  const pimStatus = apiStatus(state, pimItemsDetailedProductPath);
  return apiResponse(pimStatus);
};

export const getAntiFatigueAddPowers = (state: ReduxState) => {
  const status = apiStatus<AntiFatigueAddPowerOptions>(state, ANTI_FATIGUE_ADD_POWERS_ENDPOINT);
  if (!status || status.error || !status.body) {
    return [];
  }
  return apiResponse(status).options;
};

export const getLensCategories = (configs: ConfigProduct[] | null | undefined) => {
  if (!configs) return new Map<number, LensCategoryOptions>();

  const lensConfigs = configs.filter(config => config.primary_product_category_id === 'LENS');

  return new Map<number, LensCategoryOptions>(lensConfigs.map(configItem => [
    configItem.configured_pc_product_id,
    configItem.category_ids.includes(LensCategoryOptions.OPTICAL)
      ? LensCategoryOptions.OPTICAL
      : LensCategoryOptions.SUN,
  ]));
};

export const getLensReplacementEligibleItems = (state: ReduxState) => {
  const { lensReplacement: { eligibleItems } } = state;
  return eligibleItems;
};

// format the endpoints necessary for an api call against the Endpoint enum
export const OHM_RETAIL_PATHS: UrlChecker = {
  [Endpoint.CreditMemos]: (id) => `v1/customer/${id}/credit-memos?format=nest`,
  [Endpoint.CreditMemo]: (id) => `v1/credit-memo/${id}?format=nest`,
  [Endpoint.SalesOrders]: (id) => `v1/customer/${id}/sales-orders?format=nest`,
};
