import { initialState } from './state';
import {
  GetLensReplacementValidationSuccess,
  GetPrescriptionsFailureAction,
  LRAction,
  GetSalesOrderLRDetailsBySalesOrderIdSuccessAction,
  GetPrescriptionsSuccessAction,
  GetProductDetailsFromOriginalSalesOrderSuccessAction,
  GetProductAvailabilitiesSuccessAction,
} from './actions';

const reducer = (state = initialState, action: LRAction) => {
  switch (action.type) {
    case 'FETCH_LENS_REPLACEMENT_VALIDATION_REQUEST':
      return initialState;
    case 'FETCH_LENS_REPLACEMENT_VALIDATION_SUCCESS':
      const { payload } = action as GetLensReplacementValidationSuccess;
      return {
        ...state,
        loading: false,
        error: false,
        eligibleItems: payload,
      };
    case 'FETCH_LENS_REPLACEMENT_VALIDATION_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
        eligibleItems: [],
      };
    case 'GET_PRESCRIPTIONS_REQUEST': {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case 'GET_PRESCRIPTIONS_SUCCESS': {
      const { customerId, prescriptions } = action as GetPrescriptionsSuccessAction;
      return {
        ...state,
        prescriptions: {
          ...state.prescriptions,
          [customerId]: prescriptions,
        },
        loading: false,
      };
    }
    case 'GET_PRESCRIPTIONS_FAILURE': {
      const { error } = action as GetPrescriptionsFailureAction;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case 'FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_REQUEST': {
      return {
        ...state,
        loading: true,
        error: false,
        lensReplacementDetails: [],
      };
    }
    case 'FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_SUCCESS': {
      const { payload: lrDetails } = action as GetSalesOrderLRDetailsBySalesOrderIdSuccessAction;
      return {
        ...state,
        loading: false,
        error: false,
        lensReplacementDetails: lrDetails,
      };
    }
    case 'FETCH_SALES_ORDER_LENS_REPLACEMENT_DETAILS_BY_SALES_ORDER_FAILURE': {
      return {
        ...state,
        loading: false,
        error: true,
        lensReplacementDetails: [],
      };
    }
    case 'FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_REQUEST': {
      return {
        ...state,
        loading: true,
        error: false,
        originalSalesOrderProductDetails: [],
      };
    }
    case 'FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_SUCCESS': {
      const { payload: details } = action as GetProductDetailsFromOriginalSalesOrderSuccessAction;
      return {
        ...state,
        loading: false,
        error: false,
        originalSalesOrderProductDetails: details,
      };
    }
    case 'FETCH_PRODUCT_DETAILS_FROM_ORIGINAL_SALES_ORDER_FAILURE': {
      return {
        ...state,
        loading: false,
        error: false,
        originalSalesOrderProductDetails: [],
      };
    }
    case 'FETCH_PRODUCT_AVAILABILITIES_REQUEST_SUCCESS': {
      const { payload } = action as GetProductAvailabilitiesSuccessAction;

      return {
        ...state,
        loading: false,
        error: false,
        productCategories: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
