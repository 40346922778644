import React from 'react';

import {
  Dispatch, APIParsedSummary, ServiceGesture, ServiceGesturePreview,
} from 'types';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import Nav from 'src/shared-components/screens/Nav';
import ServiceGestureForm from 'src/components/sg/ServiceGestureForm';
import ServiceGestureOverride from 'src/components/sg/ServiceGestureOverride';
import ServiceGestureModal from 'src/components/sg/ServiceGestureModal';

interface ServiceGestureConfirmationProps {
  customerId: number;
  discountAmount: string;
  dispatch: Dispatch;
  formatGesturePreview(discount: string, preview: ServiceGesturePreview): string;
  gesture: APIParsedSummary;
  gesturePreview: ServiceGesturePreview;
  handleServiceGesture(dispatch: Dispatch, jwt: string, orderId: string, presetId: number, reasonId: number, note: string, amountOverride?: number): void;
  header: string;
  href: string;
  isGestureRequested: boolean;
  jwt: string;
  orderId: string;
  preset: string;
  reason: ServiceGesture;
}

const ServiceGestureConfirmation: React.StatelessComponent<ServiceGestureConfirmationProps> = ({
  customerId,
  discountAmount,
  dispatch,
  formatGesturePreview,
  gesture,
  gesturePreview,
  handleServiceGesture,
  header,
  href,
  isGestureRequested,
  jwt,
  orderId,
  preset,
  reason,
}) => (
  <div className="page">
    <div className="content">
      <Nav
        href={href}
        text={header}
        subtext=""
      />
      <EditableSelectionPill
        subtext="Select preset for customer courtesy"
        text={preset}
        href={`/order/${orderId}/service-gesture`}
      />
      <EditableSelectionPill
        subtext="Select preset reason for customer courtesy"
        text={reason.name}
        href={href}
      />
      {reason.notes_required ? (
        <ServiceGestureForm
          preset={preset}
          discount={discountAmount}
          jwt={jwt}
          orderId={orderId}
          dispatch={dispatch}
          handleServiceGesture={handleServiceGesture}
          reason={reason}
          isGestureRequested={isGestureRequested}
          formatGesturePreview={formatGesturePreview}
          gesturePreview={gesturePreview}
        />
      ) : (
        <ServiceGestureOverride
          discount={discountAmount}
          isGestureRequested={isGestureRequested}
          jwt={jwt}
          dispatch={dispatch}
          orderId={orderId}
          reason={reason}
          handleServiceGesture={handleServiceGesture}
          formatGesturePreview={formatGesturePreview}
          note=""
          gesturePreview={gesturePreview}
        />
      )}
      {gesture && <ServiceGestureModal customerId={customerId} orderId={orderId} summary={gesture} />}
    </div>
  </div>
);

export default ServiceGestureConfirmation;
