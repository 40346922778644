import * as React from 'react';

import { CSSStyle } from 'types';

const style: CSSStyle = {
  background: {
    background: '#F7F7F7',
    height: '100%',
  },
  crash: {
    fontSize: '24px',
    fontWeight: 700,
    margin: '0',
    paddingTop: '150px',
    textAlign: 'center',
  },
  container: {
    margin: '100px auto 0',
    width: '600px',
  },
  error: {
    background: 'white',
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    fontSize: '18px',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    padding: '0 50px',
    lineHeight: '1.6rem',
  },
  traceback: {
    padding: '10px 50px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '40px',
  },
  button: {
    background: '#1F97D8',
    color: 'white',
    flex: '1',
    fontSize: '18px',
    fontWeight: 700,
    padding: '20px 0',
    textAlign: 'center',
    textDecoration: 'none',
  },
};

const errorContainer = { ...style.container, ...style.error };
const traceback = { ...style.content, paddingTop: '20px' };
const buttonContainer = { ...style.container, ...style.buttonContainer };
const actionButton = { ...style.error, ...style.button };

interface ErrorScreenProps {
  error: any;
  info: any;
  nextURL: string;
}

const ErrorScreen: React.StatelessComponent<ErrorScreenProps> = ({ error, info, nextURL }) => {
  const hasErrors = error && info;
  const errorMessage = hasErrors ? error.toString() : error;
  const errorInfo = hasErrors ? info.componentStack : '';

  return (
    <div style={style.background}>
      <p style={style.crash}>ROM has crashed.</p>
      <div style={errorContainer}>
        <p style={style.content}>{errorMessage}</p>
        <p style={traceback}>Traceback (most recent call last):</p>
        <p style={style.content}>{errorInfo}</p>
      </div>
      <div style={buttonContainer}>
        <button style={actionButton} onClick={() => window.location.reload()}>Try again</button>
        <a style={actionButton} href={nextURL}>Restart POE</a>
      </div>
    </div>
  );
};

export default ErrorScreen;
