import React, { useState, useEffect } from 'react';

import { formatPrice, capitalizeString } from 'selectors';
import { OrderItem, PimItem, ReduxState } from 'types';
import { styles } from 'style';
import { defaultImage } from 'src/shared-components/order-actions/common';

import CheckboxSelected from 'src/shared-components/icons/checkbox-selected.svg';
import CheckboxUnselected from 'src/shared-components/icons/checkbox-unselected.svg';
import { Badge } from 'src/shared-components/badge/Badge';
import { connect } from 'react-redux';
import { ItemsLensReplacementEligibility } from 'src/types/lens-replacement';
import { isItemEligibleForFreeLR } from '../lens-replacement/utils';


const style = {
  selectedPill: {
    border: '1px solid #00A2E1',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
    padding: '10px 0',
  },
  padding: {
    paddingTop: '10px',
  },
  checkboxSelected: {
    background: `url(${CheckboxSelected}) no-repeat`,
    height: '25px',
    width: '25px',
    minWidth: '25px',
    marginLeft: 'auto',
  },
  checkboxUnselected: {
    background: `url(${CheckboxUnselected}) no-repeat`,
    height: '25px',
    width: '25px',
    minWidth: '25px',
    marginLeft: 'auto',
  },
};

const selectedPill = { ...styles.itemPill, ...style.selectedPill };
const totalItemPrice = (isForLensReplacement: boolean, total: number, quantity: number) => isForLensReplacement ? total / quantity : total;

interface ItemCardProps {
  addItem(id: string): void;
  removeItem(id: string): void;
  returnable: boolean;
  item: OrderItem;
  pimItem: PimItem;
  lensReplacementEligibleItems?:ItemsLensReplacementEligibility[];
  isForLensReplacement?: boolean;
}

const ItemCard: React.StatelessComponent<ItemCardProps> = ({
  item, pimItem, addItem, removeItem, returnable, lensReplacementEligibleItems, isForLensReplacement,
}) => {
  const { total, id, quantity } = item;
  const { image, product_name, color } = pimItem;
  const itemImage = image || defaultImage;
  const isNotLensReplacementEligible = !lensReplacementEligibleItems.length;
  const isFreeLR = isItemEligibleForFreeLR(item, lensReplacementEligibleItems) && !isNotLensReplacementEligible;
  const isPaidLR = !isFreeLR && !isNotLensReplacementEligible;
  const [state, setValues] = useState({
    itemSelected: false,
  });

  const toggleSelected = () => {
    setValues({
      ...state,
      itemSelected: !state.itemSelected,
    });
  };

  useEffect(() => {
    const { itemSelected } = state;
    if (itemSelected) {
      addItem(id);
      return;
    }

    removeItem(id);
  }, [state]);

  const { itemSelected } = state;
  const itemDescription = color && capitalizeString(color);
  const container = itemSelected ? selectedPill : styles.itemPill;

  return (
    <div style={container} onClick={returnable ? toggleSelected : () => {}}>
      <div style={style.content}>
        <img src={itemImage} style={styles.image} />
        <div style={style.padding}>
          <p style={styles.utopiaBoldPara}>{product_name}</p>
          <p style={styles.utopiaItalPara}>{itemDescription}</p>
          <p style={styles.largePara}>{formatPrice(totalItemPrice(isForLensReplacement, total, quantity))}</p>
          {
            isForLensReplacement && (
              <div>
                {isFreeLR && <Badge variant='success'> Scratch LR eligible </Badge>}
                {isPaidLR && <Badge variant='secondary'> Paid LR eligible </Badge>}
                {isNotLensReplacementEligible && <Badge variant='error'> No LR eligible </Badge>}
              </div>
            )
          }
        </div>
        {returnable && <div style={itemSelected ? style.checkboxSelected : style.checkboxUnselected} />}
      </div>
    </div>
  );
};

const select = (state: ReduxState) => ({
  lensReplacementEligibleItems : state.lensReplacement.eligibleItems,
});


export default connect(select)(ItemCard);
