import { CSSStyle } from 'types';

export const styles: CSSStyle = {
  page: {
    background: 'white',
    height: '100%',
  },
  nav: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    flexFlow: 'row wrap',
    left: '0',
    maxWidth: '768px',
    margin: '0 auto',
    marginBottom: '35px',
    padding: '30px 20px',
    position: 'fixed',
    right: '0',
    textDecoration: 'none',
    top: '0',
    zIndex: 2,
  },
  navSubtext: {
    color: '#676F78',
    fontSize: '16px',
    margin: '0',
    textAlign: 'center',
  },
  pill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    color: '#434D58',
    display: 'block',
    fontSize: '18px',
    fontWeight: 600,
    margin: '25px 0',
    padding: '24px 30px',
    textAlign: 'center',
    textDecoration: 'none',
  },
  editablePill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    display: 'flex',
    margin: '15px 0 0',
    padding: '24px 30px',
    textDecoration: 'none',
  },
  orangePill: {
    backgroundColor: '#FEE9E5',
    border: '1px solid #D55C12',
    borderRadius: '8px',
    color: '#434D58',
    display: 'flex',
    fontSize: '18px',
    fontWeight: 600,
    justifyContent: 'space-between',
    margin: '90px 0 15px',
    marginTop: '',
    padding: '18px 20px',
    textAlign: 'center',
    textDecoration: 'none',
  },
  largeBoldPara: {
    color: '#414B56',
    fontSize: '22px',
    fontWeight: 600,
    margin: '0',
    textAlign: 'center',
  },
  paddedPara: {
    color: '#414B56',
    fontSize: '22px',
    fontWeight: 600,
    margin: '40px 0 22px',
    textAlign: 'center',
  },
  paddedLargePara: {
    color: '#434D58',
    fontSize: '25px',
    fontWeight: 600,
    margin: '0',
    padding: '30px 0',
    textAlign: 'center',
  },
  boldPara: {
    color: '#525E69',
    fontSize: '18px',
    fontWeight: 600,
    margin: '0',
  },
  para: {
    color: '#525E69',
    fontSize: '18px',
    margin: '0',
  },
  microPara: {
    color: '#676F78',
    fontSize: '12px',
    margin: '0',
    paddingBottom: '5px',
  },
  editButton: {
    alignSelf: 'center',
    background: 'none',
    border: 'none',
    color: '#0089BF',
    fontSize: '18px',
    fontWeight: 600,
    display: 'flex',
    margin: '0 0 0 auto',
    padding: '0',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  formInput: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    boxSizing: 'border-box',
    display: 'block',
    margin: '25px 0',
    padding: '24px 0px 24px 30px',
    textDecoration: 'none',
    width: '100%',
  },
  button: {
    background: '#00A2E1',
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    boxSizing: 'border-box',
    color: 'white',
    cursor: 'pointer',
    display: 'block',
    fontSize: '18px',
    fontWeight: 600,
    margin: '15px 0 0',
    padding: '24px 30px',
    textAlign: 'center',
    textDecoration: 'none',
    width: '100%',
  },
  itemPill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    textAlign: 'left',
    padding: '24px 30px',
    margin: '0 auto 15px',
  },
  utopiaBoldPara: {
    color: '#414B56',
    fontFamily: 'utopia-std, serif',
    fontSize: '24px',
    fontWeight: 600,
    margin: '0 0 10px',
    width: '0 auto',
  },
  utopiaItalPara: {
    color: '#A1A4A6',
    fontFamily: 'utopia-std, serif',
    fontSize: '22px',
    fontStyle: 'italic',
    margin: '0 0 25px',
  },
  largePara: {
    color: '#414B56',
    fontSize: '20px',
    fontWeight: 600,
  },
  image: {
    height: 'auto',
    marginRight: '30px',
    width: '50%',
  },
  disabledButton: {
    opacity: 0.6,
  },
};
