import * as React from 'react';

import { styles } from 'style';

interface FormProps {
  disabled: boolean;
  name: string;
  onChange(event: any): void;
  onSubmit(event: any): void;
  placeholder?: string;
  submitButton: any;
  text: string;
  value: string;
}

const Form: React.StatelessComponent<FormProps> = ({
  onSubmit, onChange, placeholder, name, value, submitButton, disabled, text,
}) => (
  <form onSubmit={onSubmit}>
    <textarea
      style={styles.formInput}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
      value={value}
    />
    <button style={submitButton} disabled={disabled}>{text}</button>
  </form>
);

export default Form;
