import { LensReplacementState } from './types';


// eslint-disable-next-line import/prefer-default-export
export const initialState: LensReplacementState = {
  loading: false,
  error: false,
  eligibleItems: [],
  prescriptions: {},
  lensReplacementDetails: [],
  originalSalesOrderProductDetails: [],
  productCategories: [],
};
