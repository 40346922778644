import React from 'react';
import { connect } from 'react-redux';
import { styles } from 'style';
import styled from 'react-emotion';

import Link from 'src/components/app/Link';
import backArrow from 'src/shared-components/icons/back-arrow.svg';
import { ReduxState, Order, Params } from 'types';
import { getOrder } from 'selectors';
import urlstr from 'static/urlstr';
import { formatParams } from 'src/components/app/routeUtils';
import { useLRStepper } from 'src/components/lens-replacement/lr-stepper';
import waitlistActiveImg from '../icons/waitlist-blue.svg';

const BackArrow = styled('span')`
  display: block;
  background: url(${backArrow}) no-repeat;
  height: 19px;
  width: 26px;
  cursor: pointer;
`;

const StyledHeader = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 20px;
  background: white;
  height: 35px;
`;

const WidthSetter = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 768px;
  margin: auto;
`;

const Waitlist = styled('span')`
  display: block;
  background: url(${waitlistActiveImg}) no-repeat;
  background-size: 100%;
  height: 29px;
  width: 35px;
  cursor: pointer;
`;

const navHeader = { ...styles.largeBoldPara };

const header = (text: string): JSX.Element => (
  <span style={navHeader}>{text}</span>
);

const headerWithSubtext = (text: string, subtext: string): JSX.Element => (
  <div>
    <p style={navHeader}>{text}</p>
    <p style={styles.navSubtext}>{subtext}</p>
  </div>
);

interface NavProps {
  href: string;
  subtext: string;
  text: string;
  order: Order;
}

const RenderNavHeader = ({ text, subtext }: { text: string; subtext?: string }) => {
  if (subtext) {
    return headerWithSubtext(text, subtext);
  }
  return header(text);
};

const RenderBackArrow = ({ href, isFirstStep, previousStep }: { href: string; isFirstStep: number | false; previousStep: () => void }) => isFirstStep
  ? <BackArrow onClick={previousStep} />
  : (
        <Link href={href}>
          <BackArrow />
        </Link>
  );

const Nav: React.StatelessComponent<NavProps> = ({ href, text, subtext, order }) => {
  const stepper = useLRStepper('Nav', true);
  const previousStep = stepper ? stepper.previousStep : () => {};
  const isFirstStep = stepper ? stepper.stepIdx : false;

  const handleWaitlist = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { customer_id, customer_name, id } = order;
    const next = window.location.href;

    window.location.href = urlstr({
      host: process.env.WAITLIST_APP_DOMAIN,
      pathname: '/add-customer',
      query: {
        name: customer_name, id: String(customer_id), order: String(id), next,
      },
    });
  };

  return (
    <StyledHeader>
      <WidthSetter>
        <RenderBackArrow href={href} isFirstStep={isFirstStep} previousStep={previousStep} />
        <RenderNavHeader text={text} subtext={subtext} />
        {order ? <Waitlist onClick={handleWaitlist} /> : <span>{' '}</span>}
      </WidthSetter>
    </StyledHeader>
  );
};

const select = (state: ReduxState) => {
  const routeParams = formatParams(window.location.pathname);
  const order = getOrder(state, routeParams);

  return ({ order });
};

export default connect(select)(Nav);
