import { OrderItem } from 'types';
import { LensCategoryOptions } from 'src/utils/constants';
import { ConfigGalleryProduct, FrameConfigDetails, MapItemFrameAttributes } from '../../types/lens-replacement';
import { FrameAttributes, FrameAttributeConfiguration } from './types';

export const getFrameAttributesFromMapItems = (
  salesOrderItem,
  itemConfigurations: MapItemFrameAttributes,
  attributeKey: FrameAttributes,
): FrameAttributeConfiguration => itemConfigurations
  .find((item) => item.get(Number(salesOrderItem.id)))
  .get(Number(salesOrderItem.id))[attributeKey];

export const getFrameConfigsByOrderItem = (frameConfigs: ConfigGalleryProduct[], item: OrderItem) => {
  const selectedConfig = frameConfigs.find(
    (fa) => !!fa[String(item.pc_product_id)],
  );

  return Reflect.get(selectedConfig, String(item.pc_product_id));
};

export const isSunLensCategory = (item: FrameConfigDetails | null | undefined) =>
  item && item.lens_category === LensCategoryOptions.SUN;
