import React from 'react';
import { connect } from 'react-redux';
import {
  formatPrice,
  capitalizeString,
  getLensCategories,
  getLensModification,
  isInUSCountry,
  getOrder,
  getPimItemsDetailedProductPath,
  getBacksideARInfo,
  getPimItemsConfigProductsPath,
  getLensType,
  currentUserHasFeature,
} from 'selectors';
import { styles } from 'style';
import { isFrame } from 'src/utils/frameProducts';
import { LensCategoryOptions } from 'src/utils/constants';
import StatusItem from '../status-item';
import { PimProductCardProps, defaultImage, style } from './common';
import { ReduxState } from '../../../types';
import { formatParams } from '../../components/app/routeUtils';
import { formatLensReplacementProductName, getLensReplacementSuffix } from '../../components/order/util';

const chinDetail = (subtext: string, text: string | number): JSX.Element => (
  <span style={style.flex}>
    <p style={style.subPara}>{subtext}</p>
    <p style={styles.boldPara}>{text || '-'}</p>
  </span>
);

const borderedPara = { ...style.boldPara, ...style.border };
const greenPara = { ...styles.largePara, ...style.greenPara };



const PimProductCard: React.FC<PimProductCardProps> = ({
  afAddPowers,
  total,
  pimItem,
  itemDetails,
  returnReason = null,
  rxName,
  availability,
  canLensReplacement,
  lensCategories,
  lensReplacementDetails,
  originalSalesOrderProductDetails,
  lensReplacementSalesOrderItems,
}) => {
  const {
    color,
    image,
    product_name,
    lens_feature_map,
    primary_product_category_id,
    pc_product_id,
  } = pimItem;

  const isSunLenses = lensCategories.has(pc_product_id)
    ? lensCategories.get(pc_product_id) === LensCategoryOptions.SUN
    : false;

  const {
    itemId, ocHeight, segHeightOD, segHeightOS, antiFatiguePower,
  } = itemDetails || {};

  let afAddPowerDisplayName;

  if (afAddPowers) {
    afAddPowerDisplayName = afAddPowers
      .find((o) => o.name === antiFatiguePower)?.description || antiFatiguePower;
  }

  const itemColor = color && capitalizeString(color);
  const itemImage = image || defaultImage;
  const shouldRenderHeight = false;
  const itemPillPaddedStyle = { ...style.itemPill, ...style.bottomPadding };
  const itemContainerStyle = shouldRenderHeight ? style.itemPill : itemPillPaddedStyle;
  const isAntiFatigue = lens_feature_map && !!lens_feature_map.ANTI_FATIGUE;
  const segHeightName = isAntiFatigue ? 'Fitting Height' : 'Seg Height';

  const getProductName = () => {
    const lensReplacementNameSuffix = getLensReplacementSuffix(
      lensReplacementDetails,
      originalSalesOrderProductDetails,
      itemId,
    );

    if (!lensReplacementNameSuffix) return product_name;

    return formatLensReplacementProductName(
      product_name,
      lensReplacementNameSuffix,
      lensReplacementSalesOrderItems,
      pc_product_id,
    );
  };

  const getItemImage = () => {
    const detail = lensReplacementDetails.find((detail) => detail.sales_order_item_id === itemId);
    return detail ? detail.image_url : itemImage;
  };

  return (
    <div style={itemContainerStyle}>
      {
        returnReason && (
        <div style={style.eyebrow}>
          <span style={styles.boldPara}>
            Return reason:
            {returnReason}
          </span>
        </div>
        )
      }
      <div style={style.wrapper}>
        <img src={canLensReplacement ? getItemImage() : itemImage} style={styles.image}  alt="Item" />
        <div style={style.topPadding}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={styles.utopiaBoldPara}>
              {canLensReplacement ? getProductName() : product_name}
            </p>
            {canLensReplacement && isFrame(primary_product_category_id) ? <StatusItem status={availability?.us || availability?.ca} /> : null}
          </div>
          <p style={styles.utopiaItalPara}>{itemColor}</p>
          <p style={total < 0 ? greenPara : styles.largePara}>{formatPrice(total)}</p>
        </div>
      </div>
      {lens_feature_map && (
        <div style={style.chinWrapper}>
          {chinDetail('Rx Name', rxName || 'Loading...')}
          {chinDetail('Lens type', getLensType(pimItem))}
          {chinDetail('Lens modifications', getLensModification(pimItem, afAddPowerDisplayName))}
          {isSunLenses && chinDetail('Backside A/R?', getBacksideARInfo(pimItem))}
        </div>
      )}
      {(ocHeight || segHeightOD || segHeightOS) && (
        <div style={style.chinWrapper}>
          {chinDetail('OC Height', ocHeight)}
          {chinDetail(`OD ${segHeightName}`, segHeightOD)}
          {chinDetail(`OS ${segHeightName}`, segHeightOS)}
        </div>
      )}
      {shouldRenderHeight && (
        <div style={style.heightWrapper}>
          <p style={style.boldPara}>18</p>
          <p style={borderedPara}>Sarah Mosner</p>
        </div>
      )}
    </div>
  );
};

const select = (state: ReduxState) => {
  const { api, lensReplacement } = state;
  const routeParams = formatParams(window.location.pathname);
  const order = getOrder(state, routeParams);
  const pimItemsDetailsPath = getPimItemsDetailedProductPath(order?.items);
  const itemsConfigProductsPath = getPimItemsConfigProductsPath(order?.items);
  const lensReplacementSalesOrderItems = api[pimItemsDetailsPath]?.body;
  const configProducts = api[itemsConfigProductsPath]?.body as any;
  const { lensReplacementDetails, originalSalesOrderProductDetails } = lensReplacement;
  const lensCategories = getLensCategories(configProducts);
  const canLensReplacement: boolean = isInUSCountry(state);

  return {
    lensCategories,
    lensReplacementDetails,
    originalSalesOrderProductDetails,
    lensReplacementSalesOrderItems: lensReplacementSalesOrderItems || [],
    canLensReplacement,
  };
};

export default connect(select)(PimProductCard);
