import React, { useEffect, useRef, useState } from 'react';
import { PopoverContainerRef, PopoverModal } from 'src/shared-components/popover-modal';
import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { TabsContainer, Tab } from './styles';
import { WARNING_DESCRIPTION } from '../constants';
import { usePrescriptionStepper } from '../prescription-stepper';

type Props = {
  PrescriptionsTab: JSX.Element;
  ReadersTab: JSX.Element;
  isReaderOrder: boolean;
};

enum TabOptions {
  Prescriptions = 'prescriptions',
  Readers = 'readers',
}

const PrescriptionTypeSelector = ({
  PrescriptionsTab,
  ReadersTab,
  isReaderOrder,
}: Props) => {
  const readersWarningPopoverRef = useRef<PopoverContainerRef>(null);
  const { stepIdx } = usePrescriptionStepper('PrescriptionSubStepper', true);
  const [activeTab, setActiveTab] = useState(isReaderOrder ? TabOptions.Readers : TabOptions.Prescriptions);
  const [hasAcceptedDisclaimer, setHasAcceptedDisclaimer] = useState<boolean>(false);

  useEffect(() => {
    setActiveTab(isReaderOrder ? TabOptions.Readers : TabOptions.Prescriptions);
  }, [stepIdx]);

  const onClick = (tab: TabOptions) => {
    setActiveTab(tab);
    if (!hasAcceptedDisclaimer && !isReaderOrder) {
      onOpenModal();
    }
  };

  const onDismiss = useCallbackRef(() => {
    if (!readersWarningPopoverRef.current) return;
    readersWarningPopoverRef.current.closeModal();
  });

  const onContinue = useCallbackRef(() => {
    if (!readersWarningPopoverRef.current) return;
    setHasAcceptedDisclaimer(true);
    readersWarningPopoverRef.current.closeModal();
  });

  const onOpenModal = useCallbackRef(() => {
    if (!readersWarningPopoverRef.current) return;
    readersWarningPopoverRef.current.openModal();
  });

  return (
    <div>
      <TabsContainer>
        <Tab
          active={activeTab === TabOptions.Prescriptions}
          onClick={() => onClick(TabOptions.Prescriptions)}
        >
          Prescription
        </Tab>
        <Tab
          active={activeTab === TabOptions.Readers}
          onClick={() => onClick(TabOptions.Readers)}
        >
          Readers
        </Tab>
      </TabsContainer>
      <div>
        {activeTab === TabOptions.Prescriptions && (
          PrescriptionsTab
        )}
        {activeTab === TabOptions.Readers && (
          ReadersTab
        )}
      </div>
      <PopoverModal
        ref={readersWarningPopoverRef}
        title='Heads up!'
        description= {WARNING_DESCRIPTION}
        cancelLabel='Dismiss'
        confirmLabel='Continue'
        onCancel={onDismiss}
        onConfirm={onContinue}
      />
    </div>
  );
};

export default PrescriptionTypeSelector;
