import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { PopoverContainerRef, PopoverModal } from 'src/shared-components/popover-modal';
import { CheckboxField } from 'src/shared-components/checkbox';
import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { OrderItem } from 'types';
import { useLRStepper } from '../../lr-stepper';
import { CheckBoxWrapper } from './styles';
import { HighIndexConsentData } from './constants';

export interface HighIndexConsentStepRef {
  openHighIndexConsentStep: () => void
}

type HighIndexConsentStepProps = {
  stepIdx: number;
  isLastItem: boolean;
  nextStep: () => void;
  finishStep: () => void;
};

const HighIndexConsentStep: React.ForwardRefRenderFunction<HighIndexConsentStepRef, HighIndexConsentStepProps> = ({ stepIdx, isLastItem, nextStep, finishStep }, ref) => {
  const { updateData, data } = useLRStepper('HighIndexConsentStep');
  const consentPopoverRef = useRef<PopoverContainerRef>(null);
  const [isHighIndexConsentGiven, SetIsHighIndexConsentGiven] = useState(false);
  const [orderItems, setOrderItems] = useState<OrderItem[]>(data.selectedItems);

  const handleDismiss = useCallbackRef(() => {
    if (!consentPopoverRef.current) return;
    consentPopoverRef.current.closeModal();
  });
    
  const openHighIndexConsentStep = useCallback(() => {
    if (!consentPopoverRef.current) return;

    consentPopoverRef.current.openModal();
  }, []);
    
  const toggleConsent = useCallbackRef(() => {
    SetIsHighIndexConsentGiven(oldState => !oldState);
  });
    
  const addHighIndexConsentDateToItem = useCallbackRef(() => {
    const newItem = { ...data.selectedItems[stepIdx], high_index_consent_at: new Date() };
    const itemId = data.selectedItems[stepIdx].id;
    setOrderItems(oldState => oldState.map(item => (item.id === itemId ? newItem : item)));
  });

  useImperativeHandle(ref, () => ({
    openHighIndexConsentStep,
  }));

  // eslint-disable-next-line no-promise-executor-return
  const updateSelectedItem = new Promise((resolve) => resolve(updateData({ selectedItems: orderItems })));

  const handleContinueConsent = useCallbackRef(() => {
    if (!consentPopoverRef.current) return;

    addHighIndexConsentDateToItem();

    if ( isLastItem ) {
      updateSelectedItem.then(() => {
        finishStep();
      });
    } else {
      nextStep();
      SetIsHighIndexConsentGiven(oldState => !oldState);
    }
    consentPopoverRef.current.closeModal();
  });
    
  return (
    <PopoverModal
    ref={consentPopoverRef}
    title={HighIndexConsentData.title}
    description={HighIndexConsentData.description}
    cancelLabel='Dismiss'
    confirmLabel='Continue'
    onCancel={handleDismiss}
    onConfirm={isHighIndexConsentGiven ? handleContinueConsent : null}
    >
    <CheckBoxWrapper>
      <CheckboxField
      label={HighIndexConsentData.checkBoxLabel}
      handleCheck={() => toggleConsent()}
      isChecked={isHighIndexConsentGiven}
      />
    </CheckBoxWrapper>
  </PopoverModal>
  );
};

export const HighIndexConsentStepModal = forwardRef(HighIndexConsentStep);