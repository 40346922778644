/* eslint-disable import/prefer-default-export */
import styled from 'react-emotion';
import colors from 'src/utils/colors';

export const Content = styled('div')`
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 4rem;

  width: 100%;
  min-height: 75vh;

  & > * {
    max-width: 720px;
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }
`;

export const ConsentNotes = styled('div')`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid ${colors.stone};
  border-radius: 0.5rem;

  padding: 1rem;

  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  color: ${colors.charcoal};
  text-align: justify;

  ul {
    padding: 0 1rem;
    li {
      & + li {
        margin-top: 1rem;
      }
    }
  }
`;

export const StyledActionsContainer = styled('div')`
  width: 100%;

  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled('h1')`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
`;
