import styled from 'react-emotion';
import colors from 'src/utils/colors';

export const TabsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TabProps = {
  active: boolean;
};

export const Tab = styled('div')<TabProps>`
  cursor: pointer;
  padding: 1.5rem;
  width: 9rem;
  text-align: center;
  border: 1px solid ${colors.silver};
  border-radius: 5px;
  ${(props) =>
    props.active &&
  `
    border: 1px solid ${colors.darkBlue};
    font-weight: bold;

  `}
`;
