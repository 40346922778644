import {
  ErrorState, Actions, ErrorAction, FETCH_REQUEST_FAILURE,
} from 'types';

const initialState: ErrorState = {
  info: null,
};

export const errorReducer = (state = initialState, action: Actions): ErrorState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REQUEST_FAILURE:
      return { ...state, info: payload as ErrorAction };
    default:
      return state;
  }
};
