import { Action } from 'redux';
import { OrderId, RetailOrder } from './types';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

export interface GetOrderRequestAction extends Action {
  orderId: OrderId;
}

export interface GetOrderSuccessAction extends Action {
  orderId: OrderId;
  order: RetailOrder;
}

export interface GetOrderFailureAction extends Action {
  orderId: OrderId;
  error: string;
}

export type OrdersAction = GetOrderRequestAction | GetOrderSuccessAction | GetOrderFailureAction;
