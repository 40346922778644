import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  ReduxState, OrderItem, Dispatch, Order, Params, PimItem,
} from 'types';
import { actions } from 'src/redux/actions';
import {
  formatDate, getOrder, getPimItems, selectPimItem, isShipping,
} from 'selectors';
import { styles } from 'style';

import Nav from 'src/shared-components/screens/Nav';
import Loading from 'src/shared-components/screens/Loading';
import Link from 'src/components/app/Link';
import ItemCard from 'src/components/return/ItemCard';

const formatReturnPrompt = (items: OrderItem[]): string => (
  items.length > 1 ? 'Which items would you like to return?' : 'Which item would you like to return?'
);

const formatLink = (orderId: string, returnOrderType: string, selectedItemIds: string[]): string => {
  if (!selectedItemIds.length) {
    return '#';
  }

  const itemIds = selectedItemIds.join(',');
  return `/order/${orderId}/return-items/${returnOrderType}/items/${itemIds}`;
};

const formatButtonText = (itemIds: string[], returnOrderType: string): string => {
  const { length } = itemIds;

  if (returnOrderType === 'exchange') {
    return length === 1 ? `Exchange ${length} item` : `Exchange ${length} items`;
  }

  return length === 1 ? `Return ${length} item` : `Return ${length} items`;
};

const checkIfReturnable = (item: OrderItem): boolean => item.invoices && item.invoices.some((invoice) => {
  if (!invoice.returnable) return false;
  if (invoice.authorized_return_quantity === null) return true;
  return (invoice.quantity > invoice.authorized_return_quantity);
});

const disabledButton = { ...styles.button, ...styles.disabledButton };

interface ReturnItemListProps {
  dispatch: Dispatch;
  jwt: string;
  order: Order;
  pimItems: PimItem[];
  params: Params;
}

const ReturnItemList: React.StatelessComponent<ReturnItemListProps> = ({
  jwt, dispatch, order, pimItems, params,
}) => {
  const orderId = params.order;
  const returnOrderType = params['return-items'];

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);
  useEffect(actions.fetchPimItems(dispatch, jwt, order), [order]);

  const [state, setValues] = useState({
    selectedItemIds: [],
  });

  if (!order || !pimItems) return <Loading />;

  const addItem = (id: string): void => {
    setValues({
      ...state,
      selectedItemIds: [...state.selectedItemIds, id],
    });
  };

  const removeItem = (id: string): void => {
    const { selectedItemIds } = state;
    const updatedItemIds = selectedItemIds.filter((itemId) => itemId !== id);
    setValues({
      ...state,
      selectedItemIds: updatedItemIds,
    });
  };

  const { selectedItemIds } = state;
  const orderPlaced = order && formatDate(order.placed);

  // Filter out those order items which are a shipping and handling item.
  // We don't want to display them in the return item list.
  const items = order.items && order.items.filter((i) => !isShipping(selectPimItem(pimItems, i)));

  const returnPrompt = order && formatReturnPrompt(items);
  const hasSelectedItems = !!selectedItemIds.length;
  const button = hasSelectedItems ? styles.button : disabledButton;
  const href = order && formatLink(orderId, returnOrderType, selectedItemIds);
  const returnButtonText = order && formatButtonText(selectedItemIds, returnOrderType);

  return (
    <div className="page" style={styles.page}>
      <div className="content">
        <Nav
          href={`/customer/${order.customer_id}/order/${order.id}`}
          text={`Order ${order.id}`}
          subtext={`Ordered on ${orderPlaced}`}
        />
        <p style={styles.paddedLargePara}>{returnPrompt}</p>
        {items.map((item) => (
          <ItemCard
            item={item}
            pimItem={selectPimItem(pimItems, item)}
            key={item.id}
            returnable={checkIfReturnable(item)}
            addItem={addItem}
            removeItem={removeItem}
          />
        ))}
        <Link style={button} href={href}>{returnButtonText}</Link>
      </div>
    </div>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);
  const pimItems = getPimItems(state, order);

  return ({
    jwt: state.auth.jwt,
    order,
    pimItems,
  });
};

export default connect(select)(ReturnItemList);
