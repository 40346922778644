import { browserHistory } from '../../../browserHistory';

export const redirect = (href: string) => {
  const nextURL = window.location.search;
  browserHistory.push(href + nextURL);
};

export const formatParams = (url: string): any => {
  const routeParams = {};
  const routeParts = url.split('/');

  routeParts.forEach((part, index) => {
    const hasNumbers = part.match(/\d/g); // part includes any number;
    const isLast = (index + 1) === routeParts.length; // part is last in routeParts arr
    const hasCommas = part.includes(','); // part has comma separated ids
    if (part && !hasNumbers && !hasCommas && !isLast) {
      routeParams[part] = routeParts[index + 1];
    }
  });

  return routeParams;
};
