import React from 'react';
import { connect } from 'react-redux';
import { styles } from 'style';
import { LENS_REPLACEMENT_CREATE_PERMISSION } from 'src/utils/user-permissions';
import { ReduxState } from '../../../../../types';
import {
  currentUserHasPermission,
  getLensReplacementEligibleItems,
  isInUSCountry,
} from '../../../../../selectors';
import lensReplacementButton from './styles';

interface Props {
  canLensReplacement: boolean;
  hasLensReplacementPermission: boolean;
  isLensReplacementEligible: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
}

const LensReplacementButton: React.FC<Props> = (props: Props) => {
  const { canLensReplacement, isLensReplacementEligible, onClick, isDisabled, hasLensReplacementPermission } = props;
  const lensReplacementText = 'Lens replacement';
  const disableButton = isDisabled || !hasLensReplacementPermission || !isLensReplacementEligible;
  if (!canLensReplacement) return null;
  const customStyles = disableButton
    ? { ...lensReplacementButton, ...styles.disabledButton }
    : lensReplacementButton;
  return (
    <button type="button" onClick={onClick} style={customStyles} disabled={disableButton}>
      {lensReplacementText}
    </button>
  );
};

const select = (state: ReduxState) => {
  const eligibleItems = getLensReplacementEligibleItems(state);

  return {
    canLensReplacement: isInUSCountry(state),
    hasLensReplacementPermission:
      currentUserHasPermission(LENS_REPLACEMENT_CREATE_PERMISSION, state),
    isLensReplacementEligible: eligibleItems.length > 0,
  };
};

export default connect(select)(LensReplacementButton);
