import React from 'react';
import { ReadersTab } from '../readers-tab';
import { StatePrescriptions, UserPrescription } from '../../../redux/lens-replacement/types';
import { LRData } from '../lr-stepper/types';
import { RetailOrder } from '../../../redux/order/types';
import PrescriptionTypeSelector from '../prescriptions-type-selector';
import PrescriptionsTab from '../prescription-tab';
import { LR_PRODUCT_TYPE } from '../../../utils/constants';

type Props = {
  customerId: string;
  selectedForSwap: number | null;
  retailOrder: RetailOrder;
  currentSelection: UserPrescription;
  prescriptions: StatePrescriptions;
  lrData: LRData;
  originalPrescription: UserPrescription;
  onSwapClick: (item: UserPrescription) => void;
  onSelectForSwap: (prescriptionId: number) => void;
  onReadersStrengthOptionClick: (option: string) => void;
  readerStrengthOption: string | null;
};

const PrescriptionsContent = ({
  prescriptions,
  lrData,
  customerId,
  currentSelection,
  originalPrescription,
  retailOrder,
  onSwapClick,
  selectedForSwap,
  onSelectForSwap,
  onReadersStrengthOptionClick,
  readerStrengthOption,
}: Props) => {
  if (!prescriptions || !prescriptions[customerId] || !retailOrder.items.length) return null;

  const { type } = lrData;
  const originalReaderStrength = retailOrder.items[0] ? retailOrder.items[0].attributes.frame.readers_strength : null;

  const PrescriptionsTabContent = (
    <PrescriptionsTab
      customerId={customerId}
      prescriptions={prescriptions}
      originalPrescription={originalPrescription}
      currentSelection={currentSelection}
      retailOrder={retailOrder}
      lrData={lrData}
      selectedForSwap={selectedForSwap}
      onSelectForSwap={onSelectForSwap}
      onSwapClick={onSwapClick}
    />
  );

  const ReadersTabContent = (
    <ReadersTab
      originalOption={originalReaderStrength}
      onReadersStrengthOptionClick={onReadersStrengthOptionClick}
      selectedStrength={readerStrengthOption}
    />
  );

  if (type === LR_PRODUCT_TYPE.FREE) {
    return readerStrengthOption ? ReadersTabContent : PrescriptionsTabContent;
  }

  return (
    <PrescriptionTypeSelector
      PrescriptionsTab={PrescriptionsTabContent}
      ReadersTab={ReadersTabContent}
      isReaderOrder={Boolean(readerStrengthOption)}
    />
  );
};

export default PrescriptionsContent;
