import styled from 'react-emotion';

import colors from '../../utils/colors';

export const StyledLabel = styled('label')`
  display: flex;
  align-items: center;
  font-weight: 600;
  user-select: none;
`;

export const StyledInput = styled('input')<{ type: string }>`
  appearance: none;
  background-color: white;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border: 2px solid ${colors.grayChateau};
  border-radius: ${({ type }) => (type === 'radio' ? '50%' : '2px%')};
  margin-right: 8px;

  cursor: pointer;

  display: grid;
  place-content: center;

  &:checked {
    background-color: ${colors.darkBlue};
    border-color: ${colors.darkBlue};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.grayChateau};
    background-color: ${colors.grayChateau};
    border-color: currentColor;
  }

  &:focus {
    outline: 0;
  }

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    transform: scale(0);
    box-shadow: inset 16px 16px ${colors.silver};
    transition: 120ms transform ease-in-out;

    transform-origin: bottom left;
    clip-path: polygon(35% 65%, 90% 10%, 100% 25%, 35% 90%, 0% 50%, 14% 40%);
  }

  &:checked::before {
    transform: scale(1);
  }

  &:checked:disabled::before {
    box-shadow: inset 16px 16px white;
  }
`;
