import React from 'react';
import { Availability } from 'types';
import { StatusIconContainer, StatusFrameContainer, StatusDescription } from './styles';

interface Props {
  status: Availability;
}

const StatusItem: React.FC<Props> = ({ status }) => {
  const iconClasses = {
    Active: 'green circle',
    Admin: 'yellow triangle',
    Unavailable: 'red square',
  };

  const statusDisplayNames = {
    Active: 'Available',
    Admin: 'Admin',
    Unavailable: 'Unavailable',
  };

  return (
    <StatusFrameContainer>
      <StatusIconContainer>
        <span className={iconClasses[status]} />
      </StatusIconContainer>
      <StatusDescription>{statusDisplayNames[status]}</StatusDescription>
    </StatusFrameContainer>
  );
};

export default StatusItem;
