// See ADR # 9 for reference

import { CSSProperties } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { WIPState } from './types-wip';
import { OrderState } from './src/redux/order/reducer';
import { LensReplacementState } from './src/redux/lens-replacement/types';
import { ProductCategory } from './src/utils/constants';

export const FETCH_JWT_SUCCESS = 'FETCH_JWT_SUCCESS';
export const FETCH_REQUEST_FAILURE = 'FETCH_REQUEST_FAILURE';
export const UPDATE_API_STATE = 'UPDATE_API_STATE';
export const CLEAR_API_STATE = 'CLEAR_API_STATE';
export const REMOVE_API_STATE = 'REMOVE_API_STATE';

export const RETURN_ORDER_TYPE_IDS = {
  exchange: 5,
  lens_replacement: 6,
  frame_replacement: 7,
  return: 8,
};

// Redux Store
export type ReduxState = {
  api: APIState,
  auth: AuthState,
  errors: ErrorState,
  apiWIP: WIPState,
  order: OrderState,
  lensReplacement: LensReplacementState,
};

// Redux Auth
export interface AuthState {
  jwt: string;
}

export type AuthAction = string;

// Redux API
export type APIState = {
  [path: string]: APIResult
};

export interface APIResult<ResponseType = unknown> {
  // We use 'unknown' here to force the caller to determine the type of the API response.
  body: ResponseType | null;
  error: boolean | null;
}

export interface APIAction extends APIResult {
  url?: string;
  path?: string;
}

export interface APIDefaultAction {
  body: null;
  error: null;
  path: null;
}

export interface APIRemoveAction {
  path: string;
}

// Redux Error
export interface ErrorState {
  info: null | string;
}

export type ErrorAction = string;

// Redux Actions
export interface Actions {
  type: string;
  payload?: AuthAction | APIAction | ErrorAction | APIDefaultAction | APIRemoveAction;
}

// Thunk
export type Dispatch = ThunkDispatch<any, any, any>;

// All orders in a collective array
interface ItemStatus {
  description: string;
  key: string;
  id: number;
  name: string;
}

export type OrderStatus = ItemStatus;

export interface SalesOrderDetail {
  display_name: string;
  id: number;
  is_takeaway: boolean;
  item_status: ItemStatus[];
  item_total: number;
  post_tax_adjustment_amount: number;
  tax_amount : number;
}

export interface CreditMemoItem {
  display_name: string;
  pc_product_id: string;
  work_order_item_id: string;
  sales_order_item_id: string;
  received_date: string;
}

interface CreditMemoTransactionPayment {
  payment_type: string;
  last4: string;
  transaction_amount: number;
  exp_year: number;
  card_type: string;
  credit_memo_id: string;
  gift_card_code: string;
  charge_id: string;
}

export interface CreditMemoTransaction {
  transaction_id: string;
  transaction_type: string;
  transaction_amount: number;
  payment_info: CreditMemoTransactionPayment;
}

export enum InteractionType {
  SalesOrder = 'sales-order',
  CreditMemo = 'credit-memo',
}

export interface AllocatedRefundPayment {
  id: string;
  amount: number;
}

export interface RefundPayment {
  amount: number;
  payment: AllocatedRefundPayment
  sales_order_payment: Payment;
}

export interface Station {
  facility_id: number;
  station_id: number;
}

export interface Till {
  station_id: number;
  till_id: number;
}

interface SalesOrderInteraction {
  interactionType: InteractionType.SalesOrder;
  date: string;
  salesOrder: SalesOrder;
}

interface CreditMemoInteraction {
  interactionType: InteractionType.CreditMemo;
  date: string;
  creditMemo: CreditMemo;
}

export type Interaction = SalesOrderInteraction | CreditMemoInteraction;

export interface SalesOrder {
  customer_id: number;
  id: number;
  items: SalesOrderDetail[];
  locale: string;
  order_status: OrderStatus[];
  order_total: number;
  placed: string;
}

export interface CreditMemo {
  created: string;
  id: string;
  sales_order_id: string;
  customer_id: string;
  sales_order_return_id: string;
  amount: number;
  initial_amount: number;
  items: CreditMemoItem[];
  detailed_reason_id: number;
  sales_order_return_reason: number;
  transactions?: CreditMemoTransaction[];
}

export interface InvoiceItem {
  invoice_id: string;
  invoice_item_id: string;
  returnable: boolean;
  quantity: number;
  authorized_return_quantity: number;
  received_return_quantity: number;
}

// Single order, formatted differently than SalesOrder/SalesOrderDetail
export interface LensFeatureMap {
  [key: string]: string;
}

export interface PimItem {
  color: string;
  image: string;
  lens_feature_map: LensFeatureMap
  pc_product_id: number;
  primary_product_category_id: string;
  product_name: string;
  upc: string;
}

interface Content {
  content_name?: string;
  content_type_id: string;
  data_resource_name?: string;
  data_resource_type_id: string;
  product_content_type_id: string;
  text_data?: string;
}
export interface Category {
  id: string;
  name: string;
  primary_parent_id: string;
  type_id: string;
  detail_screen: string;
}
interface GoodID {
  type_id: string;
  id_value: string;
}

interface Feature {
  abbrev: string;
  data_resources: Record<string, string>;
  description: string;
  group_ids: string[];
  id_code: string;
  type_id: string;
  uom_id: string;
}

export interface DetailedProduct {
  brand_name?: string;
  categories: Category[];
  content: Content[];
  description: string;
  good_ids: GoodID[];
  features: Feature[];
  pc_product_id: number;
  primary_product_category_id: string;
  product_name: string;
  virtual_product_name?: string;
}

export interface ConfigProduct {
  configured_pc_product_id: number;
  pc_product_id: number;
  primary_product_category_id: PrimaryProductCategoryId;
  category_ids: string[];
}

type PrimaryProductCategoryId = ProductCategory.Lens | ProductCategory.FrameAssembly;

export type Availability = 'Active' | 'Admin' | 'Unavailable';

export interface CountryAvailability {
  [key: string]: Availability;
}

export interface ItemDetails {
  itemId: number;
  ocHeight: number;
  segHeightOD: number;
  segHeightOS: number;
  antiFatiguePower: string;
}

export interface OrderItem {
  id: string;
  total: number;
  tax_amount: number;
  discount_amount: number;
  quantity: number;
  invoices: InvoiceItem[];
  pc_product_id: string;
  high_index_consent_at: null | Date;
}

interface OrderAdjustment {
  amount: number;
  created: ISO8601DateTime;
  description: string;
  display_created: boolean;
}

export interface OrderGesture {
  category: string;
  name: string;
  note: null | string;
}

export interface Order {
  adjustments: OrderAdjustment[];
  adjustment_total: number;
  adjusted_total: number;
  cancelable: boolean;
  canceled: ISO8601DateTime;
  country: string;
  currency: null | string;
  customer_email: string;
  customer_id: number;
  customer_name: string;
  id: number;
  items: OrderItem[];
  placed: ISO8601DateTime;
  post_tax_adjustment_total: number;
  service_gesture_available: boolean;
  shipping_total: number;
  subtotal: number;
  tax_total: number;
  service_gestures: OrderGesture[];
}

// Interfaces to cancel an order
export interface CancelReason {
  key: string;
  id: number;
  notes_required: boolean;
  type: string;
}

interface CancelStatus extends Status {
  has_active_invoices: boolean;
}

export type CancelPayment = Payment;

export type CancelCheck = Check;

export interface CancelRequestSummary {
  checks: CancelCheck[];
  payments: CancelPayment[];
  status: CancelStatus;
}

// Interfaces for service gestures on an order
export interface ServiceGesture {
  adjustment_type: string;
  adjustment_value: number;
  category: string;
  detailed_reason_id: number;
  name: string;
  notes_required: boolean;
  reason_id: number;
}

export type ServiceGesturePreview = {
  payments: ServiceGesturePayment[];
};

type ServiceGestureStatus = Status;

export type ServiceGesturePayment = Payment;

type ServiceGestureCheck = Check;

export interface ServiceGestureRequestSummary {
  checks: ServiceGestureCheck[];
  payments: ServiceGesturePayment[];
  status: ServiceGestureStatus;
}

export interface ReturnReason {
  code: number;
  description: string;
  id: number;
  name: string;
  notes_required: boolean;
  valid_thru: number;
  visible: boolean;
  reason_type?: ReturnReasonType;
}

export interface ReturnReasonType {
  description: string;
  id: number;
  key: string;
  name: string;
  reasons?: ReturnReason[];
}

export interface ReturnSummary {
  error: any;
  centvia_url: string;
  credit_memo_id: null | string;
  credit_memo_value: null | number;
  desktop_receipt_string: string;
  estimate_id: null | string;
  id: number;
  sent_to_centvia: boolean;
}

export type ReturnParams = {
  jwt: string;
  facility: MeFacility;
  customerId: string;
  orderId: string;
};

export type PaymentPreview = {
  type: string;
  amount_cents: number;
  // attributes if credit card
  cc_expires_month?: number;
  cc_expires_year?: number;
  cc_last_four?: string;
  cc_type?: string;
  // attributes if check
  check_number?: string;
  check_date?: ISO8601DateTime;
  // attributes if gift card
  code?: string;
};

export type InsuranceBreakdown = {
  benefit_amount: number;
  copay_amount: number;
};

export type FancyEnum = {
  description: string;
  id: number;
  key: string;
  name: string;
};

export type Eye = {
  add: number | null;
  axis: number | null;
  cylinder: number | null;
  cyl?: number | null;
  sph?: number | null;
  prisms: string[][];
  sphere: number | null;
};

export type PDType = {
  bi: number | null;
  binocular: number | null;
  od: number | null;
  os: number | null;
};

export type SalesOrderPrescription = {
  isMultiFocal: boolean;
  title: string;
  created: number;
  country_code: string | null;
  deleted: number | null;
  exam_date: number | null;
  expiration_date: number | string | null;
  od: Eye;
  od_contact_lens_base_curve: string;
  od_contact_lens_color: string | null;
  od_contact_lens_diameter: string;
  od_contact_lens_product_identifier: string | null;
  os: Eye;
  os_contact_lens_base_curve: string;
  os_contact_lens_color: string | null;
  os_contact_lens_diameter: string;
  os_contact_lens_product_identifier: string | null;
  pd: PDType;
  prescription_id: number;
  prescription_usage_intention: string | null;
  region_code: string | null;
  request_id: string | null; // number
  updated: number | null;
  upload_blob_id: number | null;
  _admin_verified: boolean;
  _customer_id: string;
  _customer_supplied_name: string;
  _entry_origin_id: string;
  _has_prism: boolean;
  _lens_preference: FancyEnum;
  _optician_verified: boolean;
  _origin_id: string | null;
  _prescription_usage_type: FancyEnum;
  _prism_approved: boolean;
  _status: string;
  _use_high_index: boolean;
};

export type RxDetailsData = {
  created: ISO8601DateTime;
  expiration: ISO8601DateTime;
  od: Eye;
  os: Eye;
  isMultiFocal: boolean;
  forContacts: boolean;
  odContactDiameter: string;
  odContactBaseCurve: string;
  odContactColor: string;
  osContactDiameter: string;
  osContactBaseCurve: string;
  osContactColor: string;
  pd: PDType;
  hasPrism: boolean;
};

export type RxDetails = {
  data: RxDetailsData | null;
  name: string;
  prescriptionId: number | null;
  requestId: number | null;
};

// Types included in response from POST request
interface Status {
  error: null | string;
  id: string;
  success: boolean;
  total_credit_memo_initial_amount: number;
}

export interface Payment {
  amount_refunded: number;
  capture_amount: number;
  card_type: null | string;
  code: null | string;
  credit_card_token_id: null | string;
  exp_month: null | string;
  exp_year: null | string;
  is_cash: boolean;
  is_check: boolean;
  is_credit_card: boolean;
  is_credit_memo: boolean;
  is_gift_card: boolean;
  last4: null | string;
  payment_id: string;
  payment_type: string;
  sop_id: string;
}

interface Check {
  amount: number;
  company: string;
  country_code: string;
  created: ISO8601DateTime;
  credit_memo_id: string;
  currency: string;
  customer_address_id: string;
  extended_address: string;
  fax: string;
  first_name: string;
  id: string;
  last_name: string;
  locality: string;
  middle_name: string;
  payable_to: string;
  postal_code: string;
  region: string;
  street_address: string;
  telephone: string;
}

export interface APIParsedSummary {
  success: boolean;
  body: any;
}

export interface CSSStyle {
  [prop: string]: CSSProperties;
}

export type ISO8601DateTime = string;

export interface Params {
  [key: string]: string;
}

// Types for /api/v1/user/me endpoint
type MeCatalog = {
  url: string;
};

type MeEnvironment = {
  is_training: boolean;
};

interface MeFacilityType {
  id: number;
  key: string;
  name: string;
  description: string;
}

export interface MeFacility {
  active: boolean;
  created: ISO8601DateTime;
  currency: string;
  email: string;
  facility_id: number;
  id: number;
  locale: string;
  name: string;
  short_name: string;
  type: MeFacilityType;
}

type MeFeatures = string[];

type MePermissions = string[];

type MeRetailStores = MeFacility[];

interface MeUser {
  id: number;
  name: string;
  username: string;
}

export interface MeData {
  catalog: MeCatalog;
  environment: MeEnvironment;
  facility: MeFacility;
  features: MeFeatures;
  permissions: MePermissions;
  retail_stores: MeRetailStores;
  user: MeUser;
}

export interface AntiFatigueAddPower {
  id: number;
  /**
   * Ex. "plus_50"
   */
  key: string;
  /**
   * Ex. "+0.50"
   */
  name: string;
  /**
   * Ex. "Low"
   */
  description: string;
}

/**
 * Available Anti-Fatigue add power options
 */
export interface AntiFatigueAddPowerOptions {
  options: AntiFatigueAddPower[];
}
