/* eslint-disable import/prefer-default-export */
import React from 'react';
import { formatNumberToEyeWearOption } from 'src/utils/formatNumberToEyeWearOption';
import { ReadersOption } from './readers-option';
import { TabContainer } from './styles';

type Props = {
  originalOption?: string;
  selectedStrength?: string;
  onReadersStrengthOptionClick: (option: string) => void;
};

const READER_OPTIONS = [
  ...(new Array<number>(11)
    .fill(1)
    .map((num, idx) => formatNumberToEyeWearOption((num + idx) * 0.25))),
];

const ReadersTab = ({ originalOption, onReadersStrengthOptionClick, selectedStrength }: Props) => (
    <TabContainer>
      <p>
        Select a reader strength
      </p>
      <div>
        {READER_OPTIONS.map(readerOption => (
          <ReadersOption
            key={readerOption}
            hasPreviouslyUsed={originalOption === readerOption}
            onChangeValue={onReadersStrengthOptionClick}
            selectedOption={selectedStrength}
            value={readerOption} />
        ))}
      </div>
    </TabContainer>
);

export { ReadersTab };
