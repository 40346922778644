/* eslint-disable import/no-cycle */
import createContext from 'src/utils/createContext';
import { LRData, LRStepperContextData } from './types';

export const [LRStepperContextProvider, useStepperContext] = createContext<
LRStepperContextData<LRData>
>(
  null,
  'LRStepper',
);
