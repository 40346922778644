import React from 'react';
import styled from 'react-emotion';
import { DateTime } from 'luxon';
import { pdToString } from 'src/utils/pd';
import moment from 'moment';
import { styles } from '../../../style';
import { ISO8601DateTime, PDType, RxDetails } from '../../../types';
import { RetailOrderItemAttributes } from '../../redux/order/types';

const configureShortDate = (isoDate: ISO8601DateTime): string => moment(isoDate).format('MM/DD/YYYY');

const Label = styled('div')`
  font-size: 12px;
  font-weight: 100;
  color: #676f78;
  min-width: 40px;
`;

const CellTitle = Label.withComponent('th');

const Value = styled('div')`
  font-size: 18px;
  font-weight: 600;
  color: #525e69;
`;

const CellValue = styled(Value)`
  padding-right: 10px;
`.withComponent('td');

const Pill = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border: 1px solid #e4e6e8;
  border-radius: 8px;
  margin: 0 auto 15px;
  padding: 24px 30px;
  text-align: left;
  text-decoration: none;
`;

const RxHeader = styled('div')`
  display: flex;
  flex-direction: column;
`;

const PrescriptionName = styled('span')`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
`;

const Table = styled('table')`
  align-self: flex-start;
`;

const SpaceBetweenBlock = styled('div')`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    text-align: left;
  }

  > div:last-child {
    text-align: right;
  }
`;

export const getRxName = (
  attributes: RetailOrderItemAttributes,
  requestId: number | null,
  customerFullName = '',
  customerSuppliedName = '',
): string => {
  const prescriptionsFirstName = customerSuppliedName
    ? (customerSuppliedName.substring(0, customerFullName.indexOf(' ')))
    : '';
  const rxNameText = attributes?.frame?.readers_strength
    ? `${prescriptionsFirstName} Readers (${attributes.frame.readers_strength})`
    : customerSuppliedName;

  if (requestId) {
    return `${prescriptionsFirstName} Prescription Request`;
  }

  if (!requestId && !rxNameText) {
    return 'Non-Rx';
  }

  return rxNameText;
};

interface RxSummaryProps {
  rxDetails: RxDetails;
}

const RxSummary = ({ rxDetails }: RxSummaryProps) => {
  const details = rxDetails?.data;
  const name = rxDetails?.name;

  const isMultiFocal = details?.isMultiFocal;
  const od = details?.od;
  const os = details?.os;
  const created = details?.created;
  const expiration = details?.expiration;
  const forContacts = details?.forContacts;
  const odContactBaseCurve = details?.odContactBaseCurve;
  const odContactDiameter = details?.odContactDiameter;
  const odContactColor = details?.odContactColor;
  const osContactBaseCurve = details?.osContactBaseCurve;
  const osContactDiameter = details?.osContactDiameter;
  const osContactColor = details?.osContactColor;
  const pd = details?.pd;
  const hasPrism = details?.hasPrism;

  const pdString = pd ? pdToString(pd) : '';

  return (
    <Pill>
      <RxHeader>
        <div style={styles.microPara}>Rx Name</div>
        <PrescriptionName>{name || 'Loading...'}</PrescriptionName>
      </RxHeader>
      {!!details && (
        <Table>
          <tbody>
            <tr>
              <th>&nbsp;</th>
              <CellTitle>SPH</CellTitle>
              <CellTitle>CYL</CellTitle>
              <CellTitle>AXIS</CellTitle>
              {isMultiFocal && <CellTitle>ADD</CellTitle>}
              {forContacts && <CellTitle>BC</CellTitle>}
              {forContacts && <CellTitle>DIA</CellTitle>}
              {forContacts && <CellTitle>CLR</CellTitle>}
            </tr>
            <tr>
              <td>
                <Label>OD</Label>
              </td>
              <CellValue key="od-sph">{od.sphere}</CellValue>
              <CellValue key="od-cyl">{od.cylinder}</CellValue>
              <CellValue key="od-axis">{od.axis}</CellValue>
              {isMultiFocal && <CellValue key="od-add">{od.add}</CellValue>}
              {forContacts && <CellValue key="od-base-curve">{odContactBaseCurve}</CellValue>}
              {forContacts && <CellValue key="od-diameter">{odContactDiameter}</CellValue>}
              {forContacts && <CellValue key="od-color">{odContactColor || '-'}</CellValue>}
            </tr>
            <tr>
              <td>
                <Label>OS</Label>
              </td>
              <CellValue key="os-sph">{os.sphere}</CellValue>
              <CellValue key="os-cyl">{os.cylinder}</CellValue>
              <CellValue key="os-axis">{os.axis}</CellValue>
              {isMultiFocal && <CellValue key="os-add">{os.add}</CellValue>}
              {forContacts && <CellValue key="os-base-curve">{osContactBaseCurve}</CellValue>}
              {forContacts && <CellValue key="os-diameter">{osContactDiameter}</CellValue>}
              {forContacts && <CellValue key="os-color">{osContactColor || '-'}</CellValue>}
            </tr>
          </tbody>
        </Table>
      )}
      {(pdString || hasPrism) && (
        <SpaceBetweenBlock>
          <div>
            {pdString && (
              <>
                <Label>PD</Label>
                <Value>{pdString}</Value>
              </>
            )}
          </div>
          <div>{hasPrism && <Value>Has prism</Value>}</div>
        </SpaceBetweenBlock>
      )}
      {!!details && (
        <SpaceBetweenBlock>
          <div>
            <Label>Created on</Label>
            <Value>{configureShortDate(created)}</Value>
          </div>
          <div>
            <Label>Expiration date</Label>
            <Value>{configureShortDate(expiration)}</Value>
          </div>
        </SpaceBetweenBlock>
      )}
    </Pill>
  );
};

export default RxSummary;
