import styled from 'react-emotion';
import colors from 'src/utils/colors';

export const TabTitle = styled('p')`
  text-align: center;
  font-size: 1.5rem;
  margin-top: 3rem;
  color: ${colors.stone};
`;

export const TabSubtitle = styled('p')`
  text-align: center;
  font-size: 18px;
  color: ${colors.tundora};
`;
