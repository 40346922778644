import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  formatPrice, apiLoading, apiStatus, apiResponse, getOrder,
} from 'selectors';
import {
  ReduxState, ServiceGesture, Order, Dispatch, Params,
} from 'types';
import { actions } from 'src/redux/actions';
import { styles } from 'style';

import Nav from 'src/shared-components/screens/Nav';
import SelectionPill from 'src/shared-components/order-actions/SelectionPill';
import Loading from 'src/shared-components/screens/Loading';

interface FormattedServiceGesture {
  id: number;
  category: string;
}

const presetsPathname = (): string => '/v1/sales-order/service-gesture-presets';

const formatPresets = (presets: ServiceGesture[]): FormattedServiceGesture[] => {
  const categories = presets.map((preset) => ({ id: preset.reason_id, category: preset.category }));

  // categories is an array of duplicate objects
  return categories.filter((category, idx, self) => self.findIndex(
    (t) => (t.id === category.id && t.category === category.category)) === idx);
};

const displayCategories = (categories: FormattedServiceGesture[], orderId: string): JSX.Element[] => categories.map((category) => {
  const href = `/order/${orderId}/service-gesture/${category.id}`;
  return <SelectionPill data-cy={`order-${orderId}-sg-${category.id}`} key={category.id} href={href} text={category.category} />;
});

interface ServiceGesturePresetProps {
  dispatch: Dispatch;
  hasRequestedPresetsData: boolean;
  jwt: string;
  order: Order;
  params: Params;
  presets: FormattedServiceGesture[];
}

const ServiceGesturePreset: React.StatelessComponent<ServiceGesturePresetProps> = ({
  jwt, dispatch, hasRequestedPresetsData, presets, order, params,
}) => {
  const orderId = params.order;

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);

  useEffect(() => {
    if (!hasRequestedPresetsData) {
      const presetsPath = presetsPathname();
      dispatch(actions.fetchOhmAPI(presetsPath, jwt));
    }
  }, []);

  if (!order) {
    return <Loading />;
  }

  const total = order ? `Order Total - ${formatPrice(order.adjusted_total)}` : 'Order Total';
  const presetCategories = presets && displayCategories(presets, orderId);

  return (
    <div className="page">
      <div className="content">
        <Nav
          href={`/customer/${order.customer_id}/order/${orderId}`}
          text={total}
          subtext=""
        />
        {!presets ? <Loading /> : (
          <>
            <p style={styles.largeBoldPara}>Select preset for customer courtesy</p>
            {presetCategories}
          </>
        )}
      </div>
    </div>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);

  const presetsPath = presetsPathname();
  const presetsStatus = apiStatus<ServiceGesture[]>(state, presetsPath);
  const presetsResponse = apiResponse(presetsStatus);
  const presets = presetsResponse && formatPresets(presetsResponse);

  return ({
    jwt: state.auth.jwt,
    order,
    hasRequestedPresetsData: apiLoading(presetsStatus),
    presets,
  });
};

export default connect(select)(ServiceGesturePreset);
