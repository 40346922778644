import React, { forwardRef } from 'react';
import { PopoverContainerRef, PopoverModal } from '../../../shared-components/popover-modal';
import { browserHistory } from '../../../../browserHistory';

interface Props {
  orderId: number;
  customerId: number;
  onBookEyeExam: () => void;
  itemsEligibleForFreeLR: boolean;
  dismissPopover: () => void;
  isReaderOrder: boolean;
}

const BookEyeExamPopover = forwardRef<PopoverContainerRef, Props>((props, ref) => {
  const { orderId, onBookEyeExam, customerId, itemsEligibleForFreeLR, isReaderOrder, dismissPopover } = props;
  const lensReplacementUrl = `/customer/${customerId}/order/${orderId}/lens-replacement`;

  const onLensReplacement = () => {
    browserHistory.push(lensReplacementUrl);
  };

  return (
    <PopoverModal
      ref={ref}
      title='Before we can continue'
      description='You need an up-to-date prescription to move forward with this lens replacement, but your prescription has expired. Would you like to book an eye exam to complete this order?'
      confirmLabel='Book an exam'
      cancelLabel="No Thanks"
      onCancel={itemsEligibleForFreeLR || isReaderOrder ? onLensReplacement : dismissPopover}
      onConfirm={onBookEyeExam}
    />
  );
});

export default BookEyeExamPopover;
