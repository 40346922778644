/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { badge, Variants } from './styles';

interface Props extends React.ComponentProps<'span'> {
  variant?: Variants;
  children: React.ReactNode
}

export const Badge: React.FC<Props> = ({
  className,
  variant,
  children,
  ...props
}: Props) => {
  const classNames = [className, badge(variant)].filter(Boolean).join(' ');

  return (
    <span {...props} className={classNames}>
      {children}
    </span>
  );
};

export default Badge;
