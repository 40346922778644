import { ReturnReason, ReturnReasonType } from 'types';

const convertReasonTypes = (data:ReturnReason[]): ReturnReasonType[] => {
  if (!data) {
    return undefined;
  }

  const reasonTypeMap = data.reduce((acc, item) => {
    const reasonTypeId = item.reason_type.id;

    if (!acc.has(reasonTypeId)) {
      acc.set(reasonTypeId, {
        id: reasonTypeId,
        key: item.reason_type.key,
        name: item.reason_type.name,
        description: item.reason_type.description,
        valid_thru: item.valid_thru,
        reasons: [],
      });
    }

    const reason  = {
      id: item.id,
      code: item.code,
      name: item.name,
      description: item.description,
      note_required: item.notes_required,
      visible: item.visible,
      valid_thru: item.valid_thru,
    };

    acc.get(reasonTypeId).reasons.push(reason);

    return acc;
  }, new Map());

  return Array.from(reasonTypeMap.values());
};

export default convertReasonTypes; 
