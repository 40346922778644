import React, { useRef, useState } from 'react';
import { styles } from 'style';
import { CheckboxField } from 'src/shared-components/checkbox';
import { PopoverContainerRef, PopoverModal } from 'src/shared-components/popover-modal';
import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { ConsentNotes, Content, Header, StyledActionsContainer } from './styles';
import { CONSENT_REGARDS } from './constants';
import { useLRStepper } from '../../lr-stepper';
import LargeButton from '../../large-button';

const CustomerConsentStep: React.FC = () => {
  const { updateData, nextStep } = useLRStepper('CustomerConsentStep');

  const consentPopoverRef = useRef<PopoverContainerRef>(null);

  const [isConsentGiven, setIsConsentGiven] = useState(false);

  const handleConsentAgreement = useCallbackRef(() => {
    updateData({ isConsentGiven });
  });

  const toggleConsent = useCallbackRef(() => {
    setIsConsentGiven(oldState => !oldState);
  });


  const handleContinue = useCallbackRef(() => {

    if (!consentPopoverRef.current) return;

    consentPopoverRef.current.openModal();
  });

  const handleDismiss = useCallbackRef(() => {
    if (!consentPopoverRef.current) return;

    consentPopoverRef.current.closeModal();
  });

  const handleContinueToStep = useCallbackRef(() => {
    if (!consentPopoverRef.current) return;

    handleConsentAgreement();
    consentPopoverRef.current.closeModal();
    nextStep();
  });

  return (
    <>
      <div className='page' style={styles.page}>
        <Content>
          <Header> Frame release acknowledgement (for unlikely-but-possible scenarios)</Header>
          <ConsentNotes>
            <ul>
              {CONSENT_REGARDS.map(consentRegard => (
                <li key={consentRegard}>
                  {consentRegard}
                </li>
              ))}
            </ul>
          </ConsentNotes>

          <StyledActionsContainer>
            <CheckboxField
              label='I acknowledge that I have reviewed and understood the information provided.'
              handleCheck={() => toggleConsent()}
              isChecked={isConsentGiven}
            />

            <LargeButton
              style={{ width: '100%', marginTop: '2rem' }}
              onClick={handleContinue}
              disabled={!isConsentGiven}
              id='large-button'
            >
              Continue
            </LargeButton>
          </StyledActionsContainer>
        </Content>
      </div>

      <PopoverModal
        ref={consentPopoverRef}
        title='Thank you!'
        description='Please return this device to a Warby Parker team member.'
        cancelLabel='Dismiss'
        confirmLabel='Continue'
        onCancel={handleDismiss}
        onConfirm={handleContinueToStep}
      />
    </>
  );
};

export default CustomerConsentStep;
