import React from 'react';
import { replaceItemButton } from './styles';

interface Props {
  itemsCount: number;
  onClick: () => void;
}

export const ReplaceItemsButton: React.FC<Props> = (props: Props) => {
  const { itemsCount, onClick } = props;
  const isDisabled: boolean = itemsCount <= 0;
  const buttonText: string = itemsCount === 1
    ? `Replace ${itemsCount} item`
    : `Replace ${itemsCount} items`;

  return (
    <button
      type="button"
      className={replaceItemButton}
      disabled={isDisabled}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

export default ReplaceItemsButton;
