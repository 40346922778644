import { PimItem, ItemDetails, AntiFatigueAddPower, CSSStyle, CountryAvailability, DetailedProduct } from 'types';
import { LensCategoryOptions } from 'src/utils/constants';
import { LensCategoriesMap } from 'src/redux/order/types';
import {
  OriginalSOProductDetail,
  SalesOrderLensReplacementDetail,
} from '../../types/lens-replacement';

export const style: CSSStyle = {
  boldPara: {
    color: '#414B56',
    flex: 1,
    fontSize: '18px',
    fontWeight: 600,
    margin: '0',
    padding: '30px 0 30px 25px',
  },
  border: {
    borderLeft: '1px solid #E4E6E8',
  },
  bottomPadding: {
    paddingBottom: '24px',
  },
  chinWrapper: {
    display: 'flex',
    margin: '20px 0',
    textAlign: 'center',
    padding: '0 30px',
  },
  eyebrow: {
    backgroundColor: '#FBFBFB',
    borderBottom: '1px solid #E4E6E8',
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    padding: '15px',
  },
  flex: {
    flex: 1,
  },
  greenPara: {
    color: '#00BF2D',
  },
  itemPill: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    margin: '0 auto 15px',
    textAlign: 'left',
  },
  subPara: {
    color: '#676F78',
    fontSize: '14px',
    margin: '0',
    paddingBottom: '5px',
  },
  topPadding: {
    paddingTop: '10px',
  },
  heightWrapper: {
    borderTop: '1px solid #E4E6E8',
    display: 'flex',
    marginTop: '35px',
    width: '100%',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    padding: '54px 30px 10px',
  },
  alignEnd: {
    margin: 'auto',
    marginRight: '0px',
  },
  alignBadge: {
    position: 'absolute',
    top: '6vh',
    right: '6vw',
  },
};

export interface PimProductCardProps {
  afAddPowers?: AntiFatigueAddPower[];
  total: number;
  pimItem: PimItem;
  itemDetails?: ItemDetails;
  returnReason?: string;
  rxName: string;
  availability?: CountryAvailability;
  canLensReplacement: boolean | undefined;
  isBacksideARSupported: boolean | undefined;
  lensCategories: LensCategoriesMap;
  lensReplacementDetails: SalesOrderLensReplacementDetail[];
  originalSalesOrderProductDetails: OriginalSOProductDetail[];
  lensReplacementSalesOrderItems: DetailedProduct[]
}

export interface OrderItermComponentProps extends PimProductCardProps {
  tagLegend: string;
  isChecked: boolean;
  onChangeCheckbox: React.ChangeEventHandler<HTMLInputElement>;
  badgeStatus: 'success' | 'secondary' | 'error';
}

export const defaultImage =
  'https://i.warbycdn.com/v/c/assets/poe-product-search/image/placeholder-optical/1/0a2edd241f.png';

export const basePimItem = {
  pc_product_id: 1017252,
  primary_product_category_id: 'FRAME_CONFIG',
  upc: '846864048136',
  image: 'https://i.warbycdn.com/s/c/69d98d31efc4e17b11891cabbf9de17e06d079f9',
  product_name: 'Abner Wide',
  color: 'Parchment',
  lens_feature_map: {
    AR_COATING: 'super_hydrophobic',
    LENS_COLOR: 'Clear',
    POLARIZATION: 'non_polarized',
    REFRACTIVE_INDEX: 'regular',
  },
};

export const defaultArgs = {
  total: 9500,
  itemDetails: {
    itemId: 41,
    ocHeight: null,
    segHeightOD: null,
    segHeightOS: null,
    antiFatiguePower: null,
  },
  afAddPowers: [
    {
      id: 1,
      key: 'n_a',
      name: 'N/A',
      description: 'N/A',
    },
    {
      id: 2,
      key: 'plus_50',
      name: '+0.50',
      description: 'Low',
    },
    {
      id: 3,
      key: 'plus_75',
      name: '+0.75',
      description: 'Mid',
    },
  ],
  pimItem: basePimItem,
};
