import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  formatPrice, apiLoading, apiStatus, apiResponse, getOrder,
} from 'selectors';
import { actions } from 'src/redux/actions';
import {
  ReduxState, ServiceGesture, Order, Dispatch, Params,
} from 'types';

import SelectionPill from 'src/shared-components/order-actions/SelectionPill';
import Loading from 'src/shared-components/screens/Loading';
import ServiceGesturePresetReason from './ServiceGesturePresetReason';

const presetsPathname = (): string => '/v1/sales-order/service-gesture-presets';

// filter out the service gesture presets that only match the chosen preset reason (from route)
const formatPreset = (presets: ServiceGesture[], presetId: string): ServiceGesture[] => presets.filter((preset) => preset.reason_id === Number(presetId));

const formatPresetReasons = (presets: ServiceGesture[], orderId: string, presetId: string): JSX.Element[] => presets.map((preset) => {
  const { detailed_reason_id, name } = preset;
  const href = `/order/${orderId}/service-gesture/${presetId}/reason/${preset.detailed_reason_id}/confirmation`;
  return <SelectionPill data-cy={`order-${orderId}-sg-${presetId}-reason-${preset.detailed_reason_id}`} key={detailed_reason_id} href={href} text={name} />;
});

interface ServiceGesturePresetReasonContainerProps {
  dispatch: Dispatch;
  hasRequestedPresetData: boolean;
  jwt: string;
  order: Order;
  params: Params;
  presets: ServiceGesture[];
}

const ServiceGesturePresetReasonContainer: React.StatelessComponent<ServiceGesturePresetReasonContainerProps> = ({
  jwt, dispatch, order, hasRequestedPresetData, presets, params,
}) => {
  const orderId = params.order;

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);

  useEffect(() => {
    if (!hasRequestedPresetData) {
      const presetsPath = presetsPathname();
      dispatch(actions.fetchOhmAPI(presetsPath, jwt));
    }
  }, []);

  if (!order || !presets) {
    return <Loading />;
  }

  const total = order ? `Order Total - ${formatPrice(order.adjusted_total)}` : 'Order Total';
  const presetId = params['service-gesture'];
  const presetCategory = presets[0].category;
  const presetReasons = formatPresetReasons(presets, orderId, presetId);
  const href = `/order/${orderId}/service-gesture`;

  return (
    <ServiceGesturePresetReason
      href={href}
      preset={presetCategory}
      reasons={presetReasons}
      total={total}
    />
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);
  const presetId = params && params['service-gesture'];
  const presetsPath = presetsPathname();
  const presetsStatus = apiStatus<ServiceGesture[]>(state, presetsPath);
  const presetsResponse = apiResponse(presetsStatus);
  const presets = presetsResponse && formatPreset(presetsResponse, presetId);

  return ({
    jwt: state.auth.jwt,
    order,
    hasRequestedPresetData: apiLoading(presetsStatus),
    presets,
  });
};

export default connect(select)(ServiceGesturePresetReasonContainer);
