import * as React from 'react';

import { CSSStyle } from 'types';

import closeIcon from 'src/shared-components/icons/close.svg';
import checkmark from 'src/shared-components/icons/checkmark.svg';
import Link from 'src/components/app/Link';

const style: CSSStyle = {
  background: {
    alignItems: 'center',
    background: 'rgba(0, 36, 84, .8)',
    bottom: '0',
    display: 'flex',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: 10,
  },
  content: {
    background: 'white',
    borderRadius: '8px',
    left: '0',
    margin: '15% auto',
    maxWidth: '430px',
    padding: '60px 65px 20px',
    position: 'fixed',
    right: '0',
    textAlign: 'center',
    top: '0',
  },
  close: {
    backgroundImage: `url(${closeIcon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    cursor: 'pointer',
    display: 'block',
    height: '15px',
    left: '20px',
    position: 'absolute',
    top: '30px',
    width: '15px',
  },
  confirmationHeader: {
    fontSize: '24px',
    fontWeight: 700,
    overflowWrap: 'break-word',
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    overflowWrap: 'break-word',
  },
  subtext: {
    color: '#676F78',
    fontSize: '16px',
    lineHeight: '150%',
    paddingBottom: '25px',
  },
  button: {
    background: '#00A2E1',
    borderRadius: '0 0 8px 8px',
    color: 'white',
    fontWeight: 600,
    left: '0',
    marginBottom: '0',
    outline: '0',
    padding: '30px',
    position: 'absolute',
    right: '0',
    textDecoration: 'none',
  },
};

const formatHeaderText = (header: string | string[]): JSX.Element | JSX.Element[] => {
  if (Array.isArray(header)) {
    return header.map((text) => <p style={style.header} key={text}>{text}</p>);
  }

  return <p style={style.header}>{header}</p>;
};

interface ModalProps {
  buttonText: string;
  closeURL: string;
  confirmationHeader: string;
  header: string | string[];
  nextURL: string;
  showCloseButton: boolean;
  showCheckmark: boolean;
  subtext: string;
}

const Modal: React.StatelessComponent<ModalProps> = ({
  showCloseButton, showCheckmark, closeURL, confirmationHeader, header, subtext, buttonText, nextURL,
}) => (
  <div style={style.background}>
    <div style={style.content}>
      {showCloseButton && <Link href={closeURL} style={style.close} />}
      {showCheckmark && <img src={checkmark} alt="checkmark" />}
      {confirmationHeader && <p style={style.confirmationHeader}>{confirmationHeader}</p>}
      {formatHeaderText(header)}
      <p style={style.subtext}>{subtext}</p>
      <Link data-cy="modal-button" href={nextURL} style={style.button}>{buttonText}</Link>
    </div>
  </div>
);

export default Modal;
