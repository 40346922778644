import React, { forwardRef, useCallback, useImperativeHandle, ReactNode } from 'react';
import useToggle from '../../hooks/useToggle';
import {
  Background,
  Content,
  Section,
  Header,
  Description,
  ButtonWrapper,
  CancelButton,
  ContinueButton,
} from './styles';

export interface PopoverContainerRef {
  closeModal: () => void
  openModal: () => void
}

interface Props {
  title: string;
  description?: string;
  cancelLabel: string;
  confirmLabel: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

const PopoverContainer: React.ForwardRefRenderFunction<PopoverContainerRef, Props> = (props, ref) => {
  const { title, description, cancelLabel, confirmLabel, onCancel, onConfirm, children } = props;
  const [isOpen, toggleOpen] = useToggle(false);

  const closeModal = useCallback(() => {
    toggleOpen(false);
  }, []);

  const openModal = useCallback(() => {
    toggleOpen(true);
  }, []);

  useImperativeHandle(ref, () => ({
    closeModal,
    openModal,
  }));
  
  return (
    isOpen &&
    <Background>
      <Content>
        <Section>
          <Header>{title}</Header>
          <Description>{description}</Description>
          {children}
          <ButtonWrapper>
            <CancelButton onClick={onCancel}>{cancelLabel}</CancelButton>
            <ContinueButton onClick={onConfirm}>{confirmLabel}</ContinueButton>
          </ButtonWrapper>
        </Section>
      </Content>
    </Background>
  );
};

export const PopoverModal = forwardRef(PopoverContainer);
