import React from 'react';

import { OrderGesture, Order, CSSStyle } from 'types';
import { styles } from 'style';

import Link from 'src/components/app/Link';

const formatGestureButton = (order: Order): JSX.Element => {
  const whiteButton = { ...styles.button, ...style.whiteButton };

  if (!order.service_gesture_available) {
    const disabled = { ...whiteButton, ...styles.disabledButton };
    return <button disabled style={disabled}>Add customer courtesy</button>;
  }

  return <Link data-cy={`order-${order.id}-sg`} href={`/order/${order.id}/service-gesture`} style={whiteButton}>Add customer courtesy</Link>;
};

const formatNote = (gesture: OrderGesture): string => {
  const gestureSubtext = `${gesture.name} / ${gesture.note}`;

  if (gestureSubtext.length > 57) {
    return `${gestureSubtext.slice(0, 56)}...`;
  }

  return gestureSubtext;
};

const formatLedger = (service_gestures: OrderGesture[]): JSX.Element[] => (
  service_gestures.map((gesture) => {
    const gestureText = gesture.note ? formatNote(gesture) : gesture.name;
    const key = service_gestures.indexOf(gesture);
    return (
      <div style={style.pill} key={key}>
        <span>
          <p style={styles.microPara}>Service Gesture</p>
          <p style={style.boldPara}>{gestureText}</p>
        </span>
      </div>
    );
  })
);

const style: CSSStyle = {
  pill: {
    border: '1px solid #E4E6E8',
    borderRadius: '8px',
    display: 'flex',
    margin: '0 auto 15px',
    padding: '18px 30px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  boldPara: {
    color: '#525E69',
    fontSize: '16px',
    fontWeight: 600,
    margin: '0',
  },
  whiteButton: {
    background: 'white',
    padding: '30px',
    textAlign: 'center',
    fontWeight: 600,
    color: '#525E69',
    fontSize: '18px',
  },
};

interface ServiceGestureLedgerProps {
  order: Order;
}

const ServiceGestureLedger: React.StatelessComponent<ServiceGestureLedgerProps> = ({ order }) => {
  const { service_gestures } = order;
  const serviceGestureButton = order && formatGestureButton(order);

  if (!service_gestures) {
    return serviceGestureButton;
  }

  const ledger = formatLedger(service_gestures);
  return (
    <>
      {ledger}
      {serviceGestureButton}
    </>
  );
};

export default ServiceGestureLedger;
