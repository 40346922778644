import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ReduxState, MeData, Dispatch, CreditMemo, Params,
} from 'types';

import { apiWipStatus } from 'selectors';
import Loading from 'src/shared-components/screens/Loading';
import { Endpoint } from 'types-wip';
import { apiFactory } from 'src/redux/actions-wip';
import { redirect } from '../app/routeUtils';

interface CreditMemoRouterProps {
  jwt: string;
  meData: MeData;
  creditMemo: CreditMemo;
  dispatch: Dispatch;
  params: Params;
}

const CreditMemoDetail: React.StatelessComponent<CreditMemoRouterProps> = ({
  jwt,
  creditMemo,
  dispatch,
  params,
}) => {
  useEffect(() => {
    if (jwt && !creditMemo) {
      const creditMemoId = params['credit-memo'];
      dispatch(apiFactory(Endpoint.CreditMemo, jwt, creditMemoId));
    }
  }, []);

  useEffect(() => {
    if (creditMemo) {
      // Note: Checkout links to retail-order-management.warby.io/credit-memo/123
      // when a purchase is complete and we owe money to the customer (ie, if a customer
      // exchanges an item for a lower-priced item.)
      // This gets routed to the component you are currently viewing.
      // Here, we simply look up the credit memo to craft the full url and redirect.
      redirect(`/customer/${creditMemo.customer_id}/credit-memo/${params['credit-memo']}`);
    }
  }, [creditMemo]);

  return <Loading />;
};

const select = (state: ReduxState, { params }) => {
  const creditMemoId = params['credit-memo'];
  const creditMemoStatus = apiWipStatus(state, Endpoint.CreditMemo);
  const creditMemo = creditMemoStatus[creditMemoId] && creditMemoStatus[creditMemoId].body[0];

  return ({
    creditMemo,
    jwt: state.auth.jwt,
  });
};

export default connect(select)(CreditMemoDetail);
