/* eslint-disable @typescript-eslint/naming-convention */
import { AntiFatigueAddPower, ConfigProduct, OrderItem, PimItem } from 'types';
import {
  ConfiguredProduct,
  EligibilityType,
  ItemsLensReplacementEligibility,
  MapItemFrameAttributes,
  OrderItemFrameAttributes,
  OriginalSOProductDetail,
} from '../../types/lens-replacement';
import { FRAME_ASSEMBLY } from '../../redux/lens-replacement/constants';
import { RetailOrder, RetailOrderItem } from '../../redux/order/types';
import { getAntiFatiguePowerId, getLensTypeId } from '../../../selectors';
import { LR_PRODUCT_TYPE, ProductCategory } from '../../utils/constants';
import { UserPrescription } from '../../redux/lens-replacement/types';
import { isPlanoRx } from './steps/prescription-step/prescriptionUtils';
import { hasPrescriptionExpired } from '../../utils/verifyPrescriptions';

export const isFrameAssembly = (product: OriginalSOProductDetail | ConfiguredProduct) => (
  product.primary_product_category_id && product.primary_product_category_id === FRAME_ASSEMBLY
);

export function areAllItemsEligibleForFreeLR(
  selectedItems: OrderItem[],
  itemsEligibility: ItemsLensReplacementEligibility[],
): boolean {

  if (selectedItems === undefined || itemsEligibility === undefined) return false;
  if (!selectedItems.length || !itemsEligibility.length) return false;

  return selectedItems.every((selectedItem) => {
    const eligibilityItem = itemsEligibility.find((item) => item.get(selectedItem.pc_product_id));
    if (eligibilityItem === undefined) return false;
    const { eligibility } = eligibilityItem.get(selectedItem.pc_product_id);
    return eligibility === EligibilityType.FreeLensReplacement;
  });
}

export function isItemEligibleForFreeLR(item: OrderItem, itemsEligibility: ItemsLensReplacementEligibility[]): boolean {
  if (!item || !itemsEligibility) return false;

  const foundEligibilityMap = itemsEligibility.find(eligibilityMap => {
    const eligibilityInfo = eligibilityMap.get(item.pc_product_id);
    return eligibilityInfo && eligibilityInfo.eligibility === EligibilityType.FreeLensReplacement;
  });

  return Boolean(foundEligibilityMap);
}

export function mapOrderItemFrameAttributes(
  retailOrder: RetailOrder,
  pimItems: PimItem[],
  antiFatiguePowers: AntiFatigueAddPower[],
  pimConfigProducts: ConfigProduct[] = [],
): MapItemFrameAttributes {
  if (!retailOrder) return [];

  const { items: orderItems } = retailOrder;

  return orderItems.reduce((accumulator: MapItemFrameAttributes, retailOrderItem: RetailOrderItem) => {
    const { id, attributes, pc_product_id: pcProductId } = retailOrderItem;
    const { frame } = attributes;

    if (!frame || !pimConfigProducts.length) return accumulator;

    const pimItem = pimItems.find((item) => item.pc_product_id === pcProductId);
    const lensProductCategory = pimConfigProducts.find(
      ({
        configured_pc_product_id,
        primary_product_category_id,
      }) => configured_pc_product_id === pimItem.pc_product_id && primary_product_category_id === ProductCategory.Lens);
    const lensTypeId = getLensTypeId(pimItem, lensProductCategory);

    const frameAttributes: OrderItemFrameAttributes = {
      optician: frame.optician,
      bi_seg_height: frame.seg_height_od || frame.seg_height_os,
      oc_height: frame.oc_height > 0 ? frame.oc_height : null,
      anti_fatigue_power: getAntiFatiguePowerId(frame.anti_fatigue_power, antiFatiguePowers),
      lens_type: lensTypeId,
      measurement_type: frame.measurement_type,
    };

    const attributesMap = new Map<number, OrderItemFrameAttributes>();
    attributesMap.set(id, frameAttributes);
    accumulator.push(attributesMap);

    return accumulator;
  }, []);
}

export function canProceedToCartWithPrescription(
  currentPrescription: UserPrescription,
  lensReplacementTypeFlow: string,
) {
  if (lensReplacementTypeFlow === LR_PRODUCT_TYPE.FREE) return true;

  return !hasPrescriptionExpired(currentPrescription);
}

const filterPrescriptionsForScratchGuarantee = (
  item: UserPrescription,
  originalPrescription: UserPrescription,
): boolean => {
  if (!originalPrescription) return true;

  const {
    for_prism,
    for_readers,
    for_single_vision,
    for_progressives,
    has_pd,
  } = originalPrescription;

  const {
    for_prism: forPrism,
    for_readers: forReaders,
    for_single_vision: forSingleVision,
    for_progressives: forProgressives,
    has_pd: hasPD,
  } = item;

  return (
    for_prism === forPrism &&
    forReaders === for_readers &&
    for_single_vision === forSingleVision &&
    for_progressives === forProgressives &&
    has_pd === hasPD
  );
};

export const combinedFilter = (
  item: UserPrescription,
  originalPrescription: UserPrescription,
  lensReplacementTypeFlow: string,
) => {
  const isPlano = isPlanoRx(item);

  if (lensReplacementTypeFlow === LR_PRODUCT_TYPE.PAID) {
    return true;
  }

  const isOriginalOrderSV = originalPrescription?.for_single_vision;

  if (isPlano && isOriginalOrderSV) {
    return true;
  }

  return filterPrescriptionsForScratchGuarantee(item, originalPrescription);
};

export const filterNonContactsPrescriptions = (item: UserPrescription) => !item.for_contacts;
