import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ReduxState, CancelReason, APIParsedSummary, Dispatch, Order, Params,
} from 'types';
import {
  apiLoading, apiStatus, apiResponse, apiActionResponse, getOrder,
} from 'selectors';
import { actions } from 'src/redux/actions';
import { styles } from 'style';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import Nav from 'src/shared-components/screens/Nav';
import Loading from 'src/shared-components/screens/Loading';
import CancelNote from 'src/components/cancel/CancelNote';
import CancelModal from 'src/components/cancel/CancelModal';

const cancelReasonsPath = (): string => '/v1/sales-order/cancel-reasons';

const cancelOrderPath = (id: string): string => `/api/v2/sales_order/${id}/cancel`;

const cancelReason = (id: string, cancelTypes: CancelReason[]): CancelReason => cancelTypes.find((cancel) => cancel.id === Number(id));

const cancelOrder = (cancelReason: string, orderId: string, jwt: string, dispatch: any, note = ''): void => {
  const path = cancelOrderPath(orderId);
  const postParams = {
    method: 'POST',
    body: JSON.stringify({
      cancel_reason: cancelReason,
      ...(note && { note }),
    }),
  };

  dispatch(actions.fetchAPI(path, jwt, postParams));
};

const disabledButton = { ...styles.button, ...styles.disabledButton };

interface CancelConfirmationProps {
  jwt: string;
  dispatch: Dispatch;
  cancelRequested: boolean;
  cancelSummary: APIParsedSummary;
  cancelTypes: CancelReason[];
  hasRequestedCancelTypes: boolean;
  params: Params;
  order: Order;
}

const CancelConfirmation: React.StatelessComponent<CancelConfirmationProps> = ({
  jwt, dispatch, cancelRequested, cancelSummary, cancelTypes, hasRequestedCancelTypes, params, order,
}) => {
  useEffect(() => {
    if (jwt && !hasRequestedCancelTypes) {
      const path = cancelReasonsPath();
      dispatch(actions.fetchOhmAPI(path, jwt));
    }
  }, []);

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);

  const orderId = params.order;
  const cancelId = params.cancel;
  const cancel = cancelTypes && cancelReason(cancelId, cancelTypes);
  const isNoteRequired = cancel && cancel.notes_required;
  const cancelButtonText = cancelRequested ? 'Loading...' : 'Yes, cancel order';
  const buttonStyle = cancelRequested ? disabledButton : styles.button;

  if (!order) {
    return <Loading />;
  }

  return (
    !cancelTypes ? <Loading /> : (
      <div className="page">
        <div className="content">
          <Nav
            href={`/order/${orderId}/cancel`}
            text="Cancel Items"
            subtext=""
          />
          <EditableSelectionPill
            subtext="Cancel Reason Type"
            text={cancel.type}
            href={`/order/${orderId}/cancel`}
          />
          {isNoteRequired ? (
            <CancelNote
              jwt={jwt}
              orderId={orderId}
              cancel={cancel}
              dispatch={dispatch}
              cancelOrder={cancelOrder}
              loading={cancelRequested}
            />
          ) : (
            <>
              <p style={styles.paddedPara}>Are you sure you want to cancel this order?</p>
              <button style={buttonStyle} disabled={cancelRequested} onClick={() => cancelOrder(cancel.key, orderId, jwt, dispatch)}>{cancelButtonText}</button>
            </>
          )}
          {cancelSummary && <CancelModal customerId={order.customer_id} orderId={orderId} summary={cancelSummary} />}
        </div>
      </div>
    )
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);

  const cancelTypesPath = cancelReasonsPath();
  const cancelTypesStatus = apiStatus(state, cancelTypesPath);
  const cancelTypes = apiResponse(cancelTypesStatus);

  const cancelPath = cancelOrderPath(params && params.order);
  const cancelStatus = apiStatus(state, cancelPath);
  const cancelSummary = apiActionResponse(cancelStatus);

  return ({
    jwt: state.auth.jwt,
    hasRequestedCancelTypes: apiLoading(cancelTypesStatus),
    cancelRequested: apiLoading(cancelStatus),
    cancelTypes,
    cancelSummary,
    order,
  });
};

export default connect(select)(CancelConfirmation);
