/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { styles } from 'style';
import { Order, ReduxState, Params, PimItem, OrderItem } from 'types';
import { isShipping, selectPimItem, getOrder, getPimItems } from 'selectors';
import { connect } from 'react-redux';
import Loading from 'src/shared-components/screens/Loading';
import ItemCard from 'src/components/return/ItemCard';
import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { createItemInvoiceReturnablePredicate } from 'src/components/order/util';
import { ReplaceItemsButton } from '../../lens-replacement-buttons/replace-items/ReplaceItemsButton';
import { useLRStepper } from '../../lr-stepper';

interface SelectProductsStepProps {
  order: Order;
  pimItems: PimItem[];
}

const SelectProductsStep : React.FC<SelectProductsStepProps> = ({ order, pimItems }) => {
  const { updateData, nextStep } = useLRStepper('SelectProductsStep');

  const [selectedItemIds, setSelectedItemIds] = useState<OrderItem[]>(
    [],
  );

  const addItem = useCallbackRef((id: string) => {
    const selection = items.find(item => item.id === id);
    setSelectedItemIds(oldState => [...oldState, selection]);
  });

  const removeItem = useCallbackRef((id: string) => {
    setSelectedItemIds(oldState => oldState.filter(item => item.id !== id));
  });

  const items = (
    order.items
    && pimItems
    && order.items.filter(
      (orderItem) => !isShipping(selectPimItem(pimItems, orderItem)),
    )
  );

  const handleNextStep = useCallbackRef(() => {
    nextStep();
  });

  useEffect(() => {
    updateData({ selectedItems: selectedItemIds });
  }, [selectedItemIds]);

  return !order || !pimItems ? (
    <Loading />
  ) : (
    <>
      <p style={styles.paddedLargePara}>
        Which item would you like to replace?
      </p>

      {items.map((item) => (
      /* eslint-disable react/no-array-index-key */
        Array.from({ length: item.quantity }).map((_, index) => (
            <ItemCard
              item={item}
              pimItem={selectPimItem(pimItems, item)}
              key={`${item.id}-${index}`}
              returnable={createItemInvoiceReturnablePredicate('received_return_quantity')(item)}
              addItem={addItem}
              removeItem={removeItem}
              isForLensReplacement
            />
        ))
      ))}

      <ReplaceItemsButton
        itemsCount={selectedItemIds.length}
        onClick={handleNextStep}
      />
    </>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params) as Order;
  const pimItems = getPimItems(state, order);

  return ({
    jwt: state.auth.jwt,
    order,
    pimItems,
  });
};

export default connect(select)(SelectProductsStep);
