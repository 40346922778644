import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import ErrorBoundary from 'src/components/app/ErrorBoundary';
import AuthenticationWrapper from 'src/components/app/index';
import { initNewRelic } from 'src/new-relic';
import { injectLoggerIntoGlobal } from 'src/providers/logger/injectLoggerIntoGlobal';
import configureStore from './configureStore';

const store = configureStore();
const root = document.getElementById('app');

initNewRelic();
injectLoggerIntoGlobal();

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <AuthenticationWrapper />
    </ErrorBoundary>
  </Provider>,
  root,
);
