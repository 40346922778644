import { Dispatch } from '../../../types';
import { GET_ORDER_FAILURE, GET_ORDER_REQUEST, GET_ORDER_SUCCESS } from './actions';
import { OrderId, RetailOrder } from './types';

export const fetchRetailOrder =
  (customerId: string, orderId: OrderId) => async (dispatch: Dispatch) => {
    dispatch({ type: GET_ORDER_REQUEST, orderId });

    try {
      const response: Response = await fetch(
        `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/customers/${customerId}/sales_orders/${orderId}`,
        { credentials: 'include' },
      );

      if (response.status === 200) {
        const data = (await response.json()) as RetailOrder[];
        const order = data[0];
        dispatch({ type: GET_ORDER_SUCCESS, orderId, order });
      } else {
        const message = ((await response.json())?.message as string) || 'Something went wrong';
        dispatch({ type: GET_ORDER_FAILURE, orderId, error: message });
      }
    } catch (error) {
      dispatch({ type: GET_ORDER_FAILURE, orderId, error: (error as Error)?.message });
    }
  };
