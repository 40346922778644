import OhmClient, { Service as OhmService } from '@warbyparker/ohmsdk';
import {
  Dispatch,
  Actions,
  CLEAR_API_STATE,
  FETCH_JWT_SUCCESS,
  FETCH_REQUEST_FAILURE,
  UPDATE_API_STATE,
  REMOVE_API_STATE,
  Order,
  OrderItem,
} from 'types';
import {
  getOrderPath,
  getPimItemsPath,
  apiLoading,
  apiStatus,
  getPimItemsConfigProductsPath,
  getPimItemsDetailedProductPath,
} from 'selectors';
import { Endpoint } from 'types-wip';
import {
  fetchLensReplacementDetailsByLensReplacementSalesOrderId,
  fetchLensReplacementValidation,
  fetchProductAvailabilities,
  fetchProductDetailsFromOriginalSalesOrder,
  fetchUserPrescriptions,
} from './lens-replacement/thunks';
import { SalesOrderLensReplacementDetail } from '../types/lens-replacement';
import { apiFactory } from './actions-wip';

// fetch JWT after user has used id.warby.io to login or redirect to
const fetchJWT = () => async (dispatch: Dispatch) => {
  const endpoint = `https://${process.env.HELIOS_RETAIL_DOMAIN}/api/v1/user/jwt`;

  const request = await fetch(endpoint, {
    credentials: 'include',
  })
    .catch(() => {
      throw new Error('Failed to retrieve auth token.');
    });

  if (request.status === 200) {
    const response = await request.json();
    dispatch({ type: FETCH_JWT_SUCCESS, payload: response.token });
    return;
  }

  // if response is forbidden, redirect to the login page
  if (request.status === 403) {
    dispatch({ type: FETCH_REQUEST_FAILURE, payload: request.statusText });
    window.location.href = `https://${process.env.HELIOS_RETAIL_DOMAIN}/login?next=${encodeURIComponent(
      window.location.href,
    )}`;
    return;
  }

  dispatch({ type: FETCH_REQUEST_FAILURE, payload: request.statusText });
};

const fetchAPI = (path: string, jwt: string, extraFetchParams = {}) => (
  async (dispatch: Dispatch, getState: Function) => {
    const state = getState();
    const status = apiStatus(state, path);
    if (apiLoading(status)) return null;

    dispatch({ type: UPDATE_API_STATE, payload: { path, body: null, error: null } });
    const response = await fetch(`https://${process.env.HELIOS_RETAIL_DOMAIN}${path}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      ...extraFetchParams,
    });
    const body = await response.json();

    dispatch({
      type: UPDATE_API_STATE,
      payload: {
        path,
        body,
        error: response.status > 399,
      },
    });
    return body;
  }
);

const fetchAuthenticatedAPI = (path: string, extraFetchParams = {}) => (
  async (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_API_STATE, payload: { path, body: null, error: null } });

    const response = await fetch(`https://${process.env.HELIOS_RETAIL_DOMAIN}${path}`, {
      credentials: 'include',
      ...extraFetchParams,
    });
    const body = await response.json();

    dispatch({
      type: UPDATE_API_STATE,
      payload: {
        path,
        body,
        error: response.status > 399,
      },
    });
    return body;
  }
);

const fetchOhmAPI = (path: string, jwt: string, extraFetchParams = {}) => (
  async (dispatch: Dispatch, getState: Function) => {
    const state = getState();
    const status = apiStatus(state, path);
    if (apiLoading(status)) return null;

    dispatch({ type: UPDATE_API_STATE, payload: { path, body: null, error: null } });
    const client = new OhmClient(OhmService.RETAIL, jwt);
    const response = await client.get(path);
    const body = response.data;

    return dispatch({
      type: UPDATE_API_STATE,
      payload: {
        path,
        body,
        error: response.status > 399,
      },
    });
  }
);

const fetchPimAPI = (path: string, jwt: string, extraFetchParams = {}) => (
  async (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_API_STATE, payload: { path, body: null, error: null } });
    const client = new OhmClient(OhmService.PIM, jwt);
    const response = await client.get(path);
    const body = response.data;

    return dispatch({
      type: UPDATE_API_STATE,
      payload: {
        path,
        body,
        error: response.status > 399,
      },
    });
  }
);

export const ANTI_FATIGUE_ADD_POWERS_ENDPOINT = '/api/v2/anti-fatigue-add-power';
const fetchAntiFatigueAddPowers = (jwt: string) => fetchAPI(ANTI_FATIGUE_ADD_POWERS_ENDPOINT, jwt);

const fetchOrder = (dispatch, jwt, params) => {
  const orderPath = params && getOrderPath(params.order);
  return () => {
    orderPath && dispatch(actions.fetchOhmAPI(orderPath, jwt));
  };
};

const fetchPimItems = (dispatch, jwt, order) => {
  const pimItemPath = order && getPimItemsPath(order);
  return () => {
    pimItemPath && dispatch(actions.fetchPimAPI(pimItemPath, jwt));
  };
};

const fetchPimItemsDetailedProduct = (dispatch, jwt: string, orderItems: OrderItem[]) => {
  const pimItemDetailedProductPath = orderItems && getPimItemsDetailedProductPath(orderItems);
  return () => {
    if (pimItemDetailedProductPath) {
      dispatch(actions.fetchPimAPI(pimItemDetailedProductPath, jwt));
    }
  };
};

const fetchPimConfigProducts = (dispatch, jwt: string, order: Order) => {
  const pimItemConfigProductsPath = order?.items && getPimItemsConfigProductsPath(order?.items);
  return () => {
    if (pimItemConfigProductsPath) {
      dispatch(actions.fetchPimAPI(pimItemConfigProductsPath, jwt));
      const pcProductIds = order?.items.map((item) => item.pc_product_id);
      dispatch(fetchProductAvailabilities(pcProductIds, jwt));
    }
  };
};

const resetAPIState = (): Actions => ({ type: CLEAR_API_STATE });

const removeAPIState = (path: string): Actions => ({ type: REMOVE_API_STATE, payload: { path } });

const fetchLensReplacementValidations = (
  dispatch,
  orderId: number,
  jwt: string,
  canLensReplacement: boolean,
) => () => {
  if (!orderId || !canLensReplacement) return;

  dispatch(fetchLensReplacementValidation(orderId, jwt));
};

const fetchUserPrescriptionsByCustomerId = (
  dispatch,
  customerId: string,
) => () => {
  if (!customerId) return;

  dispatch(fetchUserPrescriptions(customerId));
};

const fetchLensReplacementDetailsBySalesOrder = (
  dispatch,
  orderId: number,
  jwt: string,
  canLensReplacement: boolean,
) => () => {
  if (!orderId || !canLensReplacement) return;

  dispatch(fetchLensReplacementDetailsByLensReplacementSalesOrderId(orderId, jwt));
};

const fetchOriginalOrderItemsDetails = (
  dispatch,
  lensReplacementDetails: SalesOrderLensReplacementDetail[],
  jwt: string,
  canLensReplacement: boolean,
) => () => {
  if (!lensReplacementDetails.length || !canLensReplacement) return;

  const pcProductIds = lensReplacementDetails.map(item => item.frame_pc_product_id);
  dispatch(fetchProductDetailsFromOriginalSalesOrder(pcProductIds, jwt));
};

const fetchOrdersStatus = (dispatch, jwt:string, params) => () => {
  if (jwt) {
    const customerId = params.customer;
    dispatch(apiFactory(Endpoint.SalesOrders, jwt, customerId));
  }
};

export const actions = {
  fetchJWT,
  fetchAPI,
  fetchAntiFatigueAddPowers,
  fetchAuthenticatedAPI,
  fetchOhmAPI,
  fetchPimAPI,
  fetchOrder,
  fetchOrdersStatus,
  fetchPimItems,
  fetchPimConfigProducts,
  resetAPIState,
  removeAPIState,
  fetchLensReplacementValidations,
  fetchLensReplacementDetailsBySalesOrder,
  fetchOriginalOrderItemsDetails,
  fetchPimItemsDetailedProduct,
  fetchUserPrescriptionsByCustomerId,
};
