import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'src/shared-components/badge/Badge';
import { ItemsLensReplacementEligibility } from 'src/types/lens-replacement';
import { useCallbackRef } from 'src/hooks/useCallbackRef';
import { LR_PRODUCT_TYPE } from 'src/utils/constants';
import { currentUserHasPermission } from 'selectors';
import { LENS_REPLACEMENT_SCRATCH_OVERRIDE_PERMISSION } from 'src/utils/user-permissions';
import GuaranteeCard from '../../guarantee-card';
import { scratchGuarantee, paidLensReplacement } from '../../guarantee-card/constants';
import { areAllItemsEligibleForFreeLR } from '../../utils';
import { Row, StyledParagraph, Content } from './styles';
import { useLRStepper } from '../../lr-stepper';
import { ReduxState } from '../../../../../types';

interface AddProductTypeProps {
  eligibleItems?: ItemsLensReplacementEligibility[];
  hasOverridePermission: boolean;
}

type ProductType = typeof LR_PRODUCT_TYPE[keyof typeof LR_PRODUCT_TYPE];

const AddProductTypeStep: React.FC<AddProductTypeProps> = ({
  eligibleItems = [],
  hasOverridePermission,
}) => {
  const { data, updateData, nextStep } = useLRStepper('AddProductTypeStep');
  const { selectedItems } = data;

  const [currentType, setCurrentType] = useState<ProductType | null>(null);

  const selectProductType = useCallbackRef((type: ProductType) => {
    setCurrentType(type);
  });

  useEffect(() => {
    updateData({ type: currentType });

    if (currentType !== null) nextStep();
  }, [currentType]);

  const isFreeEligible = areAllItemsEligibleForFreeLR(selectedItems, eligibleItems);

  const freeCardBadge = isFreeEligible ? (
    <Badge variant="success">Eligible</Badge>
  ) : (
    <Badge variant="error">Not Eligible</Badge>
  );

  return (
    <>
      <Row>
        <Content>
          <StyledParagraph>Select a lens replacement reason</StyledParagraph>
        </Content>
      </Row>
      <Row>
        <Content>
          <GuaranteeCard
            title={scratchGuarantee.title}
            description={
              isFreeEligible ? scratchGuarantee.description : scratchGuarantee.noEligibleDesc
            }
            tag={freeCardBadge}
            status={isFreeEligible ? 'Eligible' : 'Not Eligible'}
            productType={LR_PRODUCT_TYPE.FREE}
            onClick={() =>
              (isFreeEligible || hasOverridePermission) && selectProductType(LR_PRODUCT_TYPE.FREE)
            }
            hasOverridePermission={hasOverridePermission}
          />
        </Content>
      </Row>
      <Row>
        <Content>
          <GuaranteeCard
            title={paidLensReplacement.title}
            description={paidLensReplacement.description}
            tag={<Badge variant="success">Eligible</Badge>}
            status="Eligible"
            productType={LR_PRODUCT_TYPE.PAID}
            onClick={() => selectProductType(LR_PRODUCT_TYPE.PAID)}
            hasOverridePermission={hasOverridePermission}
          />
        </Content>
      </Row>
    </>
  );
};

const select = (state: ReduxState) => {
  const { lensReplacement } = state;
  const { eligibleItems } = lensReplacement;

  return {
    eligibleItems,
    hasOverridePermission: currentUserHasPermission(
      LENS_REPLACEMENT_SCRATCH_OVERRIDE_PERMISSION,
      state,
    ),
  };
};

export default connect(select)(AddProductTypeStep);
