import { css } from 'react-emotion';
import colors from '../../utils/colors';

export type Variants = 'success' | 'secondary' | 'error' | 'warning' | 'notification' | 'subtle';
const variantColors: Record<Variants, string> = {
  success: colors.green,
  secondary: colors.grayChateau,
  subtle: colors.silk,
  error: colors.redHover,
  warning: colors.apricot,
  notification: colors.orange,
};

const fontColors: Record<Variants, string> = {
  success: '#fff',
  secondary: '#fff',
  subtle: colors.grayChateau,
  error: '#fff',
  warning: '#fff',
  notification: '#fff',
};

// eslint-disable-next-line import/prefer-default-export
export const badge = (variant: Variants) => css`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 650;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: ${fontColors[variant]};
  margin-left: 5px;
  background-color: ${variantColors[variant]};
  height: fit-content;
  margin-right: auto;
`;
