import { ReduxState, SalesOrder, CreditMemo } from 'types';
import { ThunkAction } from 'redux-thunk';

// generic payload
type Payload<E, B, P> = {
  error: null | E;
  body: null | B;
  params?: null | P;
};

type SalesOrdersPayload = Payload<boolean, SalesOrder[], EndpointParam>;
type CreditMemosPayload = Payload<boolean, CreditMemo[], EndpointParam>;
type CreditMemoPayload = Payload<boolean, CreditMemo[], EndpointParam>;
type GenericPayload = Payload<boolean, Responses, EndpointParam>;

// return value of action creator that returns async func that dispatches API result
export type ThunkResult<Result> = ThunkAction<Result, ReduxState, null, GenericAction>;

// action dispatched after receiving response from api call
interface SalesOrdersAction {
  type: string;
  payload: SalesOrdersPayload;
  endpoint: Endpoint.SalesOrders;
}

// action dispatched after receiving response from api call
interface CreditMemosAction {
  type: string;
  payload: CreditMemosPayload;
  endpoint: Endpoint.CreditMemos;
}

// action dispatched after receiving response from api call
interface CreditMemoAction {
  type: string;
  payload: CreditMemoPayload;
  endpoint: Endpoint.CreditMemo;
}

export type Responses =
  | SalesOrder[]
  | CreditMemo[];

export interface GenericAction {
  type: string;
  payload: GenericPayload;
}

export type Actions =
  | CreditMemosAction
  | CreditMemoAction
  | SalesOrdersAction;

// Enum that tracks all possible endpionts to help type api response types in
// redux state

export enum Endpoint {
  SalesOrders = 'sales-orders',
  CreditMemos = 'credit-memos',
  CreditMemo = 'credit-memo',
}

export type EndpointParam = string;

type Url = string;

export type UrlChecker = {
  [P in Endpoint]: (...EndpointParam) => Url;
};

interface SalesOrdersEndpointState {
  [id: string]: Payload<boolean, SalesOrder[], null>;
}

interface CreditMemosEndpointState {
  [id: string]: Payload<boolean, CreditMemo[], null>;
}

interface CreditMemoEndpointState {
  [id: string]: Payload<boolean, CreditMemo[], null>;
}

export type WIPState = {
  [Endpoint.SalesOrders]: SalesOrdersEndpointState;
  [Endpoint.CreditMemos]: CreditMemosEndpointState;
  [Endpoint.CreditMemo]: CreditMemoEndpointState;
};

export const UPDATE_API_STATE_WIP = 'UPDATE_API_STATE_WIP';
