export const RECOMMENDATION_NOTES = Object.freeze([
  ' Eyewire should not appear bent or misshapen, free of rolling or warping',
  'Acetate does not show any signs of cracking, dryness or brittleness that may increase chances of breakage',
  'Screws are not stripped and can be loosened, tightened or replaced',
  'Temple should be able to open and close (to fit inside a case) and hinge is securely mounted to the frame front',
  'Barrels, solder points, hinges and all metal components must be intact (no missing rivets or plaques, windsor rim secure) and free of cracks, defects, rust or damage',
  'If applicable, nose pad guard arms should be intact and secure, free from damage',
]);

export const LR_PRODUCT_TYPE = Object.freeze({
  FREE: 'free',
  PAID: 'paid',
});

export const LR_RETURN_REASON_IDS = Object.freeze({
  'LR: Scratch': 222,
  'LR: Damage': 223,
  'LR: Craze': 224,
  'LR: Coating defect': 225,
  'LR: Retired/Unique Frame': 226,
  'LR: Rx change/upgrade': 227,
  'LR: Lens product change': 228,
});

export enum LensTypes {
  Polycarbonate = 'polycarbonate',
  HighIndex = 'high_index',
  UltraHighIndex = 'ultra_high_index',
  SunHighIndex = 'sun_high_index',
  Cr39 = 'cr_39',
}

export enum LensCategoryOptions {
  OPTICAL = 'OPT_LENS',
  SUN = 'SUN_LENS',
}

export enum ProductCategory {
  Lens = 'LENS',
  FrameAssembly = 'FRAME_ASSEMBLY',
}
