import { OrdersList } from './types';
import {
  GET_ORDER_FAILURE,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GetOrderFailureAction,
  GetOrderSuccessAction,
  OrdersAction,
} from './actions';

export interface OrderState {
  orders: OrdersList;
  error: string;
  loading: boolean;
}

const initialState: OrderState = {
  orders: {},
  error: '',
  loading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const orderReducer = (state = initialState, action: OrdersAction) => {
  switch (action.type) {
    case GET_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case GET_ORDER_SUCCESS: {
      const { orderId, order } = action as GetOrderSuccessAction;
      return {
        ...state,
        orders: {
          ...state.orders,
          [orderId]: order,
        },
        loading: false,
      };
    }
    case GET_ORDER_FAILURE: {
      const { error } = action as GetOrderFailureAction;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};
export default orderReducer;
