/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useRef } from 'react';

import { textfield, textfieldContainer } from './styles';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface TextFieldProps extends InputProps {
  label: string;
  value?: string;
  onChange?:  React.ChangeEventHandler<HTMLInputElement>;
  description?: string;
  helpText?: string;
}

export const TextField: React.FC<TextFieldProps> = ({
  label,
  value = '',
  description = '',
  helpText = '',
  onChange = () => {},
  ...props
})=>{
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus =  useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <div className={textfieldContainer} onClick={handleFocus}>
      <label htmlFor={label} className={textfield}>
        {label}

        <span onClick={handleFocus}>
          {description}
        </span>

        <div onClick={handleFocus}>
          {helpText}

          <input
            id={label}
            type="text"
            value={value}
            onChange={onChange}
            ref={inputRef}
            {...props}
          />
        </div>
      </label>
    </div>
  );
};

export default TextField;
