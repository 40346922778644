import { css } from 'react-emotion';
import colors from '../../../../utils/colors';

export const replaceItemButton = css`
  background: ${colors.blue};
  border: 1px solid #E4E6E8;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0 0;
  padding: 24px 30px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    background-color: hsl(197, 50%, 70%);
  }
`;
