import React, { FC, useEffect, useMemo, useState } from 'react';
import { TextField } from 'src/shared-components/text-field/TextField';
import { LR_RETURN_REASON_IDS, RECOMMENDATION_NOTES } from 'src/utils/constants';
import { getOrder, getPimItems, selectPimItem } from 'selectors';
import { Order, Params, PimItem, ReduxState } from 'types';
import { connect } from 'react-redux';
import { styles } from 'style';
import { SelectField } from 'src/shared-components/select-field';
import { Button } from '../../guarantee-card/styles';
import { lrStyles } from '../../styles';
import { useStepperContext } from '../../lr-stepper/context';

interface OpticianNotesStepProps {
  pimItems: PimItem[];
}

const OpticianNotesStep: React.FC<OpticianNotesStepProps> = ({ pimItems }) => {
  const { updateData, nextStep, data } = useStepperContext('OpticianNotesStep');

  const { selectedItems } = data;

  const [opticianNote, setOpticianNote] = useState('');
  const [returnReasonId, setReturnReasonId] = useState<Record<string, number | string>>({});

  useEffect(() => {
    updateData({ notes: opticianNote, returnReasonId });
  }, [opticianNote, returnReasonId]);

  const recommendationNotes = Array.from(RECOMMENDATION_NOTES);

  const returnReasonOptions = useMemo(() => (
    Object.keys(LR_RETURN_REASON_IDS).map(opt => ({
      label: opt,
      value: LR_RETURN_REASON_IDS[opt],
    }))
  ), []);

  const areAllSelectFieldsFilled = () => selectedItems.every(item => returnReasonId[item.pc_product_id] !== undefined);

  return (
    <>
      <div style={lrStyles.container}>
        <p style={lrStyles.boldPara}>
          To ensure a successful lens replacement, the frame should be in good condition overall
        </p>

        <p style={lrStyles.boldPara}>Specifically inspect for:</p>

        <div style={lrStyles.para}>
          <ul>
            {recommendationNotes && recommendationNotes.map(note => (
              <li key={`lr-note-${note}`}>{note}</li>
            ))}
          </ul>
        </div>

        <div>
          <p style={lrStyles.boldPara}>
            <b>Inspection notes</b>
          </p>

          <p style={styles.para}>
            These notes will saved on the sales order and available on the CPU quality check page
          </p>

          <TextField
            value={opticianNote}
            onChange={e => setOpticianNote(e.target.value)}
            label="Notes"
          />

          {selectedItems.map((item)=>{
            const product = selectPimItem(pimItems, item);
            const itemName = product ? product.product_name : 'Unknown Product Name';
            return (
             <React.Fragment key={item.id}>
              <p style={styles.para}> Lens Replacement reason for: <b>{itemName}</b></p>
              <SelectField
                name='Return Reason'
                placeholder='Select a Lens Replacement Reason'
                onChange={e => {
                  const { value } = e.target;
                  setReturnReasonId(prevState => ({ ...prevState, [item.pc_product_id]: value }));
                }}
                options={returnReasonOptions}
                required
              />
           </React.Fragment>
            );
          })}
        </div>
      </div>

      <div style={{ ...lrStyles.container, border: '0px solid', padding: 0, marginTop: '2rem' }}>
        <Button
          disabled={!areAllSelectFieldsFilled()}
          onClick={nextStep}
        >
          Continue to Acknowledgement Form
        </Button>
      </div>
    </>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params) as Order;
  const pimItems = getPimItems(state, order);

  return ({
    jwt: state.auth.jwt,
    pimItems,
  });
};

export default connect(select)(OpticianNotesStep);
