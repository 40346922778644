export type FreeLensReplacement = 'FREE_LR_ELIGIBILITY';
export type PaidLensReplacement = 'PAID_LR_ELIGIBILITY';

export enum EligibilityType {
  FreeLensReplacement = 'FREE_LR_ELIGIBILITY',
  PaidLensReplacement = 'PAID_LR_ELIGIBILITY',
}

type LensCategory = 'OPT_LENS' | 'SUN_LENS';

export type Eligibility = FreeLensReplacement | PaidLensReplacement;

export interface LREligibilityValidationResponse {
  items: LensReplacementEligibleItems[];
}

export interface SalesOrderLRDetailsBySalesOrderResponse {
  data: SalesOrderLensReplacementDetail[];
}

export type OriginalSOProductDetailResponse = OriginalSOProductDetail[];
export type ConfiguredProductDetailResponse = ConfiguredProductDetail[];

export type ConfiguredProductsResponse = ConfiguredProduct[];

export type FrameVariantWidth = {
  color_variant_id: string;
  width_slug: string;
};

export type FrameConfigDetails = {
  config_pc_product_id: 1111221;
  config_product_name: string;
  sf_variant_id: string;
  frame_color: string;
  frame_pc_product_id: string;
  frame_option_type: string;
  frame_product_name: string;
  lens_pc_product_id: string;
  lens_option_type: string;
  lens_product_name: string;
  is_hto: boolean;
  is_demo: boolean;
  is_readers: boolean;
  lens_refractive_index: string;
  is_rx: boolean;
  is_high_index: boolean;
  is_ultra_high_index: boolean;
  is_progressive_lens: boolean;
  is_precision_lens: boolean;
  is_prism_lens: boolean;
  is_flash_lens: boolean;
  is_blue_light: boolean;
  is_photochromic: boolean;
  is_cr_39: boolean;
  lens_color: string;
  lens_category: LensCategory;
  is_polarized: boolean;
  ca_price: number;
  images: Record<any, any>;
  is_suggested_sun_rx: boolean;
  is_suggested_lens_color: boolean;
  us_price: number;
  is_rx_sun: boolean;
  is_non_polarized: boolean;
  is_anti_fatigue: boolean;
};

export interface LensReplacementEligibleItems {
  [key: string]: {
    eligibility: Eligibility;
  };
}

export type ItemsLensReplacementEligibility = Map<
string,
{ eligibility: Eligibility; is_eligible_to_override?: boolean }
>;

export interface SalesOrderLensReplacementDetail {
  sales_order_id: number;
  sales_order_item_id: number;
  return_reason_id: number | null;
  original_sales_order_id: number;
  frame_pc_product_id: number;
  image_url: string;
}

interface ProductDetailCategory {
  id: string;
  name: string;
  primary_parent_id: string;
  type_id: string;
  detail_screen: string;
}

interface DetailedProduct {
  pc_product_id: number;
  primary_product_category_id: string;
  product_name: string;
  internal_name: string;
  long_description: string;
  categories: ProductDetailCategory[];
  displayName?: string;
  virtual_product_name?: string;
}

export type OriginalSOProductDetail = DetailedProduct;
export type ConfiguredProductDetail = DetailedProduct;

export interface ConfiguredProduct {
  configured_pc_product_id: number;
  pc_product_id: number;
  pc_product_ids: number[];
  primary_product_category_id: string;
}

export interface CategoryProduct {
  pc_product_id: number;
  pc_product_config_id?: number;
  id: string;
  type_id: string;
  primary_parent_id: number;
  name: string;
  detail_screen: string;
}

export interface ConfigGalleryProduct {
  [key: string]: ConfiguredProduct & { variant: FrameVariantWidth };
}

export interface OrderItemFrameAttributes {
  optician: string;
  oc_height: number | null;
  bi_seg_height: number | null;
  lens_type: string | null;
  anti_fatigue_power: number | null;
  measurement_type: string | null;
}

export type MapItemFrameAttributes = Map<number, OrderItemFrameAttributes>[];
export type OrderWithFrameAttributes = Map<number, MapItemFrameAttributes>;
