import React from 'react';

import { CreditMemo, CreditMemoItem, CSSStyle } from 'types';
import { formatPrice } from 'selectors';
import Link from 'src/components/app/Link';
import dropdownArrow from 'src/shared-components/icons/dropdown-arrow.svg';

const style: CSSStyle = {
  pillContainer: {
    marginBottom: '10px',
  },
  browText: {
    margin: '4px 0px',
  },
  angryBrow: {
    background: '#FFECE7',
    color: '#DA6E2D',
    fontSize: '18px',
    fontWeight: 600,
    borderRadius: '8px 8px 0px 0px',
    border: '1px solid #DA6E2D',
    padding: '12px 35px 10px 35px',
    display: 'flex',
  },
  chin: {
    background: 'white',
    color: '#676F78',
    fontSize: '18px',
    borderRadius: '0px 0px 8px 8px',
    border: '1px solid #E4E6E8',
    padding: '12px 35px 10px 35px',
  },
  pill: {
    background: 'white',
    borderRadius: '8px 8px 0px 0px',
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    borderColor: '#E4E6E8',
    color: '#676F78',
    display: 'grid',
    fontSize: '18px',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(2, 1fr) 100px 25px 50px',
    padding: '35px 15px',
  },
  link: {
    color: '#0089BF',
    fontSize: '18px',
  },
  leftPara: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    margin: '0',
    marginLeft: '20px',
    textAlign: 'left',
    width: '100%',
  },
  middlePara: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    margin: '0',
    textAlign: 'left',
    width: '100%',
  },
  leftText: {
    textAlign: 'left',
  },
  lastPara: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    margin: '0',
    paddingRight: '70px',
    width: '100%',
  },
  leftPad: {
    paddingLeft: '20px',
    margin: '0',
  },
  block: {
    display: 'block',
    height: '100%',
    margin: '0',
    textAlign: 'left',
    width: '100%',
  },
  flexContainer: {
    alignItems: 'center',
    display: 'flex',
    gridColumnStart: '5',
    paddingLeft: '20px',
  },
  arrow: {
    alignItems: 'center',
    background: `url(${dropdownArrow}) no-repeat`,
    display: 'flex',
    height: '14px',
    transform: 'rotate(-90deg)',
    width: '15px',
  },
};

const incompletePill = { ...style.pill, ...{ borderRadius: '0px', borderWidth: '0px 1px' } };

const getDisplayableItems = (creditMemo: CreditMemo): CreditMemoItem[] => creditMemo.items.filter((item) => item.display_name);
const refundPending = (creditMemo: CreditMemo): boolean => creditMemo.amount !== 0;
const receivePending = (creditMemo: CreditMemo): boolean => !!getDisplayableItems(creditMemo).length && creditMemo.items.some((i) => i.received_date === null);
const isIncomplete = (creditMemo: CreditMemo): boolean => refundPending(creditMemo) || receivePending(creditMemo);

const itemNames = (items: CreditMemoItem[]): JSX.Element | JSX.Element[] => {
  if (items.length === 0) {
    return (
      <p style={style.leftPad}>Refund</p>
    );
  }

  const flexPara = items.length > 1 ? { ...style.leftPad, ...style.block } : style.leftPad;
  return (
    items.map((item) => <p style={flexPara} key={item.work_order_item_id}>{item.display_name}</p>)
  );
};

const getSalesOrderLink = (customerId: string, salesOrderId: string): JSX.Element => {
  const salesOrderHref = `/customer/${customerId}/order/${salesOrderId}`;
  return (
    <Link href={salesOrderHref} style={style.link}>
      {salesOrderId}
    </Link>
  );
};

const getBrow = (href: string, creditMemo: CreditMemo): JSX.Element | null => {
  // One and only one of these will be true.
  let angryText = 'This return has not been received and a refund has not been issued.';
  if (!refundPending(creditMemo)) angryText = 'This return has not been received.';
  else if (!receivePending(creditMemo)) angryText = 'A refund has not been issued for this return.';

  return (
    <Link href={href} style={style.angryBrow}>
      <p style={style.browText}>{angryText}</p>
    </Link>
  );
};

const getChin = (customerId: string, creditMemo: CreditMemo): JSX.Element => {
  const label = getDisplayableItems(creditMemo).length ? 'Returned from order' : 'Related to order';
  return (
    <div style={style.chin}>
      <p style={style.browText}>
        {label}
        {' '}
        {getSalesOrderLink(customerId, creditMemo.sales_order_id)}
      </p>
    </div>
  );
};

interface CreditMemoPillProps {
  customerId: string;
  creditMemo: CreditMemo;
}

const CreditMemoPill: React.StatelessComponent<CreditMemoPillProps> = ({ customerId, creditMemo }) => {
  const href = `/customer/${customerId}/credit-memo/${creditMemo.id}`;
  const itemContainer = creditMemo && creditMemo.items && creditMemo.items.length > 1 ? style.leftText : style.middlePara;

  return (
    <div style={style.pillContainer}>
      {isIncomplete(creditMemo) && getBrow(href, creditMemo)}
      <Link href={href} style={isIncomplete(creditMemo) ? incompletePill : style.pill}>
        <p style={style.leftPara}>{creditMemo.id}</p>
        <div style={itemContainer}>{itemNames(getDisplayableItems(creditMemo))}</div>
        <p style={style.lastPara}>
          (
          {formatPrice(creditMemo.initial_amount)}
          )
        </p>
        <div style={style.flexContainer}>
          <span style={style.arrow} />
        </div>
      </Link>
      {getChin(customerId, creditMemo)}
    </div>
  );
};

export default CreditMemoPill;
