
import styled from 'react-emotion';

import colors from 'src/utils/colors';

export const StyledButton = styled('button')`
  margin-top: 2rem;

  border-radius: 99999px;

  width: 100%;
  height: 45px;

  padding: 1rem 2rem;
  line-height: 0.01rem;
  font-family: inherit;
  font-weight: 700;

  border: none;

  background-color: ${colors.darkBlue};
  color: ${colors.white};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${colors.blue};
  }

  &:active {
    background-color: ${colors.darkBlue};
  }

  &:disabled {
    background-color: ${colors.white};
    border: 1px solid ${colors.stone};
    color: ${colors.stone};
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
