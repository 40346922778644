import {
  AuthState, AuthAction, Actions, FETCH_JWT_SUCCESS,
} from 'types';

const initialState: AuthState = {
  jwt: '',
};

export const authReducer = (state = initialState, action: Actions): AuthState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_JWT_SUCCESS:
      return { ...state, jwt: payload as AuthAction };
    default:
      return state;
  }
};
