/* eslint-disable class-methods-use-this */
import {
  LogData, ILogger, LogLevel, LogMessage,
} from '../types';
import { getNewRelicAgent } from '../../../new-relic';
import parseErrorToJSON from '../utils/parseErrorToJSON';

export class NewRelicLogger implements ILogger {
  public debug(args: any, message: string): void {
    this.actionLog(LogLevel.DEBUG, message, args);
  }

  public error(args: any, message: string): void {
    this.errorLog(LogLevel.ERROR, message, args);
  }

  public info(args: any, message: string): void {
    this.actionLog(LogLevel.INFO, message, args);
  }

  public warn(args: any, message: string): void {
    this.errorLog(LogLevel.WARN, message, args);
  }

  private actionLog(type: LogLevel, message: LogMessage, data: LogData) {
    const agent = getNewRelicAgent();

    agent.addPageAction(type, {
      app: 'retail-order-management-app',
      data,
      message,
    });
  }

  private errorLog(type: LogLevel, message: LogMessage, data: LogData) {
    const agent = getNewRelicAgent();

    agent.noticeError(message || type, {
      app: 'retail-order-management-app',
      data: parseErrorToJSON(data),
      message,
      type,
    });
  }
}
