import { combineReducers } from 'redux';
import { authReducer } from './reducers/auth';
import { errorReducer } from './reducers/error';
import { apiReducer } from './reducers/api';
import { apiReducerWIP } from './reducers/api-wip';
import orderReducer from './order/reducer';
import lensReplacementReducer from './lens-replacement/reducer';

export const rootReducer = combineReducers({
  api: apiReducer,
  auth: authReducer,
  errors: errorReducer,
  apiWIP: apiReducerWIP,
  order: orderReducer,
  lensReplacement: lensReplacementReducer,
});
