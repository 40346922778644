import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  CSSStyle,
  Dispatch,
  Order,
  OrderItem,
  Params,
  PimItem,
  ReduxState,
} from 'types';
import { actions } from 'src/redux/actions';
import {
  currentUserHasFeature,
  currentUserHasPermission,
  formatDate,
  getOrder,
  getPimItems,
  selectPimItem,
  isShipping,
  selectIdFromRoute,
  getLensReplacementEligibleItems,
  getOrderStatus,
  isInUSCountry,
  checkIfIsReaderOrder,
} from 'selectors';
import { styles } from 'style';
import Link from 'src/components/app/Link';
import Loading from 'src/shared-components/screens/Loading';
import OrderDetail from 'src/components/order/OrderDetail';
import { CAN_SERVICE_GESTURE_FEATURE_FLAG } from 'src/utils/feature-flags';
import { SALES_ORDER_CANCEL_PERMISSION } from 'src/utils/user-permissions';
import { StatePrescriptions } from 'src/redux/lens-replacement/types';
import BookEyeExamPopover from '../lens-replacement/book-eye-exam-popover/BookEyeExamPopover';
import { PopoverContainerRef } from '../../shared-components/popover-modal';
import { createItemInvoiceReturnablePredicate, formatCancelHREF } from './util';
import LensReplacementButton from '../lens-replacement/lens-replacement-buttons/start-lens-replacement/LensReplacementButton';
import { browserHistory } from '../../../browserHistory';
import { getOrderData, isLensReplacementOrder as checkIfIsLensReplacement } from '../../redux/order/selectors';
import { formatParams } from '../app/routeUtils';
import { hasAllPrescriptionsExpired } from '../../utils/verifyPrescriptions';
import { ItemsLensReplacementEligibility, SalesOrderLensReplacementDetail } from '../../types/lens-replacement';
import { areAllItemsEligibleForFreeLR } from '../lens-replacement/utils';

const style: CSSStyle = {
  margin: {
    margin: '0 0 15px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};

const blueButton = { ...styles.button, ...style.margin };
const disabledButton = { ...styles.button, ...styles.disabledButton, ...style.margin };

const formatReturnButton = (params: {
  items: OrderItem[];
  order: Order;
  orderStatus: string;
  isLensReplacementEligible: boolean;
  hasInsurancePayment: boolean;
  isLensReplacementOrder: boolean;
  bookEyeExamPopover: React.MutableRefObject<PopoverContainerRef>;
  onClickLensReplacement: (onOpenModal: () => void, onRedirectToLensReplacement: () => void) => void;
}): JSX.Element => {
  const {
    items,
    order,
    orderStatus,
    hasInsurancePayment,
    isLensReplacementEligible,
    isLensReplacementOrder,
    bookEyeExamPopover,
    onClickLensReplacement,
  } = params;
  const hasReturnableItem = items && items.some(createItemInvoiceReturnablePredicate('authorized_return_quantity'));
  const hasReturnableItemLR = items && items.some(createItemInvoiceReturnablePredicate('received_return_quantity'));
  const returnText = 'Add a new refund';
  const exchangeText = 'Add a new exchange';
  const awaitingReturnStatus = 'Awaiting return';

  const returnItemsUrl = (orderId: number) => `/order/${orderId}/return-items/return`;
  const exchangeItemsUrl = (orderId: number) => `/order/${orderId}/return-items/exchange`;
  const lensReplacementUrl = (orderId: number, customerId: number) => `/customer/${customerId}/order/${orderId}/lens-replacement`;


  const onOpenBookEyeExamModal = () => {
    bookEyeExamPopover.current.openModal();
  };

  const onLensReplacement = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const uri = lensReplacementUrl(order.id, order.customer_id);

    browserHistory.push(`${uri}?${urlParams}`);
  };

  const refundButton = hasReturnableItem && !isLensReplacementOrder
    ? <Link href={returnItemsUrl(order.id)} style={blueButton}>{returnText}</Link>
    : <Link href="#" style={disabledButton}>{returnText}</Link>;

  const exchangeButton = hasReturnableItem && !hasInsurancePayment && !isLensReplacementOrder
    ? <Link href={exchangeItemsUrl(order.id)} style={blueButton}>{exchangeText}</Link>
    : <Link href="#" style={disabledButton}>{exchangeText}</Link>;

  const lrButton = hasReturnableItemLR && isLensReplacementEligible && !(orderStatus === awaitingReturnStatus)
    ? <LensReplacementButton onClick={() => onClickLensReplacement(onOpenBookEyeExamModal, onLensReplacement)} />
    : <LensReplacementButton isDisabled />;

  return (
    <div style={style.wrapper}>
      {refundButton}
      {exchangeButton}
      {lrButton}
    </div>
  );
};

interface OrderContainerProps {
  canCancel: boolean;
  canServiceGesture: boolean;
  canLensReplacement: boolean;
  dispatch: Dispatch;
  hasInsurancePayment: boolean
  jwt: string;
  order: Order;
  orderStatus: string;
  params: Params;
  pimItems: PimItem[];
  isLensReplacementEligible: boolean;
  lensReplacementDetails: SalesOrderLensReplacementDetail[];
  isLensReplacementOrder: boolean;
  prescriptions: StatePrescriptions;
  eligibleItems: ItemsLensReplacementEligibility[];
  isReaderOrder: boolean;
}

const OrderContainer: React.FC<OrderContainerProps> = ({
  canCancel,
  canServiceGesture,
  dispatch,
  jwt,
  order,
  orderStatus,
  params,
  pimItems,
  isLensReplacementEligible,
  lensReplacementDetails,
  canLensReplacement,
  hasInsurancePayment,
  isLensReplacementOrder,
  prescriptions,
  eligibleItems = [],
  isReaderOrder,
}) => {
  const bookEyeExamPopoverRef = useRef<PopoverContainerRef>(null);
  useEffect(actions.fetchOrder(dispatch, jwt, params), []);
  useEffect(actions.fetchPimItems(dispatch, jwt, order), [order]);
  useEffect(actions.fetchPimItemsDetailedProduct(dispatch, jwt, order?.items), [order?.items]);
  useEffect(actions.fetchPimConfigProducts(dispatch, jwt, order), [order]);
  useEffect(actions.fetchLensReplacementValidations(dispatch, order?.id, jwt, canLensReplacement), [order, canLensReplacement]);
  useEffect(actions.fetchLensReplacementDetailsBySalesOrder(dispatch, order?.id, jwt, canLensReplacement), [order, canLensReplacement]);
  useEffect(
    actions.fetchOriginalOrderItemsDetails(
      dispatch,
      lensReplacementDetails,
      jwt,
      canLensReplacement,
    ), [lensReplacementDetails, canLensReplacement],
  );
  useEffect(actions.fetchUserPrescriptionsByCustomerId(dispatch, order?.customer_id.toString()), [order]);
  useEffect(actions.fetchOrdersStatus(dispatch, jwt, params), []);
  const areItemsEligibleForFreeLR = areAllItemsEligibleForFreeLR(order?.items, eligibleItems);

  if (!order || !pimItems) {
    return <Loading />;
  }

  const onLensReplacement = (onOpenModal: () => void, onRedirectToLensReplacement: () => void) => {
    if (hasAllPrescriptionsExpired(prescriptions[order?.customer_id])) {
      onOpenModal();
      return;
    }

    onRedirectToLensReplacement();
  };

  const items = order.items && order.items.filter((i) => !isShipping(selectPimItem(pimItems, i)));
  const isCanceled = order.canceled;
  const orderPlaced = formatDate(order.placed);
  const canceledDate = formatDate(order.canceled);
  const cancelLink = formatCancelHREF(items, order);
  const returnButton = formatReturnButton({
    items,
    order,
    orderStatus,
    hasInsurancePayment,
    isLensReplacementEligible,
    isLensReplacementOrder,
    bookEyeExamPopover: bookEyeExamPopoverRef,
    onClickLensReplacement: onLensReplacement,
  });
  const showShippingDetails = false;
  const address = '55 NewAddress St. Brooklyn, NY 10001';
  const shipping = 'Standard Shipping';

  const onBookEyeExam = () => {
    bookEyeExamPopoverRef.current?.closeModal();
    const waitlistParams = 'redirectToExams=true';
    const waitlistUrl = `https://${process.env.WAITLIST_APP_DOMAIN}`;
    window.location.href = `${waitlistUrl}?${waitlistParams}`;
  };

  const dismissPopover = () => {
    bookEyeExamPopoverRef.current?.closeModal();
  };

  return (
    <>
      <BookEyeExamPopover
        ref={bookEyeExamPopoverRef}
        orderId={order.id}
        onBookEyeExam={onBookEyeExam}
        customerId={order.customer_id}
        itemsEligibleForFreeLR={areItemsEligibleForFreeLR}
        dismissPopover={dismissPopover}
        isReaderOrder={isReaderOrder}
      />
      <OrderDetail
        address={address}
        canCancel={canCancel}
        cancelLink={cancelLink}
        canceledDate={canceledDate}
        canServiceGesture={canServiceGesture}
        customerId={params.customer}
        isCanceled={isCanceled}
        items={items}
        pimItems={pimItems}
        order={order}
        orderPlaced={orderPlaced}
        returnButton={returnButton}
        shipping={shipping}
        showShippingDetails={showShippingDetails}
      />
    </>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const { lensReplacement: { lensReplacementDetails } } = state;
  const order = getOrder(state, params);
  const pimItems = getPimItems(state, order);
  const routeParams = formatParams(window.location.pathname) as Params;
  const orderId = selectIdFromRoute('order', routeParams);
  const orderStatus = getOrderStatus(state, order);
  const retailOrder = getOrderData(state, orderId);
  const canLensReplacement: boolean = isInUSCountry(state);
  const eligibleItems = getLensReplacementEligibleItems(state);
  const { prescriptions } = state.lensReplacement;
  const isReaderOrder = checkIfIsReaderOrder(retailOrder); 
  return {
    jwt: state.auth.jwt,
    order,
    orderStatus,
    pimItems,
    canServiceGesture: currentUserHasFeature(CAN_SERVICE_GESTURE_FEATURE_FLAG, state),
    canCancel: currentUserHasPermission(SALES_ORDER_CANCEL_PERMISSION, state),
    isLensReplacementEligible: eligibleItems.length > 0,
    lensReplacementDetails,
    canLensReplacement,
    hasInsurancePayment: !!retailOrder?.has_insurance_payment,
    isLensReplacementOrder: checkIfIsLensReplacement(retailOrder),
    prescriptions,
    eligibleItems,
    isReaderOrder,
  };
};

export default connect(select)(OrderContainer);
