const colors = {
  allports: '#006EA5',
  alto: '#D2D2D2',
  blue: '#00A2E1',
  blueHover: '#3DBDEE',
  charcoal: '#414B56',
  darkBlue: '#006EA5',
  gray: '#929292',
  grayChateau: '#A1A4A6',
  grayLight: '#f8f8f8',
  grayBorder: '#E4E6E8',
  apricot: '#e49c6d',
  red: '#D6003C',
  redHover: '#E4405D',
  redPressed: '#BE0035',
  silk: '#F8F8F8',
  silver: '#E1E5E6',
  silverChalice: '#A3A3A3',
  smoke: '#A1A4A6',
  stone: '#676F78',
  tundora: '#474747',
  white: '#FFFFFF',
  green: '#00BC37',
  orange: '#F2B600', // https://warbyparker.atlassian.net/wiki/spaces/research/pages/7487684638/Warby+Parker+colors
};

export default colors;
