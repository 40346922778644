import {
  UPDATE_API_STATE, CLEAR_API_STATE, REMOVE_API_STATE, APIState, Actions, APIAction,
} from 'types';

const initialState: APIState = {};

export const apiReducer = (state = initialState, action: Actions): APIState => {
  const { type, payload = {} } = action;
  const { path, body, error } = payload as APIAction;

  switch (type) {
    case UPDATE_API_STATE:
      return { ...state, [path]: { ...state[path], body, error } };
    case REMOVE_API_STATE:
      return { ...state, [path]: null };
    case CLEAR_API_STATE:
      return initialState;
    default:
      return state;
  }
};
