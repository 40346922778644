export const FREE_LR_ELIGIBILITY = 'FREE_LR_ELIGIBILITY';
export const PAID_LR_ELIGIBILITY = 'PAID_LR_ELIGIBILITY';

export const scratchGuarantee = {
  title: 'Scratch guarantee',
  description: `Select this option if the customer’s lenses are scratched or if the lens coating is peeling within the 6-month scratch guarantee policy. \n
  The customer must keep the same lens modifications but may change the prescription if a newer version is available.`,
  noEligibleDesc: 'The order exceeds the 6-month scratch guarantee window.',
};

export const paidLensReplacement = {
  title: 'Paid lens replacement',
  description: 'Select this option if the customer wants to update their lens modifications or prescription at no fault to Warby Parker or is past the 6-month scratch guarantee policy ',
};
