import { UserPrescription } from 'src/redux/lens-replacement/types';

export const isPlanoRx = (item: UserPrescription) => {
  const { od, os } = item;
  const isPlanoSph = (od.sph === 0 && os.sph === 0);
  const isCylAxisNull = (!od.cyl && !os.cyl && !od.axis && !os.axis);
  const areBooleansFalse =  !item.for_contacts && 
  !item.for_prism && 
  !item.for_progressives &&
  !item.for_readers &&
  !item.for_single_vision &&
  !item.has_pd;
  
  return isPlanoSph && isCylAxisNull && areBooleansFalse;
};

/**
 * Checks if all prescriptions in the array have values (i.e., are not null).
 * @param {Array} userPrescriptions - Array of userPrescriptions.
 * @returns {boolean} - Returns true if all prescriptions are non-null, false otherwise.
 */
export const areAllPrescriptionsValid = (userPrescriptions:UserPrescription[]) => userPrescriptions.every(prescription => prescription !== null);

