import React from 'react';
import { connect } from 'react-redux';

import { ReduxState } from 'types';
import { actions } from 'src/redux/actions';
import { browserHistory } from 'browserHistory';

import ErrorScreen from 'src/shared-components/screens/ErrorScreen';
import Router from 'src/components/app/Router';

class AuthenticationWrapper extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      location: browserHistory.location,
    };
  }

  static getDerivedStateFromProps(props) {
    const { jwt, dispatch, meRequested } = props;

    if (!jwt) {
      dispatch(actions.fetchJWT());
    }

    if (jwt && !meRequested) {
      const path = '/api/v1/user/me';
      dispatch(actions.fetchAPI(path, jwt));
    }

    return {};
  }

  componentDidMount() {
    this.unsubscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  unsubscribe = () => {
    browserHistory.listen((location) => this.setState({ location }));
  };

  public render() {
    const { jwt, errors } = this.props;
    const { location } = this.state;

    return (
      <>
        { errors && <ErrorScreen error={errors} info={null} nextURL={location.search} />}
        { jwt && <Router location={location} /> }
      </>
    );
  }
}

const select = (state: ReduxState) => ({
  jwt: state.auth.jwt,
  errors: state.errors.info,
  meRequested: !!state.api['/api/v1/user/me'],
});

export default connect(select)(AuthenticationWrapper);
