// copied template from POE
// TODO: move into a separate shared library

interface IOptions {
  host?: string;
  hostname?: string;
  pathname?: string;
  port?: string | number;
  protocol: string;
  query?: { [s: string]: string };
}

export default (options: Omit<IOptions, 'protocol'>): string => {
  const defaults: IOptions = {
    hostname: (window.location && window.location.hostname) || 'localhost',
    protocol: 'https:',
  };

  const { host, hostname, pathname, port, protocol, query }: IOptions = {
    ...defaults,
    ...options,
  };
  const dummyURL = 'https://.';
  const url = new window.URL(dummyURL);
  url.hostname = hostname || '';
  url.host = host || url.host;
  url.pathname = pathname || url.pathname;
  url.protocol = protocol as string;

  if (port) {
    url.port = String(port);
  }

  if (query) {
    const params = new window.URLSearchParams();
    for (const [key, val] of Object.entries(query)) {
      if (val !== null) {
        params.append(key, val as string);
      }
    }

    url.search = String(params);
  }

  return url.href;
};
