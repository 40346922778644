import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ReduxState, Order, Dispatch, Params, ReturnReasonType, OrderItem, PimItem, RETURN_ORDER_TYPE_IDS,
} from 'types';
import { actions } from 'src/redux/actions';
import {
  apiStatus, apiResponse, getOrder, getDescriptiveName, selectPimItem, getPimItems,
} from 'selectors';
import { styles } from 'style';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import SelectionPill from 'src/shared-components/order-actions/SelectionPill';
import Nav from 'src/shared-components/screens/Nav';
import Loading from 'src/shared-components/screens/Loading';
import convertReasonTypes from 'src/utils/convertReasonTypes';
import { getReturnReasonTypesDataPath } from './constants';

const getReturnReasonType = (returnReasonTypeId: string, returnReasonTypesData: ReturnReasonType[]): ReturnReasonType => returnReasonTypesData && returnReasonTypesData.find((r) => r.id === Number(returnReasonTypeId));

const configureReturnReasonType = (selectedReturnReasonType: ReturnReasonType, orderId: string, returnOrderType: string, itemIds: string): JSX.Element => {
  if (!selectedReturnReasonType) {
    return;
  }

  return (
    <EditableSelectionPill
      key={selectedReturnReasonType.id}
      subtext="Return Type"
      text={selectedReturnReasonType.name}
      href={`/order/${orderId}/return-items/${returnOrderType}/items/${itemIds}`}
    />
  );
};

const configureReturnReasons = (selectedReturnReasonType: ReturnReasonType, returnReasonTypeId: string, order: Order, returnOrderType: string, itemIds: string): JSX.Element | JSX.Element[] => {
  if (!selectedReturnReasonType || !order) {
    return <Loading />;
  }

  // format return data to sort by id in ascending order
  return selectedReturnReasonType.reasons.sort((a, b) => a.id - b.id).map(
    (reason) => (
      <SelectionPill
        key={reason.id}
        href={`/customer/${order.customer_id}/order/${order.id}/return-items/${returnOrderType}/items/${itemIds}/reason-type/${returnReasonTypeId}/reason/${reason.id}/confirmation`}
        text={reason.name}
      />
    ),
  );
};

interface ReturnReasonProps {
  dispatch: Dispatch;
  jwt: string;
  order: Order;
  pimItems: PimItem[];
  params: Params;
  returnReasonTypesDataPath: string;
  returnReasonTypesData: ReturnReasonType[];
}

const ReturnReasonSelected: React.StatelessComponent<ReturnReasonProps> = ({
  jwt,
  dispatch,
  order,
  pimItems,
  params,
  returnReasonTypesDataPath,
  returnReasonTypesData,
}) => {
  const orderId = params.order;
  const returnOrderType = params['return-items'];
  const itemIds = params.items;
  const returnReasonTypeId = params['reason-type'];

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);
  useEffect(actions.fetchPimItems(dispatch, jwt, order), [order]);

  useEffect(() => {
    if (jwt && !returnReasonTypesData) {
      dispatch(actions.fetchAPI(returnReasonTypesDataPath, jwt));
    }
  }, []);

  const selectedReturnReasonType = getReturnReasonType(returnReasonTypeId, returnReasonTypesData);
  const items = order && order.items && order.items.filter((item) => itemIds.includes(item.id));
  const href = order && `/order/${order.id}/return-items/${returnOrderType}/items/${itemIds}`;
  const returnReasonType = configureReturnReasonType(selectedReturnReasonType, orderId, returnOrderType, itemIds);
  const returnReasons = configureReturnReasons(selectedReturnReasonType, returnReasonTypeId, order, returnOrderType, itemIds);
  const changeItemsHref = `/order/${orderId}/return-items/${returnOrderType}`;

  return (
    <div className="page" style={styles.page}>
      <div className="content">
        <Nav
          href={href}
          text="Return items"
          subtext=""
        />
        {(!order || !pimItems) ? <Loading /> : (
          <>
            {
              items.map((i) => (
                <EditableSelectionPill
                  key={i.id}
                  subtext="Item for return"
                  text={getDescriptiveName(selectPimItem(pimItems, i))}
                  href={changeItemsHref}
                />
              ))
            }
            {returnReasonType}
            <p style={styles.paddedPara}>Return Reason</p>
            {returnReasons}
          </>
        )}
      </div>
    </div>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);
  const pimItems = getPimItems(state, order);

  const returnReasonTypesDataPath = order ? getReturnReasonTypesDataPath(order.id) : '';
  const returnReasonTypesDataStatus = apiStatus(state, returnReasonTypesDataPath);
  const returnReasonData = apiResponse(returnReasonTypesDataStatus);
  const returnReasonTypesData = convertReasonTypes(returnReasonData);

  return ({
    jwt: state.auth.jwt,
    order,
    pimItems,
    returnReasonTypesDataPath,
    returnReasonTypesData,
  });
};

export default connect(select)(ReturnReasonSelected);
