import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { styles } from 'style';
import {
  MeData,
  MeFacility,
  Params,
  ReturnReasonType,
  ReturnReason,
  ReturnParams,
  ReturnSummary,
  Order,
  OrderItem,
  Dispatch,
  ReduxState,
  PimItem,
  RETURN_ORDER_TYPE_IDS,
} from 'types';
import { actions } from 'src/redux/actions';
import {
  getOrder, getPimItems, selectPimItem, getDescriptiveName,
  apiStatus, apiResponse, apiLoading,
} from 'selectors';
import Loading from 'src/shared-components/screens/Loading';
import ReturnModal from 'src/components/return/ReturnModal';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import Nav from 'src/shared-components/screens/Nav';
import convertReasonTypes from 'src/utils/convertReasonTypes';
import { getReturnReasonTypesDataPath } from './constants';

const RETURN_ACTION_IDS = {
  exchange_all: 1,
  refund_all: 2,
  refund_partial: 3,
  refund_original: 4,
  no_action: 5,
  lens_replacement: 6,
};

const PARTY_IDS = {
  US: 1,
  CA: 2,
};

const salesOrderReturnAddPath = '/api/v1/sales-order-return/add';

const getReturnBody = (
  items: OrderItem[],
  order: Order,
  returnReason: ReturnReason,
  facility: MeFacility,
  returnOrderType: string,
): string => {
  const formattedItems = items.map((item) => {
    const validInvoice = item.invoices.find((invoice) => {
      if (!invoice.returnable) return false;
      if (invoice.authorized_return_quantity === null) return true;
      return (invoice.quantity > invoice.authorized_return_quantity);
    }) || { invoice_id: null, invoice_item_id: null };
    return {
      invoice_id: validInvoice.invoice_id,
      item_id: validInvoice.invoice_item_id,
      pc_product_id: item.pc_product_id,
      pc_version_id: null,
      product_id: null,
      quantity: 1,
      variant_id: null,
    };
  });

  const totalOrderItems = order.items.map((i) => i.quantity).reduce((a, b) => a + b, 0);
  const isPartialReturn = items.length < totalOrderItems;

  let return_action = isPartialReturn ? 'refund_partial' : 'refund_all';
  if (returnOrderType === 'exchange') return_action = 'exchange_all';

  return JSON.stringify({
    facility_id: facility.facility_id,
    issue_credit: true,
    items: formattedItems,
    memo: null,
    order_type_id: RETURN_ORDER_TYPE_IDS[returnOrderType],
    party_id: PARTY_IDS[facility.locale],
    return_action,
    return_action_id: RETURN_ACTION_IDS[return_action],
    return_reason_id: returnReason.id,
    sales_order_id: Number(order.id),
  });
};

const getReturnReasonType = (returnReasonTypeId: string, returnReasonTypesData: ReturnReasonType[]): ReturnReasonType => returnReasonTypesData && returnReasonTypesData.find((r) => r.id === Number(returnReasonTypeId));

const getReturnReason = (returnReasonId: string, returnReasonType: ReturnReasonType): ReturnReason => returnReasonType && returnReasonType.reasons.find((r) => r.id === Number(returnReasonId));

const configureButtonText = (items: OrderItem[], returnRequested: boolean, returnOrderType: string): string => {
  if (returnRequested) {
    return 'Loading...';
  }

  if (returnOrderType === 'exchange') {
    return items.length > 1 ? 'Yes, exchange items' : 'Yes, exchange item';
  }

  return items.length > 1 ? 'Yes, return items' : 'Yes, return item';
};

interface ReturnConfirmationProps {
  dispatch: Dispatch;
  jwt: string;
  meData: MeData;
  params: Params;
  href: string;
  items: OrderItem[];
  returnOrderType: string;
  itemIds: string;
  order: Order;
  pimItems: PimItem[];
  orderId: string;
  returnReasonTypesDataPath: string;
  returnReasonTypesData: ReturnReasonType[];
  returnRequested: boolean;
  returnParams: ReturnParams;
  returnSummary: ReturnSummary;
}

const ReturnConfirmation: React.StatelessComponent<ReturnConfirmationProps> = ({
  dispatch,
  meData,
  jwt,
  order,
  pimItems,
  params,
  returnReasonTypesDataPath,
  returnReasonTypesData,
  returnRequested,
  returnSummary,
}) => {
  const customerId = params.customer;
  const orderId = params.order;
  const returnOrderType = params['return-items'];
  const itemIds = params.items;
  const returnId = params['reason-type'];
  const returnReasonTypeId = params['reason-type'];
  const returnReasonId = params.reason;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // reset api state of '/api/v1/sales-order-return/add'
    dispatch(actions.removeAPIState(salesOrderReturnAddPath));

    if (jwt && !meData) {
      const mePath = '/api/v1/user/me';
      dispatch(actions.fetchAuthenticatedAPI(mePath));
    }

    if (jwt && !returnReasonTypesData) {
      dispatch(actions.fetchAPI(returnReasonTypesDataPath, jwt));
    }
  }, []);

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);
  useEffect(actions.fetchPimItems(dispatch, jwt, order), [order]);

  useEffect(() => {
    if (showModal && returnSummary && returnSummary.error) {
      setShowModal(false);
    }
  }, [returnSummary]);

  if (!order || !returnReasonTypesData || !meData || !pimItems) {
    return <Loading />;
  }

  const href = `/order/${order.id}/return-items/${returnOrderType}/items/${itemIds}/reason-type/${returnId}`;
  const items = order.items && order.items.filter((item) => itemIds.includes(item.id));

  const returnReasonType = getReturnReasonType(returnReasonTypeId, returnReasonTypesData);
  const returnReason = getReturnReason(returnReasonId, returnReasonType);

  const returnReasonTypeHref = `/order/${orderId}/return-items/${returnOrderType}/items/${itemIds}`;
  const returnReasonHref = `/order/${orderId}/return-items/${returnOrderType}/items/${itemIds}/reason-type/${returnReasonType.id}`;

  const changeItemsHref = `/order/${orderId}/return-items/${returnOrderType}`;

  return (
    <div className="page" style={styles.page}>
      <div className="content">
        <Nav
          href={href}
          text="Return items"
          subtext=""
        />
        {
          returnSummary && returnSummary.error
          && (
          <div style={styles.orangePill}>
            <p style={styles.boldPara}>
              Error:
              {returnSummary.error.message}
            </p>
          </div>
          )
        }
        {
          items.map((i) => (
            <EditableSelectionPill
              key={i.id}
              subtext="Item for return"
              text={getDescriptiveName(selectPimItem(pimItems, i))}
              href={changeItemsHref}
            />
          ))
        }
        <EditableSelectionPill
          subtext="Return Type"
          text={returnReasonType.name}
          href={returnReasonTypeHref}
        />
        <EditableSelectionPill
          subtext="Return Type Reason"
          text={returnReason.name}
          href={returnReasonHref}
        />
        <p style={styles.paddedPara}>Are you sure you want to return this order?</p>
        <button style={styles.button} onClick={() => setShowModal(true)}>
          {configureButtonText(items, returnRequested, returnOrderType)}
        </button>
        {
          showModal && (
            <ReturnModal
              jwt={jwt}
              dispatch={dispatch}
              customerId={customerId}
              returnOrderType={returnOrderType}
              returnBody={getReturnBody(items, order, returnReason, meData.facility, returnOrderType)}
              disabled={returnRequested}
              closeClick={() => setShowModal(false)}
            />
          )
        }
      </div>
    </div>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);
  const pimItems = getPimItems(state, order);

  const returnReasonTypesDataPath = order ? getReturnReasonTypesDataPath(order.id) : '';
  const returnReasonTypesDataStatus = apiStatus(state, returnReasonTypesDataPath);
  const returnReasonData = apiResponse(returnReasonTypesDataStatus);
  const returnReasonTypesData = convertReasonTypes(returnReasonData);

  const mePath = '/api/v1/user/me';
  const meDataStatus = apiStatus(state, mePath);
  const meData = apiResponse(meDataStatus);

  const returnPath = '/api/v1/sales-order-return/add';
  const returnStatus = apiStatus(state, returnPath);
  const returnSummary = apiResponse(returnStatus);

  return ({
    jwt: state.auth.jwt,
    meData,
    order,
    pimItems,
    returnReasonTypesDataPath,
    returnReasonTypesData,
    returnRequested: apiLoading(returnStatus) || returnSummary,
    returnSummary,
  });
};

export default connect(select)(ReturnConfirmation);
