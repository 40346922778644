/* eslint-disable no-underscore-dangle */
import { InsuranceBreakdown, ReduxState } from '../../../types';
import { OrderId, RetailOrder } from './types';

export const getOrderData = (state: ReduxState, orderId: OrderId): RetailOrder | null =>
  state.order?.orders?.[orderId] || null;

export const getInsuranceBreakdown = (orderData: RetailOrder | null): InsuranceBreakdown => {
  if (!orderData || !orderData.insurance) return null;

  const totalCopayAmount = orderData?.insurance._insurance_eligibility_items.reduce((accumulator, item) => accumulator + item.copay_amount, 0);
  return { benefit_amount: orderData?.insurance.benefit_amount, copay_amount: totalCopayAmount };
};

export const isLensReplacementOrder = (orderData: RetailOrder | null): boolean => {
  if (!orderData || !orderData.items) return false;

  return orderData.items.some(
    ({ attributes }) => (
      attributes.true_lens_replacement &&
      attributes.true_lens_replacement.original_config_pc_product_id &&
      attributes.true_lens_replacement.original_sales_order_id
    ));
};
