import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  ReduxState, Order, Dispatch, Params,
} from 'types';
import { actions } from 'src/redux/actions';
import { getOrder } from 'selectors';

import OrderContainer from 'src/components/order/OrderContainer';
import Modal from 'src/shared-components/screens/Modal';

interface CancelWarningProps {
  dispatch: Dispatch;
  jwt: string;
  order: Order;
  params: Params;
}

const CancelWarning: React.StatelessComponent<CancelWarningProps> = ({
  order, dispatch, jwt, params,
}) => {
  useEffect(actions.fetchOrder(dispatch, jwt, params), []);

  const orderId = params.order;

  return (
    <>
      <OrderContainer params={params} />
      {order && (
      <Modal
        showCloseButton
        showCheckmark={false}
        closeURL={`/customer/${order.customer_id}/order/${orderId}`}
        confirmationHeader=""
        header="Are you sure you want to cancel?"
        subtext="Canceling will affect all items in this order."
        buttonText="Yes, cancel both items"
        nextURL={`/order/${orderId}/cancel`}
      />
      )}
    </>
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);

  return ({
    jwt: state.auth.jwt,
    order,
  });
};

export default connect(select)(CancelWarning);
