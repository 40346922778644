import OhmClient, { Service as OhmService } from '@warbyparker/ohmsdk';
import { Dispatch } from 'redux';

import {
  ThunkResult, GenericAction, Responses, Endpoint, EndpointParam, UPDATE_API_STATE_WIP,
} from 'types-wip';
import { OHM_RETAIL_PATHS } from 'selectors';

export const apiFactory = (endpoint: Endpoint, jwt: string, ...params: [EndpointParam]): ThunkResult<Promise<GenericAction>> => {
  const path = (OHM_RETAIL_PATHS[endpoint](...params));
  return apiCall(path, endpoint, jwt, params);
};

const apiCall = (path: string, endpoint: Endpoint, jwt, params) => (
  async (dispatch: Dispatch<GenericAction>) => {
    const client = new OhmClient(OhmService.RETAIL, jwt);
    const response = await client.get<Responses>(path);
    const body = response.data;

    return dispatch({
      type: UPDATE_API_STATE_WIP,
      endpoint,
      payload: {
        body,
        error: response.status > 399,
        params,
      },
    });
  }
);
