import styled from 'react-emotion';
import colors from 'src/utils/colors';

export const Container = styled('div')`
  border: 1px solid ${colors.silver};
  top: 0;
  left: 0;
  right: 0;
  width: 700px;
  max-height: 210px;
  margin: auto;
  margin-top: 15px;
  border-radius: 8px;
  background: white;
  overflow: hidden;
  z-index: 15;
`;

export const Section = styled('section')`
  flex: 1;
  padding: 0;
  margin: 15px;
  text-align: left;
`;

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  padding: 0;
`;

export const Description = styled('p')`
  color: ${colors.stone};
  font-size: 18px;
  font-weight: 500;
  max-height: 70px;
  margin-bottom: 24px;
  text-align: justify;
`;

export const ButtonWrapper = styled('div')`
  width: 640px;
  display: flex;
  justify-content: center;
  padding: 1px;
  font-family: proxima-nova, utopia-std, sans-serif;
`;

const sharedButtonStyles = `
  margin-bottom: 0;
  border-radius: 50px;
  width: 100%;
  height: 45px;
  padding: 15px 30px;
  line-height: 0.15px;
  font-family: inherit;
  font-weight: 700;
`;

export const DisableButton = styled('button')`
  ${sharedButtonStyles}
  border: 1px solid ${colors.grayChateau};
  background-color: ${colors.white};
  color: ${colors.grayChateau};
  &:hover {
    background-color: ${colors.white};
  }
  &:active {
    background-color: ${colors.white};
  }
`;

export const Button = styled('button')`
  ${sharedButtonStyles}
  border: none;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.blue};
  }
  &:active {
    background-color: ${colors.darkBlue};
  }

  &:disabled {
    opacity: 0.6;
    background-color: ${colors.darkBlue};
  }
`;

export const TagButton = styled('button')`
  margin-bottom: 0;
  border-radius: 50px;
  font-family: inherit;
  font-weight: 600;
  width: 150px;
  height: 35px;
  color: ${colors.white};
  background-color: ${colors.darkBlue};
  border: none;
  transition: background-color 0.3s ease;
  margin-left: auto;
  text-align: center;

  &:hover {
    background-color: ${colors.blue};
  }
`;
