import { UPDATE_API_STATE_WIP, WIPState, Endpoint, Actions } from 'types-wip';

const initialState: WIPState = {
  [Endpoint.SalesOrders]: {},
  [Endpoint.CreditMemos]: {},
  [Endpoint.CreditMemo]: {},
};

export const apiReducerWIP = (state = initialState, action: Actions): WIPState => {
  const { type, payload, endpoint } = action;

  switch (type) {
    case UPDATE_API_STATE_WIP:
      const { body, error, params } = payload;
      return {
        ...state,
        [endpoint]: {
          ...state[endpoint],
          [params]: {
            error,
            body,
          },
        },
      };
    default:
      return state;
  }
};
