import React, { FC } from 'react';
import { pdToString } from 'src/utils/pd';
import { CellColumn, CellTitle, CellValue, Pill, Table } from './grid';
import { UserPrescription } from '../../redux/lens-replacement/types';

const PrescriptionTable: FC<{ data: UserPrescription }> = ({ data }) => {
  const details = data;

  const isForProgressives = details?.for_progressives || details?.isMultiFocal;
  const od = details?.od;
  const os = details?.os;
  const pd = details?.pd;

  const pdString = pd ? pdToString(pd) : '';

  return (
    <Pill>
      <Table>
        <tbody>
          <tr>
            <CellTitle>&nbsp;</CellTitle>
            <CellTitle>SPH</CellTitle>
            <CellTitle>CYL</CellTitle>
            <CellTitle>AXIS</CellTitle>
            {isForProgressives && <CellTitle>ADD</CellTitle>}
            <CellTitle>PD</CellTitle>
          </tr>
          <tr>
            <CellColumn> Right Eye (OD)</CellColumn>
            <CellValue key="od-sph">{od.sph}</CellValue>
            <CellValue key="od-cyl">{od.cyl}</CellValue>
            <CellValue key="od-axis">{od.axis}</CellValue>
            {isForProgressives && <CellValue key="od-add">{od.add}</CellValue>}
            <CellValue rowSpan={2}>{pdString}</CellValue>
          </tr>
          <tr>
            <CellColumn>Left Eye (OS)</CellColumn>
            <CellValue key="os-sph">{os.sph}</CellValue>
            <CellValue key="os-cyl">{os.cyl}</CellValue>
            <CellValue key="os-axis">{os.axis}</CellValue>
            {isForProgressives && <CellValue key="os-add">{os.add}</CellValue>}
          </tr>
        </tbody>
      </Table>
    </Pill>
  );
};

export default PrescriptionTable;
