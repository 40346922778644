import { CSSStyle } from 'types';
import styled from 'react-emotion';
import dropdownArrow from 'src/shared-components/icons/dropdown-arrow.svg';
import colors from 'src/utils/colors';
import { sharedButtonStyles } from '../popover-modal/styles';

export const cssStyle: CSSStyle = {
  arrow: {
    alignItems: 'center',
    background: `url(${dropdownArrow}) no-repeat`,
    display: 'flex',
    height: '14px',
    width: '15px',
    margin: '5px 4px 0px',
  },
  arrowUp: {
    alignItems: 'center',
    background: `url(${dropdownArrow}) no-repeat`,
    display: 'flex',
    height: '14px',
    transform: 'rotate(180deg)',
    width: '15px',
    margin: '0px 4px',
  },
};

export const Wrapper = styled('li')<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
  border: '1px solid #e4e6e8',
  backgroundColor: isDisabled ? '#f1f1f1' : 'white',
  borderRadius: 8,
  justifyContent: 'space-between',
  textDecoration: 'none',
  padding: '20px 30px',
  margin: '5px 0px',
}));

export const RxLink = styled('a')({
  textDecoration: 'none',
  textAlign: 'center',
  color: '#0089bf',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gridColumn: 'span 1',
});

export const RadioButton = styled('div')<{ isDisabled: boolean }>(({ isDisabled }) => ({
  padding: '5px',
  gridColumn: 'span 1',
  pointerEvents: isDisabled ? 'none' : 'auto',
  cursor: isDisabled ? 'not-allowed' : 'pointer',
}));

export const RxDescription = styled('button')({
  padding: '15px 36px',
  background: 'none',
  border: 'none',
  textAlign: 'left',
  cursor: 'pointer',
  display: 'inline-grid',
  width: '100%',
  justifyContent: ' flex-start',
  gridColumn: 'span 1',
});

export const RxTitle = styled('span')<{ centered?: boolean }>((args) => {
  const { centered } = args;
  return {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    color: '#414b56',
    fontWeight: 600,
    justifyContent: centered ? 'center' : 'space-between',
  };
});

export const RxSubtitle = styled('span')({
  color: '#676f78',
  fontWeight: 400,
  margin: '5px 0px',
});

export const SwapButton = styled('button')`
  ${sharedButtonStyles}
  width: 100%;
  grid-column: 1 / span 3;

  &:hover {
    background-color: ${colors.blue};
  }

  &:active {
    background-color: ${colors.darkBlue};
  }
`;
