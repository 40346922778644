
import styled from 'react-emotion';

export const CenteredImage = styled('img')`
  display: block;
  margin: 10px auto; 
  width: 50%; 
  padding: 20px;
`;


export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center; 
  align-items: center;
  gap: 1rem; 
  margin-top: 1rem; 
`;

