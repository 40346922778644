import { applyMiddleware, compose, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { rootReducer } from 'src/redux/index';
import { ReduxState } from 'types';
import { Actions } from 'types-wip';

export default function configureStore() {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    composeEnhancer(
      applyMiddleware(
        thunk as ThunkMiddleware<ReduxState, Actions>,
      ),
    ),
  );
  return store;
}
