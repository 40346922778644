import styled from 'react-emotion';
import colors from '../../utils/colors';

export const Background = styled('div')`
  position: fixed;
  display: flex;
  align-items: center;
  background: rgba(0, 36, 84, 0.8);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
`;

export const Content = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 485px;
  height: auto;
  margin: auto;
  margin-top: 15%;
  border-radius: 8px;
  background: white;
  overflow: hidden;
  z-index: 15;
`;

export const Section = styled('section')`
  flex: 1;
  padding: 0;
  margin: 30px;
  text-align: left;
`;

export const Header = styled('h1')`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

export const Description = styled('p')`
  color: ${colors.charcoal};
  font-size: 18px;
  font-weight: 150;
  height: auto;
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  padding: 1px;
  font-family: proxima-nova, utopia-std, sans-serif;
`;

export const CancelButton = styled('button')`
  margin-bottom: 0;
  border-radius: 50px;
  width: 195px;
  height: 45px;
  padding: 15px 30px;
  background: white;
  border: none;
  color: ${colors.stone};
  border: 1px solid ${colors.grayChateau};
  margin-right: 10px;
  transition: all 0.2s ease;
  line-height: 0.15px;
  font-family: inherit;
  font-weight: 700;

  &:hover {
    background-color: ${colors.darkBlue};
  }

  &:active {
    background-color: ${colors.darkBlue};
  }

  &:hover,
  &:active {
    color: ${colors.white};
  }
`;
export const sharedButtonStyles = `
  margin-bottom: 0;
  border-radius: 50px;
  width: 195px;
  height: 45px;
  padding: 15px 30px;
  background-color: ${colors.darkBlue};
  border: none;
  color: ${colors.white};
  margin-right: 10px;
  transition: background-color 0.3s ease;
  line-height: 0.15px;
  font-family: inherit;
  font-weight: 700;
`;

export const ContinueButton = styled('button')`
  ${sharedButtonStyles}
  &:hover {
    background-color: ${colors.blue};
  }

  &:active {
    background-color: ${colors.darkBlue};
  }
`;
