/* eslint-disable import/no-cycle */
import createContext from 'src/utils/createContext';
import { PrescriptionStepperContextData, PrescriptionStepData } from './types';

export const [PrescriptionStepperContextProvider, usePrescriptionStepperContext] = createContext<
PrescriptionStepperContextData<PrescriptionStepData>
>(
  {
    data: {
      selectedPrescriptions: [],
    },
    updateData: () => {},
    nextStep: () => {},
    previousStep: () => {},
    stepIdx: 0,
    currentItem: null,
    isLastItem: false,
    currentPrescription: null,
    setAllPrescriptions: ()=>{},
  },
  'PrescriptionStepper',
);
