import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  CSSStyle,
  Dispatch,
  Order,
  OrderItem,
  Params,
  ReduxState,
  CreditMemo,
  CreditMemoTransaction,
  ReturnReason,
  CreditMemoItem,
  PimItem,
  RxDetails,
} from 'types';

import { runReturn, getReturnReceiptPath, ReturnRunnerSteps } from 'src/components/return/ReturnRunner';
import { Endpoint } from 'types-wip';
import { apiFactory } from 'src/redux/actions-wip';

import { styles } from 'style';
import {
  selectIdFromRoute,
  formatPrice,
  apiStatus,
  apiWipStatus,
  apiResponse,
  formatDate,
  selectPimItem,
  getOrder,
  getPimItems,
} from 'selectors';
import { actions } from 'src/redux/actions';

import Link from 'src/components/app/Link';
import CustomerDetail from 'src/shared-components/screens/CustomerDetail';
import Loading from 'src/shared-components/screens/Loading';
import Nav from 'src/shared-components/screens/Nav';
import PimProductCard from 'src/shared-components/order-actions/PimProductCard';
import RefundForm from 'src/components/credit-memo/RefundForm';
import { formatParams } from '../app/routeUtils';
import { getRxDetails } from '../order/OrderDetail';
import RxSummary from '../../shared-components/screens/RxSummary';
import { getOrderData } from '../../redux/order/selectors';
import { fetchRetailOrder } from '../../redux/order/thunks';
import { RetailOrder } from '../../redux/order/types';

const style: CSSStyle = {
  fixed: {
    display: 'flex',
    alignItems: 'center',
    bottom: '0',
    background: 'white',
    left: '0',
    margin: '0 auto',
    maxWidth: '768px',
    padding: '0 30px',
    position: 'fixed',
    right: '0',
    zIndex: 2,
  },
  link: {
    color: '#0089BF',
    fontSize: '18px',
  },
  orange: {
    color: '#D55C12',
  },
  leftButton: {
    margin: '0px 2.5px 15px 0px',
  },
  rightButton: {
    margin: '0px 0px 15px 2.5px',
  },
  wideButton: {
    margin: '0px 0px 15px',
  },
};

const getReturnedItems = (creditMemo: CreditMemo, salesOrder: Order): OrderItem[] => {
  const returnedSalesOrderItemIds = creditMemo.items.map((i) => i.sales_order_item_id);
  return salesOrder.items.filter((i: OrderItem) =>
    returnedSalesOrderItemIds.some((r) => r === i.id),
  );
};

const getDisplayableItems = (creditMemo: CreditMemo): CreditMemoItem[] => creditMemo.items.filter((item) => item.display_name);
const refundPending = (creditMemo: CreditMemo): boolean => creditMemo.amount !== 0;
const receivePending = (creditMemo: CreditMemo): boolean =>
  !!getDisplayableItems(creditMemo).length &&
  creditMemo.items.some((i) => i.received_date === null);

enum PaymentType {
  CASH = 'cash',
  CHECK = 'check',
  CREDIT_CARD = 'credit_card',
  SAVED_CREDIT_CARD = 'saved_credit_card',
  GIFT_CARD = 'gift_card',
  CREDIT_MEMO = 'credit_memo',
  AFFIRM_LOAN = 'affirm_loan',
  FACEBOOK = 'facebook',
}

export const getPaymentPreview = (transaction: CreditMemoTransaction): JSX.Element => {
  const {
    transaction_amount: transactionAmount,
    payment_info: paymentInfo,
    transaction_id: transactionId,
  } = transaction;
  const paymentAmount = formatPrice(transactionAmount);
  const paymentType = paymentInfo ? paymentInfo?.payment_type : '';
  let body;
  switch (paymentType) {
    case PaymentType.CASH:
      body = `Cash — (${paymentAmount})`;
      break;
    case PaymentType.CHECK:
      body = `Check — (${paymentAmount})`;
      break;
    case PaymentType.CREDIT_CARD:
    case PaymentType.SAVED_CREDIT_CARD:
      body = `${paymentInfo.card_type} ending in ${paymentInfo.last4} — (${paymentAmount})`;
      break;
    case PaymentType.GIFT_CARD:
      body = `Gift card ${paymentInfo.gift_card_code} — (${paymentAmount})`;
      break;
    case PaymentType.CREDIT_MEMO:
      body = `Credit memo ${paymentInfo.credit_memo_id} — (${paymentAmount})`;
      break;
    case PaymentType.AFFIRM_LOAN:
      body = `Affirm loan ${paymentInfo.charge_id} — (${paymentAmount})`;
      break;
    case PaymentType.FACEBOOK:
      body = `Facebook payment ${paymentInfo.charge_id} — (${paymentAmount})`;
      break;
    default:
      body = `Refund — (${paymentAmount})`;
  }

  return (
    <div style={styles.itemPill} key={transactionId}>
      <span>
        <p style={styles.microPara}>Refund Details</p>
        <p style={styles.boldPara}>{body}</p>
      </span>
    </div>
  );
};

const getSalesOrderLink = (customerId: string, salesOrderId: string): JSX.Element => {
  const salesOrderHref = `/customer/${customerId}/order/${salesOrderId}`;
  return (
    <Link href={salesOrderHref} style={style.link}>
      {salesOrderId}
    </Link>
  );
};

const getSalesOrderPill = (creditMemo: CreditMemo, customerId: string): JSX.Element => {
  const label = creditMemo.items.length ? 'Returned from order' : 'Related to order';
  return (
    <div style={styles.itemPill}>
      <span style={styles.para}>
        {label}
        {' '}
        {getSalesOrderLink(customerId, creditMemo.sales_order_id)}
      </span>
    </div>
  );
};

const getReturnIdPill = (creditMemo: CreditMemo): JSX.Element =>
  creditMemo.sales_order_return_id && (
    <div style={styles.itemPill}>
      <span style={styles.para}>
        Return
        {creditMemo.sales_order_return_id}
      </span>
    </div>
  );

const orangePara = { ...styles.boldPara, ...style.orange };
const disabledButton = { ...styles.button, ...style.wideButton, ...styles.disabledButton };
const leftButton = { ...styles.button, ...styles.disabledButton, ...style.leftButton };
const rightButton = { ...styles.button, ...styles.disabledButton, ...style.rightButton };

const receivePendingPill = (
  <div style={styles.orangePill}>
    <span style={orangePara}>This return has not been received.</span>
  </div>
);

const getReceiveLink = (
  creditMemo: CreditMemo,
  customerId: string,
  jwt: string,
  dispatch: Dispatch,
  loading: boolean,
): JSX.Element => {
  const receiveNowSteps: ReturnRunnerSteps = {
    receiveReturn: true,
    refreshCreditMemo: true,
  };

  if (loading) {
    return <div style={disabledButton}>Receive Return</div>;
  }

  return (
    <div
      onClick={() => runReturn(null, creditMemo.id, customerId, jwt, dispatch, receiveNowSteps)}
      style={{ ...styles.button, ...style.wideButton }}
    >
      Receive Return
    </div>
  );
};

const refundPendingPill = (
  <div style={styles.orangePill}>
    <span style={orangePara}>A refund for this return has not been issued.</span>
  </div>
);

const printLinks = (
  <div style={style.fixed}>
    <button style={leftButton}>Print UPC</button>
    <button style={rightButton}>Print Insert</button>
  </div>
);

interface CreditMemoDetailProps {
  dispatch: Dispatch;
  returnReasonsDataPath: string;
  returnReasonsData: ReturnReason[];
  jwt: string;
  customerId: string;
  creditMemo: CreditMemo;
  orderParams: Params;
  salesOrder: Order;
  pimItems: PimItem[];
  params: Params;
  returnReceiptLoading: boolean;
  retailOrder: RetailOrder;
  rxDetails: RxDetails;
}

const CreditMemoDetail: React.StatelessComponent<CreditMemoDetailProps> = ({
  dispatch,
  returnReasonsDataPath,
  returnReasonsData,
  jwt,
  customerId,
  orderParams,
  salesOrder,
  pimItems,
  creditMemo,
  params,
  returnReceiptLoading,
  retailOrder,
  rxDetails,
}) => {
  useEffect(() => {
    if (jwt && !creditMemo) {
      const creditMemoId = params['credit-memo'];
      dispatch(apiFactory(Endpoint.CreditMemo, jwt, creditMemoId));
    }

    if (jwt && !returnReasonsData) {
      dispatch(actions.fetchOhmAPI(returnReasonsDataPath, jwt));
    }
  }, []);

  useEffect(actions.fetchOrder(dispatch, jwt, orderParams), [creditMemo]);
  useEffect(actions.fetchPimItems(dispatch, jwt, salesOrder), [salesOrder]);

  useEffect(() => {
    if (!retailOrder && creditMemo?.sales_order_id) {
      dispatch(fetchRetailOrder(customerId, creditMemo?.sales_order_id));
    }
  }, [creditMemo?.sales_order_id]);

  if (!creditMemo) {
    return <Loading />;
  }

  const refundTransactions = creditMemo.transactions.filter(
    (t) => t.transaction_type === 'refund_origin' || t.transaction_type === 'refund_payment',
  );
  const returnedItems = salesOrder ? getReturnedItems(creditMemo, salesOrder) : [];
  const previousHref = `/customer/${params.customer}`;
  const returnReason =
    returnReasonsData &&
    returnReasonsData.find((r) => r.id === creditMemo.sales_order_return_reason);
  const returnReasonText = returnReason
    ? `${returnReason.reason_type.name} - ${returnReason.name}`
    : 'Returned';

  return (
    <div className="page" style={styles.page}>
      <div className="content">
        <Nav
          href={previousHref}
          text={`Credit memo ${creditMemo.id}`}
          subtext={`Issued on ${formatDate(creditMemo.created)}`}
        />
        {receivePending(creditMemo) && receivePendingPill}
        {receivePending(creditMemo) && getReceiveLink(creditMemo, customerId, jwt, dispatch, returnReceiptLoading)}
        {refundPending(creditMemo) && refundPendingPill}
        {refundPending(creditMemo) && <RefundForm creditMemo={creditMemo} />}
        {getSalesOrderPill(creditMemo, customerId)}
        {getReturnIdPill(creditMemo)}
        {salesOrder && <CustomerDetail order={salesOrder} />}
        {refundTransactions.map((t) => getPaymentPreview(t))}
        {
          returnedItems.map((item) => selectPimItem(pimItems, item) && (
            <React.Fragment key={item.id}>
              <PimProductCard
                key={item.id}
                total={-1 * item.total}
                pimItem={selectPimItem(pimItems, item)}
                returnReason={returnReasonText}
                rxName={rxDetails?.name}
              />
              <RxSummary rxDetails={rxDetails} />
            </React.Fragment>
          ),
          )
        }
        {printLinks}
      </div>
    </div>
  );
};

const select = (state: ReduxState) => {
  const routeParams = formatParams(window.location.pathname);
  const creditMemoId = selectIdFromRoute('credit-memo', routeParams);
  const customerId = selectIdFromRoute('customer', routeParams);

  const returnReasonsDataPath = '/v1/sales-order-returns/reasons';
  const returnReasonsDataStatus = apiStatus(state, returnReasonsDataPath);
  const returnReasonsData = apiResponse(returnReasonsDataStatus);

  const creditMemoStatus = apiWipStatus(state, Endpoint.CreditMemo);
  const creditMemo = creditMemoStatus[creditMemoId] && creditMemoStatus[creditMemoId].body[0];

  const orderParams = creditMemo && { order: creditMemo?.sales_order_id };
  const salesOrder = getOrder(state, orderParams);
  const pimItems = getPimItems(state, salesOrder);
  const retailOrder = getOrderData(state, creditMemo?.sales_order_id);
  const rxDetails = getRxDetails(retailOrder);

  const returnReceiptStatus = creditMemo && apiStatus(state, getReturnReceiptPath(creditMemo.id));

  return ({
    jwt: state.auth.jwt,
    returnReceiptLoading: returnReceiptStatus && !returnReceiptStatus.error,
    returnReasonsDataPath,
    returnReasonsData,
    customerId,
    orderParams,
    salesOrder,
    pimItems,
    creditMemo,
    rxDetails,
  });
};

export default connect(select)(CreditMemoDetail);
