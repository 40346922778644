import styled from 'react-emotion';

export const Row = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0px 10px;
`;

export const Content = styled('div')`
  width: 100%;
`;

export const StyledParagraph = styled('p')`
  text-align: center;
  font-size: 25px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
`;
