import React, { useState } from 'react';

import { styles } from 'style';
import { ServiceGesture, Dispatch, ServiceGesturePreview } from 'types';

import ServiceGestureOverride from 'src/components/sg/ServiceGestureOverride';
import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import Form from 'src/shared-components/order-actions/Form';

const disabledButton = { ...styles.button, ...styles.disabledButton };

interface ServiceGestureNoteFormProps {
  discount: string;
  dispatch: Dispatch;
  formatGesturePreview(discount: string, preview: ServiceGesturePreview): string;
  gesturePreview: ServiceGesturePreview;
  handleServiceGesture(dispatch: Dispatch, jwt: string, orderId: string, presetId: number, reasonId: number, note: string, amountOverride?: number): void;
  isGestureRequested: boolean;
  jwt: string;
  orderId: string;
  preset: string;
  reason: ServiceGesture;
}

const ServiceGestureNoteForm: React.StatelessComponent<ServiceGestureNoteFormProps> = ({
  preset, discount, jwt, orderId, dispatch, handleServiceGesture, reason, isGestureRequested, formatGesturePreview, gesturePreview,
}) => {
  const [form, setValues] = useState({
    note: '',
    hideForm: false,
  });

  const updateNote = (event): void => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const toggleForm = (event): void => {
    event.preventDefault();
    setValues({
      ...form,
      hideForm: !form.hideForm,
    });
  };

  const { note, hideForm } = form;
  const disabled = !note.length;
  const noteButton = disabled ? disabledButton : styles.button;
  const lowerCasePreset = preset.toLowerCase();

  return (
    hideForm ? (
      <>
        <EditableSelectionPill
          subtext={`Notes on ${lowerCasePreset}`}
          text={note}
          onClick={toggleForm}
        />

        <ServiceGestureOverride
          discount={discount}
          isGestureRequested={isGestureRequested}
          jwt={jwt}
          dispatch={dispatch}
          orderId={orderId}
          reason={reason}
          note={note}
          handleServiceGesture={handleServiceGesture}
          formatGesturePreview={formatGesturePreview}
          gesturePreview={gesturePreview}
        />
      </>
    ) : (
      <>
        <p style={styles.paddedPara}>
          Notes on
          {preset}
        </p>
        <Form
          onSubmit={toggleForm}
          onChange={updateNote}
          placeholder={`Please note the details of the ${lowerCasePreset}`}
          name="note"
          value={note}
          submitButton={noteButton}
          disabled={disabled}
          text="Done"
        />
      </>
    )
  );
};

export default ServiceGestureNoteForm;
