import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actions } from 'src/redux/actions';
import {
  APIParsedSummary,
  Dispatch,
  Order,
  Params,
  ReduxState,
  ServiceGesture,
  ServiceGesturePreview,
} from 'types';
import {
  apiLoading, apiStatus, apiResponse, apiActionResponse, getOrder,
} from 'selectors';

import Loading from 'src/shared-components/screens/Loading';
import ServiceGestureConfirmation from 'src/components/sg/ServiceGestureConfirmation';

const presetsPathname = (): string => '/v1/sales-order/service-gesture-presets';

const gesturePathname = (id: string): string => `/api/v2/sales_order/${id}/service-gesture`;

const gesturePreviewPathname = (orderId: string, presetId: string): string => `/api/v2/sales_order/${orderId}/service-gesture-preview/${presetId}`;

const formatToDollar = (amount: number): string => (amount / 100).toFixed(2);

// filter out the service gesture presets that only match the chosen preset reason (from route)
const formatPreset = (presets: ServiceGesture[], presetId: string): ServiceGesture[] => presets.filter((preset) => preset.reason_id === Number(presetId));

const parseReason = (presets: ServiceGesture[], reasonId: string) => presets.find((preset) => preset.detailed_reason_id === Number(reasonId));

// if an order is service gesturable, the preview array from /api/v2/sales_order/
// ...${orderId}/service-gesture-preview/${reasonId} will return an array of
// payments and the amount refunded seems to get allocated to the first payment
// in the array, which is what we will use to render the last 4. otherwise, if the
// preview returns an error, we will just render the service gesture amount without the
// last 4 and the error will render in the modal in the next step of the flow
const formatGesturePreview = (discount: string, preview: ServiceGesturePreview): string => {
  const firstPayment = preview && preview.payments && preview.payments[0];
  // there are cases where you can issue a SG to a non-credit card payment i.e., is_cash is true
  if (firstPayment && firstPayment.is_credit_card) {
    return `Credit $${discount} to card ending in ${firstPayment.last4}`;
  }

  return `Credit $${discount}`;
};

export const formatRefundAmount = (reason: ServiceGesture, total: string): string => {
  const { adjustment_type, adjustment_value } = reason;
  if (adjustment_type === 'absolute') {
    return `${(Math.abs(adjustment_value) / 100)}.00`;
  }

  // the other adjustment type is percentage in which
  // service gesture will take a percentage off the order total
  const adjustmentPercentage = Math.abs(adjustment_value) / 100;
  const totalAmount = Number(total);
  return (totalAmount / adjustmentPercentage).toFixed(2);
};

const handleServiceGesture = (dispatch, jwt: string, orderId: string, presetId: number, reasonId: number, note = '', amountOverride: number = null): void => {
  const path = gesturePathname(orderId);
  const postParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify({
      sales_order_id: orderId,
      reason_id: presetId,
      detailed_reason_id: reasonId,
      ...(note && { note }),
      ...(amountOverride && { amount_override: amountOverride }),
    }),
  };

  dispatch(actions.fetchAPI(path, jwt, postParams));
};

interface ServiceGestureConfirmationContainerProps {
  dispatch: Dispatch;
  gesture: APIParsedSummary;
  gesturePreview: ServiceGesturePreview;
  hasRequestedGesturePreview: boolean;
  hasRequestedPresetsData: boolean;
  isGestureRequested: boolean;
  jwt: string;
  order: Order;
  params: Params;
  presets: ServiceGesture[];
  reason: ServiceGesture;
}

const ServiceGestureConfirmationContainer: React.StatelessComponent<ServiceGestureConfirmationContainerProps> = ({
  jwt, dispatch, order, hasRequestedPresetsData, presets, isGestureRequested, gesture, hasRequestedGesturePreview, gesturePreview, params, reason,
}) => {
  const orderId = params.order;
  const presetId = params['service-gesture'];

  useEffect(actions.fetchOrder(dispatch, jwt, params), []);

  useEffect(() => {
    if (!hasRequestedPresetsData) {
      const presetsPath = presetsPathname();
      dispatch(actions.fetchOhmAPI(presetsPath, jwt));
    }

    if (!hasRequestedGesturePreview) {
      const gesturePreviewPath = gesturePreviewPathname(orderId, presetId);
      dispatch(actions.fetchAPI(gesturePreviewPath, jwt));
    }
  }, []);

  if (!order || !reason || !presets) {
    return <Loading />;
  }

  const totalInDollars = formatToDollar(order.adjusted_total);
  const header = order ? `Order Total - $${totalInDollars}` : 'Order Total';
  const presetCategory = presets[0].category;
  const href = `/order/${orderId}/service-gesture/${presetId}`;
  const discountAmount = reason && formatRefundAmount(reason, totalInDollars);

  return (
    <ServiceGestureConfirmation
      discountAmount={discountAmount}
      dispatch={dispatch}
      formatGesturePreview={formatGesturePreview}
      gesture={gesture}
      gesturePreview={gesturePreview}
      handleServiceGesture={handleServiceGesture}
      header={header}
      href={href}
      isGestureRequested={isGestureRequested}
      jwt={jwt}
      orderId={orderId}
      customerId={order.customer_id}
      preset={presetCategory}
      reason={reason}
    />
  );
};

const select = (state: ReduxState, { params }: { params: Params }) => {
  const order = getOrder(state, params);
  const orderId = params && params.order;

  const presetId = params && params['service-gesture'];
  const presetsPath = presetsPathname();
  const presetsStatus = apiStatus<ServiceGesture[]>(state, presetsPath);
  const presetsResponse = apiResponse(presetsStatus);
  const presets = presetsResponse && formatPreset(presetsResponse, presetId);

  const gesturePreviewPath = gesturePreviewPathname(orderId, presetId);
  const gesturePreviewStatus = apiStatus(state, gesturePreviewPath);
  const gesturePreview = apiResponse(gesturePreviewStatus);

  const serviceGesturePath = gesturePathname(orderId);
  const serviceGestureStatus = apiStatus(state, serviceGesturePath);
  const gesture = apiActionResponse(serviceGestureStatus);

  const reasonId = params && params.reason;
  const reason = presets && parseReason(presets, reasonId);

  return ({
    jwt: state.auth.jwt,
    hasRequestedPresetsData: apiLoading(presetsStatus),
    presets,
    order,
    hasRequestedGesturePreview: apiLoading(gesturePreviewStatus),
    gesturePreview,
    isGestureRequested: apiLoading(serviceGestureStatus),
    gesture,
    reason,
  });
};

export default connect(select)(ServiceGestureConfirmationContainer);
