
import styled from 'react-emotion';
import { Badge } from 'src/shared-components/badge/Badge';
import colors from 'src/utils/colors';

export const StyledBadge = styled(Badge)`
  margin: 0 0 0 1rem;
`;

export const OptionContainer = styled('label')`
  max-width: 220px;

  padding: 1.25rem;
  background-color: white;

  border: 1px solid ${colors.silver};
  border-radius: 0.5rem;

  display: flex;
  align-items: center;

  color: ${colors.stone};
  font-weight: bold;

  &:has(> input[type='radio']:checked) {
    border-color: ${colors.stone};
  }

  & > input[type='radio'] {
    margin: 0 0.5rem 0 0;
    accent-color: ${colors.allports};
    width: 1.25rem;
    height: 1.25rem;
  }
`;
