import React, { useState } from 'react';

import { styles } from 'style';
import { Dispatch, CancelReason } from 'types';

import EditableSelectionPill from 'src/shared-components/order-actions/EditableSelectionPill';
import Form from 'src/shared-components/order-actions/Form';

const disabledButton = { ...styles.button, ...styles.disabledButton };

interface CancelNoteProps {
  cancel: CancelReason;
  cancelOrder(key: string, id: string, jwt: string, dispatch: Dispatch, note: string): void;
  dispatch: Dispatch;
  jwt: string;
  loading: boolean;
  orderId: string;
}

const CancelNote: React.StatelessComponent<CancelNoteProps> = ({
  jwt, dispatch, loading, orderId, cancel, cancelOrder,
}) => {
  const [form, setValues] = useState({
    note: '',
    hideForm: false,
  });

  const updateNote = (event): void => {
    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const toggleForm = (event): void => {
    event.preventDefault();
    setValues({
      ...form,
      hideForm: !form.hideForm,
    });
  };

  const { note, hideForm } = form;
  const disabled = !note.length;
  const cancelButton = loading ? 'Loading...' : 'Yes, cancel order';
  const noteButton = disabled ? disabledButton : styles.button;
  const cancelButtonStyle = loading ? disabledButton : styles.button;

  return (
    hideForm ? (
      <>
        <EditableSelectionPill
          subtext="Notes on cancel reason"
          text={note}
          onClick={toggleForm}
        />
        <p style={styles.paddedPara}>Are you sure you want to cancel this order?</p>
        <button style={cancelButtonStyle} disabled={loading} onClick={() => cancelOrder(cancel.key, orderId, jwt, dispatch, note)}>{cancelButton}</button>
      </>
    )
      : (
        <>
          <p style={styles.paddedPara}>Notes on cancel reason</p>
          <Form
            onSubmit={toggleForm}
            onChange={updateNote}
            placeholder="Please note the details of the cancel reason"
            name="note"
            value={note}
            submitButton={noteButton}
            disabled={disabled}
            text="Done"
          />
        </>
      )
  );
};

export default CancelNote;
